<template>
	<section
		class="section wow simple"
	>
		<div class="content">
			<h3 class="block-title">
				Конкурс
			</h3>

			<div class="contest-view">
				<div class="contest-view__banner">
					<h3 class="title-section">
						{{ contest.name }}
					</h3>
					<img
						:src="contest.image"
						alt="Фото"
						class="contest-view__pic"
					>
				</div>
				<div class="contest-view__steps">
					<ContestItem
						v-for="(stage, index) in stages"
						:key="stage.id"
						:index="index"
						:stage="stage"
						:is-registered-to-mind-format="isRegisteredToMindFormat"
						:is-disable-profile-check="isDisableProfileCheck"
						:actual-tests="actualTests"
					/>
				</div>
			</div>
			<div v-if="certificates.length > 0">
				<h3 class="block-title">
					Сертификаты за прохождение конкурса
				</h3>
				<div class="certificate-card-block">
					<div class="certificate-card-group">
						<div class="grid">
							<div
								v-for="item in certificates"
								:key="item.id"
								class="box"
							>
								<div class="certificate-card-item">
									<a
										class="certificate-card-pic"
										data-modal="#modal-certificate"
										@click="showCertificate(item)"
									>
										<img
											:src="item.image_url"
											alt=""
										>
									</a>
									<a
										class="certificate-card-link"
										data-modal="#modal-certificate"
										@click="showCertificate(item)"
									>Смотреть</a>
									<a
										v-if="item.pdf_url"
										:href="item.pdf_url"
										target="_blank"
										class="certificate-card-link"
									>Скачать</a>
									<div class="certificate-card-title">
										{{ item.contest_name }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Contest from '@/api/contests';
import ContestItem from '@/pages/cabinet/user/contests/ContestItem.vue';
import Contests from '@/api/contests';

export default {
    name: 'Contest',
    components: {
        ContestItem
    },
    data: () => ({
        contest: {},
        stages: [],
        isRegisteredToMindFormat: false,
		isDisableProfileCheck: false,
        actualTests: [],
        certificates: []
    }),
    computed: {
        ...mapGetters('user', {
            userID: 'userID',
            user: 'userInfo'
        }),
    },
    created() {
        this.getUserInfo()
        this.getContestData()
        this.getCertificates()
    },
    methods: {
        ...mapActions('tst', [
            'getAllTests'
        ]),
        // ...mapActions('smpl', [
        //     'getEventSample',
        // ]),
        ...mapActions('user', {
            getUserInfo: 'detail',
        }),
        getContestData() {
            Contest.getContestInfo(this.$route.query.id).then((response) => {
                if (this.user && this.user.is_mindformat_test_done) this.isRegisteredToMindFormat = true
                if (!this.isRegisteredToMindFormat) this.isRegisteredToMindFormat = !response.data.is_mindformat_test
				this.isDisableProfileCheck = response.data.is_disable_profile_check;
                if (response.data.legacy_tests && response.data.legacy_tests.length > 0) {
                    this.getAllTests().then((result) => {
                        response.data.legacy_tests.forEach((tst) => {
                            let res = result.find((item) => item.id === tst && !item.is_passed);
                            if (res !== undefined) this.actualTests.push(result.find((item) => item.id === tst && !item.is_passed));
                        })
                    })
                }
                this.contest = response.data
                response.data.stages.forEach((stage) => {
                    if ((new Date(stage.end_date).toISOString().split('T')[0] >=
                            new Date().toISOString().split('T')[0]) &&
                        (new Date(stage.start_date).toISOString().split('T')[0] <=
                            new Date().toISOString().split('T')[0])) {
                        this.stages.push({...stage, status: 'actual'})
                    }
                    if ((new Date(stage.end_date).toISOString().split('T')[0] <
                            new Date().toISOString().split('T')[0]) &&
                        (new Date(stage.start_date).toISOString().split('T')[0] <
                            new Date().toISOString().split('T')[0])) {
                        this.stages.push({...stage, status: 'past'})
                    }
                    if ((new Date(stage.end_date).toISOString().split('T')[0] >=
                            new Date().toISOString().split('T')[0]) &&
                        (new Date(stage.start_date).toISOString().split('T')[0] >
                            new Date().toISOString().split('T')[0])) {
                        this.stages.push({...stage, status: 'next'})
                    }
                })
            })
        },
        getCertificates () {
            Contests.getCertificate(this.$route.query.id).then((response) => {
                this.certificates = response.data
            })
        },
        showCertificate(cert) {
            this.modalOpen('cert-view', cert)
        },
    }
}
</script>
