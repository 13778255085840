<template>
	<div
		v-if="board"
		class="prizes-progress__list"
	>
		<div
			v-for="(item, index) in board"
			:key="index"
			class="progress"
		>
			<div
				v-if="item.title !== 'Решено кейсов'"
				class="progress__value title-section"
			>
				{{ item.user_point }}/{{ item.point }}
			</div>
			<div
				v-if="item.title !== 'Решено кейсов'"
				class="progress__track"
			>
				<div
					class="progress__line"
					:style="'width:'+ item.progress_value + '%'"
				/>
			</div>
			<div
				v-if="item.title !== 'Решено кейсов'"
				class="progress__title"
			>
				{{ item.title }}
			</div>
			<!--			<div-->
			<!--				v-if="item.title === 'Решено кейсов'"-->
			<!--				class="progress__title"-->
			<!--				style="margin-top: 5px; font-size: 16px"-->
			<!--			>-->
			<!--				Прием кейсов на проверку завершен-->
			<!--			</div>-->
			<!--			<div class="progress__text">-->
			<!--				{{ item.valid_text }}-->
			<!--			</div>-->
		</div>
		<div>
			<router-link
				to="/stipendia"
				class="link-panel"
			>
				Узнай больше о стипендиальной программе
				<img
					:src="'/img/icons/icon__link-outer.svg' | prodSrcImage"
					alt="Иконка"
					class="link-panel__icon"
				>
			</router-link>
		</div>
	</div>
</template>

<script>
export default {
    name: 'PrizesProgress',
    props: {
        board: {
            type: Array,
            default: null
        }
    }
}
</script>
