<template>
	<div
		v-if="!resumes && isLoaded"
		class="preloader"
	/>
	<div
		v-else-if="responseResumes.length === 0 && watchUser"
		class="certificate-card-title"
	>
		Резюме нет
	</div>
	<div
		v-else
		:class="{'resumes': !watchUser, 'resume-group': watchUser}"
	>
		<transition-group name="resume">
			<div
				v-for="(resume, index) in resumes"
				:key="resume.id"
				class="resume"
			>
				<a
					class="resume-title"
					@click="goToPage(resume, 'watchResume')"
				>
					{{ resume.preferred_position }}
				</a>
				<div class="resume-date">
					Обновлено {{ resume.date_update | dateFullFormat }}, в
					{{ resume.date_update | timeFullFormat }}
				</div>
				<div v-if="!watchUser">
					<button
						class="button blue"
						@click="goToPage(resume, 'watchResume')"
					>
						Просмотр резюме
					</button>
					<button
						class="button-hollow"
						@click="downloadResume(resume.id, index)"
					>
						<a
							ref="link"
							style="display: none;"
						/>
						<div>
							<span ref="downloadText">Скачать резюме</span>
						</div>
					</button>
					<button
						class="button-control"
						@click="goToPage(resume)"
					>
						<img
							:src="'/img/icons/icon-edit.png' | prodSrcImage"
							alt="Редактировать резюме"
						>
						Редактировать
					</button>
					<button
						class="button-control"
						@click="doubleResume(resume.id)"
					>
						<img
							:src="'/img/icons/icon-copy.png' | prodSrcImage"
							alt="Дублировать резюме"
						>
						Дублировать
					</button>
					<button
						v-if="!clickRemove"
						class="button-control"
						@click="removeResume(resume.id)"
					>
						<img
							:src="'/img/icons/icon-remove.png' | prodSrcImage"
							alt="Удалить резюме"
						>
						Удалить
					</button>
					<button
						v-else
						class="button-control"
					>
						<img
							:src="'/img/icons/icon-remove.png' | prodSrcImage"
							alt="Удалить резюме"
						>
						Удалить
					</button>
				</div>
				<div v-else>
					<button
						class="button blue"
						@click="goToPage(resume)"
					>
						Просмотр резюме
					</button>
					<button
						class="button-hollow"
						@click="downloadResume(resume.id, index)"
					>
						<a
							ref="link"
							style="display: none;"
						/>
						<div>
							<span ref="downloadText">Скачать резюме</span>
						</div>
					</button>
				</div>
			</div>
		</transition-group>
	</div>
</template>

<script>

import {mapActions} from 'vuex'
import User from '@/api/user';

export default {
    filters: {
        dateFullFormat(date) {
            return new Intl.DateTimeFormat('ru-RU', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            }).format(new Date(date)).replace('г.', 'года')
        },
        timeFullFormat(time) {
            return new Intl.DateTimeFormat('ru-RU', {
                hour: 'numeric',
                minute: 'numeric'
            }).format(new Date(time))
        }
    },
    props: {
        userId: {
            type: Number,
            default: null
        }
    },
    data: () => ({
        isLoaded: true,
        resumesKey: 0,
        responseResumes: [],
        updateComponentResume: 0,
        isDownloaded: false,
        clickRemove: false,
        userInfo_id: null
    }),
    computed: {
        resumes() {
            return this.responseResumes && Array.from(this.responseResumes.values()).sort((x, y) => {
                let date1 = new Date(x.date_update).getTime(),
                    date2 = new Date(y.date_update).getTime();

                if (date1 > date2) return -1
                else if (date1 < date2) return 1
                else return 0
            })
        },
        watchUser() {
            return this.$route.params.id !== JSON.stringify(this.userId)
        }
    },
    created() {
        this.getResumes()
    },
    methods: {
        ...mapActions('tb', {
            double: 'doubleResume',
            remove: 'removeResume',
            getAll: 'getAllResumes'
        }),
        ...mapActions('user', {
            getUserInfo: 'detailWatchUser'
        }),
        getResumes() {
            if (this.$route.params.id !== JSON.stringify(this.userId)) {
                this.getUserInfo(this.$route.params.id)
                    .then(user => {
                        this.userInfo_id = user.id
                    })
                    .then(() => {
                        this.getAll(this.userInfo_id).then(res => {
                            this.responseResumes = res
                            this.isLoaded = false
                        })
                    })
            }
            else this.getAll().then(res => {
                this.responseResumes = res
                this.isLoaded = false
            })
        },
        doubleResume(id) {
            this.double(id).then(() => {
                this.resumesKey += 1
                this.$emit('update', this.resumesKey)
            })
        },
        removeResume(id, index) {
            this.clickRemove = true
            this.$emit('delete', index)
            this.remove(id).then(() => {
                this.resumesKey += 1
                this.clickRemove = false
                this.$emit('update', this.resumesKey)
            })
        },
        goToPage(resume, action) {
            if (this.$route.params.id !== JSON.stringify(this.userId)) {
                this.$router.push({
                    path: `/profile/${this.$route.params.id}?resume=true`,
                    query: {
                        resumeId: resume.id,
                        name: resume.preferred_position
                    }
                })
            }
            else if (action === 'watchResume') {
                this.$router.push({
                    path: `/profile/${this.$route.params.id}?resume=true&action=watch`,
                    query: {
                        resumeId: resume.id,
                        name: resume.preferred_position
                    }
                })
            } else {
                this.$router.push({
                    path: `/profile/${this.$route.params.id}?resume=true&action=edit`,
                    query: {
                        id: resume.id,
                        name: resume.preferred_position
                    }
                })
            }
        },
        downloadResume(id, index) {
            this.isDownloaded = true
            this.$refs.downloadText[index].innerHTML = 'Скачивается...'
            User.downloadResume(id)
                .then(response => {
                    if (response.status === 204) this.downloadResume(id, index)
                    else {
                        window.open(response.data.download_link, '_blank')
                        this.isDownloaded = false
                        this.$refs.downloadText[index].innerHTML = 'Скачать резюме'
                    }
                })
        },
    }

}
</script>

<style lang="less">
@import "../../../assets/styles/pages/cabinet/user/resumes";
</style>
