<template>
	<div class="data-access__row">
		<div class="data-access__cell">
			{{ itemKey.id }}
		</div>
		<div class="data-access__cell">
			{{ itemKey.api_key }}
		</div>
		<div class="data-access__cell">
			<input
				:type="isShowAPI ? 'text' : 'password'"
				class="data-access__api"
				:value="itemKey.api_secret"
				readonly
			>
		</div>
		<div class="data-access__cell">
			<button
				class="data-access__toggle" 
				:class="{'data-access__toggle data-access__toggle--active': isShowAPI}"
				@click="isShowAPI = !isShowAPI"
			/>
			<button
				class="delete-key"
				@click="remove(itemKey.id)"
			/>
		</div>
	</div>
</template>

<script>
export default {
    name: 'DataAccessItem',
		props: {
			itemKey:{ 
				type: Object,
				default: () => {}
			}
		},
    data: () => ({
        isShowAPI: false
    }),
		methods:{
			remove(id) {
				this.$emit('remove',id)
			}
		}
}
</script>

<style scoped>
</style>
