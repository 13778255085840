<template>
	<div v-if="onlinePrizes && onlinePrizes.length">
		<div
			v-if="!isVisible"
			class="prize"
		>
			<div class="prize__header">
				<img
					:src="'/img/pages/catalog/pic__achievement-1--x2.png' | prodSrcImage"
					alt="Приз"
					class="prize__icon"
				>
				<div class="prize__header-content">
					<div class="prize__header-title">
						<h4 class="title-element">
							Онлайн-призы
						</h4>
					</div>
				</div>
			</div>
			<div
				class="prize__banner"
				:style="'/img/pages/profile/pic__prize-9.jpg' | bgrImageCover"
			>
				<div class="prize__banner-content hidden-mobile">
					<img
						:src="'/img/logo--white.svg' | prodSrcImage"
						alt="Лифт в будущее"
						class="prize__banner-logo"
					>
					<h3 class="title-block">
						<span>Гайды по саморазвитию и эффективности</span>
					</h3>
				</div>
			</div>
			<h3 class="title-block visible-mobile">
				<span>Гайды по саморазвитию и эффективности</span>
			</h3>
			<div class="prize__text">
				Крутая подборка советов от экспертов из разных сфер. Пригодится и в жизни, и в карьере. Как приручить тайм-менеджмент, легко запоминать любые объемы информации, проводить переговоры и презентации, писать резюме и произвести хорошее впечатление на работодателя и накопить на крутые гаджеты с минимальным уровнем доходов. Скачивай гайд на телефон или компьютер, чтобы он всегда был под рукой
			</div>
			<div class="prize__buttons">
				<button
					class="button"
					@click="isVisible = !isVisible"
				>
					Смотреть
					<img
						:src="'/img/icons/icon__arrow-right--white.svg' | prodSrcImage"
						alt="Иконка"
						class="button__icon-right"
					>
				</button>
			</div>
		</div>
		<div
			v-else
			class="prize-slider"
		>
			<VueSlickCarousel
				class="prize-slider__list"
				v-bind="sliderSettings"
			>
				<div
					v-for="(item, index) in onlinePrizes"
					:key="index"
				>
					<div
						class="prize-slide"
						:class="{'prize-slide--ready': item.used}"
					>
						<div class="prize-slider__header">
							<img
								:src="'/img/pages/catalog/pic__achievement-1--x2.png' | prodSrcImage"
								alt="Приз"
								class="prize-slider__header-icon"
							>
							<div class="prize-slider__header-content">
								<div class="prize-slider__header-title">
									<h4 class="title-element">
										Онлайн-призы
									</h4>
								</div>
							</div>
						</div>
						<div class="prize-slide__number">
							<div class="prize-slide__current">
								{{ index + 1 }}
							</div>
							<div class="prize-slide__amount">
								/{{ onlinePrizes.length }}
							</div>
						</div>
						<div
							class="prize-slide__pic"
							:style="item.present.banner | bgrDynamicImageCover"
						/>
						<div class="prize-slide__title title-block">
							{{ item.present.title }}
						</div>
						<div class="prize-slide__text">
							{{ item.present.text }}
						</div>
						<div class="prize-slide__buttons">
							<button
								class="button"
								:class="{'button--disabled': !item.available,
									'button--white': item.used}"
								@click="getPrize(item)"
							>
								Скачать гайд
							</button>
						</div>
					</div>
				</div>
			</VueSlickCarousel>
		</div>
	</div>
</template>

<script>
import User from '@/api/user'

export default {
    name: 'PrizesOnline',
    props: {
        onlinePrizes: {
            type: Array,
            default: null
        }
    },
    data: () => ({
        isVisible: false,
        sliderSettings: {
            infinite: false,
            easing: 'swing',
            arrows: true,
            dots: false,
            autoplay: false,
            autoplaySpeed: 3000,
            pauseOnFocus: true,
            pauseOnHover: false,
            speed: 600,
            draggable: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: false
        }
    }),
    methods: {
        async getPrize(prize) {
            try {
                await User.UsedPrize(prize.id, 'used=true')
                window.open(prize.present.action, '_blank')
            } catch {
                console.log('err')
            }
        }
    }
}
</script>
