var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.watchUser && _vm.$route.params.id)?_c('section',{staticClass:"section section-profile wow"},[_c('div',{staticClass:"content"},[_c('Breadcrumb',{attrs:{"page-name":_vm.watchUserInfoLastName +
						' ' +
						_vm.watchUserInfoFirstName +
						' ' +
						_vm.watchUserInfoMiddleName,"additional-pages":[
					{
						name: 'Поиск по пользователям',
						link: '/search-users'
					}
				]}}),_c('h3',{staticClass:"page-title"},[_vm._v(" "+_vm._s(_vm.watchUserInfoLastName)+" "+_vm._s(_vm.watchUserInfoFirstName)+" "+_vm._s(_vm.watchUserInfoMiddleName)+" ")]),_c('div',{staticClass:"profile-navigation"},[_c('div',{staticClass:"profile-navigation__list"},[_c('router-link',{staticClass:"profile-navigation__link",class:{
							'profile-navigation__link--active': _vm.cabinetPage === 'Profile'
						},attrs:{"to":`/profile/${_vm.$route.params.id}`}},[_c('span',{staticClass:"iconify iconify-user"}),_vm._v(" Профиль ")]),_c('router-link',{staticClass:"profile-navigation__link",class:{
							'profile-navigation__link--active': _vm.cabinetPage === 'Courses'
						},attrs:{"to":`/profile/${_vm.$route.params.id}?courses=true`}},[_c('span',{staticClass:"iconify iconify-graduate"}),_vm._v(" Курсы ")]),_c('router-link',{staticClass:"profile-navigation__link",class:{
							'profile-navigation__link--active': _vm.cabinetPage === 'Program'
						},attrs:{"to":`/profile/${_vm.$route.params.id}?program=true`}},[_c('span',{staticClass:"iconify iconify-list"}),_vm._v(" Программы ")]),_c('router-link',{staticClass:"profile-navigation__link",class:{
							'profile-navigation__link--active':
								_vm.cabinetPage === 'Certificates'
						},attrs:{"to":`/profile/${_vm.$route.params.id}?certificates=true`}},[_c('span',{staticClass:"iconify iconify-certificate"}),_vm._v(" Сертификаты ")]),_c('router-link',{staticClass:"profile-navigation__link",class:{
							'profile-navigation__link--active':
								_vm.cabinetPage === 'Resumes' ||
								_vm.cabinetPage === 'Resume'
						},attrs:{"to":`/profile/${_vm.$route.params.id}?resumes=true`}},[_c('span',{staticClass:"iconify iconify-document"}),_vm._v(" Резюме ")])],1),_c('div',{staticClass:"select select-navigation profile_nav"},[_c('c-select',{attrs:{"options":_vm.pagesWatchUser,"default-data":_vm.pageName},on:{"selected":_vm.selectPage}})],1)])],1)]):(_vm.isRecruiter && !_vm.$route.params.id)?_c('section',{staticClass:"section section-profile wow"},[_c('div',{staticClass:"content"},[(_vm.$route.fullPath === '/profile')?_c('div',[_c('Breadcrumb',{attrs:{"page-name":'Личный кабинет'}})],1):_vm._e(),(_vm.$route.fullPath === '/profile?companies=true')?_c('div',[_c('Breadcrumb',{attrs:{"page-name":'Список компаний',"additional-pages":[
						{ name: 'Личный кабинет', link: '/profile' }
					]}})],1):(
					_vm.$route.query.action === 'watch' &&
						_vm.$route.query.company
				)?_c('div',[_c('Breadcrumb',{attrs:{"page-name":_vm.$route.query.name,"additional-pages":[
						{
							name: 'Личный кабинет',
							link: '/profile'
						},
						{
							name: 'Список компаний',
							link: '/profile?companies=true'
						}
					]}})],1):(
					_vm.$route.query.action === 'edit' &&
						_vm.$route.query.company
				)?_c('div',[_c('Breadcrumb',{attrs:{"page-name":'Редактирование компании',"additional-pages":[
						{
							name: 'Личный кабинет',
							link: '/profile'
						},
						{
							name: 'Список компаний',
							link: '/profile?companies=true'
						},
						{
							name: _vm.$route.query.name,
							link:
								'profile?company=true&action=watch&id=${$route.query.id}'
						}
					]}})],1):(
					_vm.$route.fullPath === '/profile?vacancies=true'
				)?_c('div',[_c('Breadcrumb',{attrs:{"page-name":'Список вакансий',"additional-pages":[
						{ name: 'Личный кабинет', link: '/profile' }
					]}})],1):(
					_vm.$route.fullPath ===
						'/profile?vacancy=true&action=create'
				)?_c('div',[_c('Breadcrumb',{attrs:{"page-name":'Создание вакансии',"additional-pages":[
						{
							name: 'Личный кабинет',
							link: '/profile'
						},
						{
							name: 'Список вакансий',
							link: '/profile?vacancies=true'
						}
					]}})],1):(
					_vm.$route.query.action === 'watch' &&
						_vm.$route.query.vacancy
				)?_c('div',[_c('Breadcrumb',{attrs:{"page-name":_vm.$route.query.name,"additional-pages":[
						{
							name: 'Личный кабинет',
							link: '/profile'
						},
						{
							name: 'Список вакансий',
							link: '/profile?vacancies=true'
						}
					]}})],1):(
					_vm.$route.query.action === 'edit' &&
						_vm.$route.query.vacancy
				)?_c('div',[_c('Breadcrumb',{attrs:{"page-name":'Редактирование вакансии',"additional-pages":[
						{
							name: 'Личный кабинет',
							link: '/profile'
						},
						{
							name: 'Список вакансий',
							link: '/profile?vacancies=true'
						},
						{
							name: _vm.$route.query.name,
							link:
								'profile?vacancy=true&action=watch&id=${$route.query.id}'
						}
					]}})],1):(
					_vm.$route.fullPath === '/profile?responses=true'
				)?_c('div',[_c('Breadcrumb',{attrs:{"page-name":'Отклики',"additional-pages":[
						{ name: 'Личный кабинет', link: '/profile' }
					]}})],1):(
					_vm.$route.query.action === 'edit' &&
						_vm.$route.query.response === 'true'
				)?_c('div',[_c('Breadcrumb',{attrs:{"page-name":_vm.$route.query.name,"additional-pages":[
						{
							name: 'Личный кабинет',
							link: '/profile'
						},
						{
							name: 'Отклики',
							link: '/profile?responses=true'
						}
					]}})],1):(
					_vm.$route.fullPath === '/profile?favorites=true'
				)?_c('div',[_c('Breadcrumb',{attrs:{"page-name":'Избранные кандидаты',"additional-pages":[
						{ name: 'Личный кабинет', link: '/profile' }
					]}})],1):(
					_vm.$route.fullPath === '/profile?access=true'
				)?_c('div',[_c('Breadcrumb',{attrs:{"page-name":'Доступ к данным',"additional-pages":[
						{ name: 'Личный кабинет', link: '/profile' }
					]}})],1):_vm._e(),_c('h3',{staticClass:"page-title"},[_vm._v(" Личный кабинет ")]),_c('div',{staticClass:"profile-navigation"},[_c('div',{staticClass:"profile-navigation__list"},[_c('router-link',{staticClass:"profile-navigation__link",class:{
							'profile-navigation__link--active': _vm.cabinetPage === 'Profile'
						},attrs:{"to":'/profile'}},[_c('span',{staticClass:"iconify iconify-user"}),_vm._v(" Профиль ")]),_c('router-link',{staticClass:"profile-navigation__link",class:{
							'profile-navigation__link--active':
								_vm.cabinetPage === 'Companies' ||
								_vm.cabinetPage === 'Company'
						},attrs:{"to":'/profile?companies=true'}},[_vm._v(" Компании ")]),_c('router-link',{staticClass:"profile-navigation__link",class:{
							'profile-navigation__link--active':
								_vm.cabinetPage === 'Vacancies' ||
								_vm.cabinetPage === 'Vacancy'
						},attrs:{"to":'/profile?vacancies=true'}},[_c('span',{staticClass:"iconify iconify-favorite"}),_vm._v(" Вакансии ")]),_c('router-link',{staticClass:"profile-navigation__link",class:{
							'profile-navigation__link--active':
								_vm.cabinetPage === 'Responses' ||
								_vm.cabinetPage === 'ReResponse' ||
								_vm.cabinetPage === 'UserResponse'
						},attrs:{"to":'/profile?responses=true'}},[_c('span',{staticClass:"iconify iconify-checked"}),_vm._v(" Отклики ")]),_c('router-link',{staticClass:"profile-navigation__link",class:{
							'profile-navigation__link--active': _vm.cabinetPage === 'Favorites'
						},attrs:{"to":'/profile?favorites=true'}},[_vm._v(" Избранные кандидаты ")]),_c('router-link',{staticClass:"profile-navigation__link",class:{
							'profile-navigation__link--active': _vm.cabinetPage === 'DataAccess'
						},attrs:{"to":'/profile?access=true'}},[_vm._v(" Доступ к данным ")])],1),_c('div',{staticClass:"select select-navigation profile_nav"},[_c('select-2',{attrs:{"options":_vm.pagesRecruiter,"value":_vm.pageName},on:{"selected":_vm.selectPage}})],1)])])]):(!_vm.isRecruiter && !_vm.watchUser)?_c('section',{staticClass:"section section-profile wow"},[_c('div',{staticClass:"content"},[(
					_vm.$route.fullPath ===
						`/profile/${_vm.$route.params.id}`
				)?_c('div',[_c('Breadcrumb',{attrs:{"page-name":'Личный кабинет'}})],1):_vm._e(),(
					(_vm.$route.fullPath ===
						`/profile/${_vm.$route.params.id}?prizes=true`)
						&& ((_vm.user.user_role === 'Student') ||
							(_vm.user.user_role === 'College') ||
							(_vm.user.user_role === 'Schoolboy'))
				)?_c('div',[_c('Breadcrumb',{attrs:{"page-name":'Статистика',"additional-pages":[
						{
							name: 'Личный кабинет',
							link: `/profile/${_vm.$route.params.id}`
						}
					]}})],1):_vm._e(),(
					_vm.$route.fullPath ===
						`/profile/${_vm.$route.params.id}?contests=true`
				)?_c('div',[_c('Breadcrumb',{attrs:{"page-name":'Конкурсы',"additional-pages":[
						{
							name: 'Личный кабинет',
							link: `/profile/${_vm.$route.params.id}`
						}
					]}})],1):_vm._e(),(_vm.$route.query.contest)?_c('div',[_c('Breadcrumb',{attrs:{"page-name":_vm.$route.query.name,"additional-pages":[
						{
							name: 'Личный кабинет',
							link: '/profile'
						},
						{
							name: 'Конкурсы',
							link: `/profile/${_vm.$route.params.id}?contests=true`
						}
					]}})],1):_vm._e(),(
					_vm.$route.fullPath ===
						`/profile/${_vm.$route.params.id}?courses=true`
				)?_c('div',[_c('Breadcrumb',{attrs:{"page-name":'Курсы',"additional-pages":[
						{
							name: 'Личный кабинет',
							link: `/profile/${_vm.$route.params.id}`
						}
					]}})],1):_vm._e(),(
					_vm.$route.fullPath ===
						`/profile/${_vm.$route.params.id}?program=true`
				)?_c('div',[_c('Breadcrumb',{attrs:{"page-name":'Программы',"additional-pages":[
						{
							name: 'Личный кабинет',
							link: `/profile/${_vm.$route.params.id}`
						}
					]}})],1):(
					_vm.$route.fullPath ===
						`/profile/${_vm.$route.params.id}?certificates=true`
				)?_c('div',[_c('Breadcrumb',{attrs:{"page-name":'Сертификаты',"additional-pages":[
						{
							name: 'Личный кабинет',
							link: `/profile/${_vm.$route.params.id}`
						}
					]}})],1):(
					_vm.$route.fullPath ===
						`/profile/${_vm.$route.params.id}?tests=true`
				)?_c('div',[_c('Breadcrumb',{attrs:{"page-name":'Тесты',"additional-pages":[
						{
							name: 'Личный кабинет',
							link: `/profile/${_vm.$route.params.id}`
						}
					]}})],1):(
					_vm.$route.fullPath ===
						`/profile/${_vm.$route.params.id}?resumes=true`
				)?_c('div',[_c('Breadcrumb',{attrs:{"page-name":'Резюме',"additional-pages":[
						{
							name: 'Личный кабинет',
							link: `/profile/${_vm.$route.params.id}`
						}
					]}})],1):(
					_vm.$route.query.action === 'create' &&
						_vm.$route.query.resume
				)?_c('div',[_c('Breadcrumb',{attrs:{"page-name":'Создание резюме',"additional-pages":[
						{
							name: 'Личный кабинет',
							link: `/profile/${_vm.$route.params.id}`
						},
						{
							name: 'Резюме',
							link: `/profile/${_vm.$route.params.id}?resumes=true`
						}
					]}})],1):(
					_vm.$route.query.action === 'edit' &&
						_vm.$route.query.resume
				)?_c('div',[_c('Breadcrumb',{attrs:{"page-name":'Редактирование резюме',"additional-pages":[
						{
							name: 'Личный кабинет',
							link: `/profile/${_vm.$route.params.id}`
						},
						{
							name: 'Резюме',
							link: `/profile/${_vm.$route.params.id}?resumes=true`
						}
					]}})],1):(
					_vm.$route.fullPath ===
						`/profile/${_vm.$route.params.id}?responses=true`
				)?_c('div',[_c('Breadcrumb',{attrs:{"page-name":'Отклики',"additional-pages":[
						{
							name: 'Личный кабинет',
							link: `/profile/${_vm.$route.params.id}`
						}
					]}})],1):(
					_vm.$route.query.action === 'watch' &&
						_vm.$route.query.response === 'true'
				)?_c('div',[_c('Breadcrumb',{attrs:{"page-name":_vm.$route.query.name,"additional-pages":[
						{
							name: 'Личный кабинет',
							link: `/profile/${_vm.$route.params.id}`
						},
						{
							name: 'Отклики',
							link: `/profile/${_vm.$route.params.id}?responses=true`
						}
					]}})],1):(
					_vm.$route.fullPath ===
						`/profile/${_vm.$route.params.id}?favoritesVacancies=true`
				)?_c('div',[_c('Breadcrumb',{attrs:{"page-name":'Избранные вакансии',"additional-pages":[
						{
							name: 'Личный кабинет',
							link: `/profile/${_vm.$route.params.id}`
						}
					]}})],1):_vm._e(),_c('h3',{staticClass:"page-title"},[_vm._v(" Личный кабинет ")]),_c('div',{staticClass:"profile-navigation"},[_c('div',{staticClass:"profile-navigation__list"},[((_vm.user && _vm.user.user_role === 'Student') || (_vm.user && _vm.user.user_role === 'Schoolboy') || (_vm.user && _vm.user.user_role === 'College'))?_c('router-link',{staticClass:"profile-navigation__link",class:{
							'profile-navigation__link--active': _vm.cabinetPage === 'Prizes'
						},attrs:{"to":`/profile/${_vm.$route.params.id}?prizes=true`}},[_c('span',{staticClass:"iconify iconify-stats"}),_vm._v(" Статистика ")]):_vm._e(),_c('router-link',{staticClass:"profile-navigation__link",class:{
							'profile-navigation__link--active': _vm.cabinetPage === 'Profile'
						},attrs:{"to":`/profile/${_vm.$route.params.id}`}},[_c('span',{staticClass:"iconify iconify-user"}),_vm._v(" Профиль ")]),_c('router-link',{staticClass:"profile-navigation__link",class:{
							'profile-navigation__link--active': _vm.cabinetPage === 'Contests' || _vm.cabinetPage === 'Contest'
						},attrs:{"to":`/profile/${_vm.$route.params.id}?contests=true`}},[_c('span',{staticClass:"iconify iconify-cup"}),_vm._v(" Конкурсы ")]),_c('router-link',{staticClass:"profile-navigation__link",class:{
							'profile-navigation__link--active': _vm.cabinetPage === 'Courses'
						},attrs:{"to":`/profile/${_vm.$route.params.id}?courses=true`}},[_c('span',{staticClass:"iconify iconify-graduate"}),_vm._v(" Курсы ")]),_c('router-link',{staticClass:"profile-navigation__link",class:{
							'profile-navigation__link--active':
								_vm.cabinetPage === 'Tests'
						},attrs:{"to":`/profile/${_vm.$route.params.id}?tests=true`}},[_c('span',{staticClass:"iconify iconify-test"}),_vm._v(" Тесты ")]),_c('router-link',{staticClass:"profile-navigation__link",class:{
							'profile-navigation__link--active': _vm.cabinetPage === 'Programs'
						},attrs:{"to":`/profile/${_vm.$route.params.id}?program=true`}},[_c('span',{staticClass:"iconify iconify-list"}),_vm._v(" Программы ")]),_c('router-link',{staticClass:"profile-navigation__link",class:{
							'profile-navigation__link--active':
								_vm.cabinetPage === 'Certificates'
						},attrs:{"to":`/profile/${_vm.$route.params.id}?certificates=true`}},[_c('span',{staticClass:"iconify iconify-certificate"}),_vm._v(" Сертификаты ")]),_c('router-link',{staticClass:"profile-navigation__link",class:{
							'profile-navigation__link--active':
								_vm.cabinetPage === 'Resumes' ||
								_vm.cabinetPage === 'Resume'
						},attrs:{"to":`/profile/${_vm.$route.params.id}?resumes=true`}},[_c('span',{staticClass:"iconify iconify-document"}),_vm._v(" Резюме ")]),_c('router-link',{staticClass:"profile-navigation__link",class:{
							'profile-navigation__link--active':
								_vm.cabinetPage === 'Responses' ||
								_vm.cabinetPage === 'ReResponse' ||
								_vm.cabinetPage === 'UserResponse'
						},attrs:{"to":`/profile/${_vm.$route.params.id}?responses=true`}},[_c('span',{staticClass:"iconify iconify-checked"}),_vm._v(" Отклики ")]),_c('router-link',{staticClass:"profile-navigation__link",class:{
							'profile-navigation__link--active':
								_vm.cabinetPage ===
								'FavoritesVacancies'
						},attrs:{"to":`/profile/${_vm.$route.params.id}?favoritesVacancies=true`}},[_c('span',{staticClass:"iconify iconify-favorite"}),_vm._v(" Избранные вакансии ")])],1),_c('div',{staticClass:"select select-navigation profile_nav"},[_c('select-2',{attrs:{"options":_vm.pages,"value":_vm.pageName},on:{"selected":_vm.selectPage}})],1)])])]):_vm._e(),_c(_vm.cabinetPage,{tag:"component"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }