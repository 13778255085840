<template>
	<section class="section wow">
		<div class="content">
			<router-link
				:to="'/profile?vacancies=true'"
				class="button-hollow icon-hover create-resume-back"
			>
				<span class="icon icon-back" />
				К списку вакансий
			</router-link>

			<div
				v-if="!isLoaded"
				class="preloader"
				style="margin-top: 200px"
			/>
			<div
				v-else
				class="create-resume-block"
			>
				<div class="create-resume-section">
					<div class="block-title">
						{{ titleText }}
					</div>

					<div
						v-if="isWatch"
						class="grid"
					>
						<div
							v-if="vacancy.vacancy_owner.length"
							class="box full"
						>
							<div class="field-wrap value-wrap">
								<label
									class="field-label"
								>Владелец вакансии:</label>
								<div class="field-value">
									<span
										v-for="(owner,
											index) in vacancy.vacancy_owner"
										:key="index"
									>
										<span
											v-if="
												vacancy.vacancy_owner[
													vacancy.vacancy_owner
														.length - 1
												] === owner
											"
										>{{ owner }}</span>
										<span v-else>{{ owner }}, </span>
									</span>
								</div>
							</div>
						</div>
						<div class="box">
							<div class="field-wrap value-wrap">
								<label class="field-label">Название:</label>
								<div class="field-value">
									{{ vacancy.name }}
								</div>
							</div>
						</div>
						<div class="box">
							<div class="field-wrap value-wrap">
								<label class="field-label">Компания:</label>
								<div class="field-value">
									{{ vacancy.company.company_name }}
								</div>
							</div>
						</div>
						<div class="box">
							<div class="field-wrap value-wrap">
								<label class="field-label">Город:</label>
								<div
									v-if="vacancy.city"
									class="field-value"
								>
									{{ vacancy.city }}
								</div>
							</div>
						</div>
						<div class="box">
							<div class="field-wrap value-wrap">
								<label class="field-label">Улица:</label>
								<div
									v-if="vacancy.street"
									class="field-value"
								>
									{{ vacancy.street }}
								</div>
							</div>
						</div>
						<div class="box">
							<div class="field-wrap value-wrap">
								<label class="field-label">Дом:</label>
								<div
									v-if="vacancy.house"
									class="field-value"
								>
									{{ vacancy.house }}
								</div>
							</div>
						</div>
						<div class="box">
							<div class="field-wrap value-wrap">
								<label class="field-label">Офис:</label>
								<div
									v-if="vacancy.apartment"
									class="field-value"
								>
									{{ vacancy.apartment }}
								</div>
							</div>
						</div>
						<div class="box full">
							<div class="field-wrap value-wrap">
								<label class="field-label">Описание:</label>
								<div
									class="field-value"
									style="white-space: pre-wrap;"
									v-html="vacancy.description"
								/>
							</div>
						</div>
						<div class="box full">
							<div class="field-wrap value-wrap">
								<label class="field-label">Обязанности:</label>
								<div
									class="field-value"
									style="white-space: pre-wrap;"
									v-html="vacancy.duties"
								/>
							</div>
						</div>
						<div class="box full">
							<div class="field-wrap value-wrap">
								<label class="field-label">Требования:</label>
								<div
									class="field-value"
									style="white-space: pre-wrap;"
									v-html="vacancy.requirements"
								/>
							</div>
						</div>
						<div class="box full">
							<div class="field-wrap value-wrap">
								<label class="field-label">Условия:</label>
								<div
									class="field-value"
									style="white-space: pre-wrap;"
									v-html="vacancy.conditions"
								/>
							</div>
						</div>
						<div class="box">
							<div class="field-wrap value-wrap">
								<label
									class="field-label"
								>Тип занятости:</label>
								<div class="field-value">
									{{ vacancy.type_employment }}
								</div>
							</div>
						</div>
						<div class="box salary salary--watch">
							<div class="field-wrap value-wrap">
								<label
									class="field-label"
								>Заработная плата от:</label>
								<div class="field-value">
									{{ vacancy.salary_from | salaryFormat }}
								</div>
							</div>
						</div>
						<div class="box">
							<div class="field-wrap value-wrap">
								<label
									class="field-label"
								>Заработная плата до:</label>
								<div class="field-value">
									{{ vacancy.salary_before | salaryFormat }}
								</div>
							</div>
						</div>
					</div>

					<div
						v-else
						class="grid"
					>
						<div
							ref="name"
							class="box"
						>
							<div class="field-wrap">
								<label class="field-label">
									<input
										v-model="$v.vacancy.name.$model"
										type="text"
										class="field"
										placeholder="Название"
									>
									<div class="label">Название:</div>
									<span
										v-if="$v.vacancy.name.$error"
										class="help-block form-error"
									>
										Это обязательное поле
									</span>
								</label>
							</div>
						</div>
						<div class="box">
							<div class="field-wrap">
								<label class="field-label">
									<c-select
										:name="'Компания:'"
										autosearch-re-company
										:default-data="vacancy.company.company_name"
										:is-error="
											$v.vacancy.company.company_name
												.$error
										"
										@selected="selectCompany"
									/>
								</label>
							</div>
						</div>
						<div class="box">
							<div class="field-wrap">
								<label class="field-label">
									<input
										v-model="vacancy.city"
										type="text"
										class="field"
										placeholder="Город"
									>
									<div class="label">Город:</div>
									<span
										v-if="$v.vacancy.city.$error"
										class="help-block form-error"
									>
										Это обязательное поле
									</span>
								</label>
							</div>
						</div>
						<div class="box">
							<div class="field-wrap">
								<label class="field-label">
									<input
										v-model="vacancy.street"
										type="text"
										class="field"
										placeholder="Улица"
									>
									<div class="label">Улица:</div>
								</label>
							</div>
						</div>
						<div class="box">
							<div class="field-wrap">
								<label class="field-label">
									<input
										v-model="vacancy.house"
										type="text"
										class="field"
										placeholder="Дом"
									>
									<div class="label">Дом:</div>
								</label>
							</div>
						</div>
						<div class="box">
							<div class="field-wrap">
								<label class="field-label">
									<input
										v-model="vacancy.apartment"
										type="text"
										class="field"
										placeholder="Офис"
									>
									<div class="label">Офис:</div>
								</label>
							</div>
						</div>
						<div
							ref="description"
							class="box full"
						>
							<div class="field-wrap">
								<label class="field-label">
									<textarea
										v-model="$v.vacancy.description.$model"
										rows="3"
										class="field"
										placeholder="Описание"
									>Описание</textarea>
									<div class="label">Описание:</div>
									<span
										v-if="$v.vacancy.description.$error"
										class="help-block form-error"
									>
										Это обязательное поле
									</span>
								</label>
							</div>
						</div>
						<div class="box full">
							<div class="field-wrap">
								<label class="field-label">
									<textarea
										v-model="$v.vacancy.duties.$model"
										rows="3"
										class="field"
										placeholder="Обязанности"
									>Обязанности</textarea>
									<div class="label">Обязанности:</div>
									<span
										v-if="$v.vacancy.duties.$error"
										class="help-block form-error"
									>
										Это обязательное поле
									</span>
								</label>
							</div>
						</div>
						<div class="box full">
							<div class="field-wrap">
								<label class="field-label">
									<textarea
										v-model="$v.vacancy.requirements.$model"
										rows="3"
										class="field"
										placeholder="Требования"
									>Требования</textarea>
									<div class="label">Требования:</div>
									<span
										v-if="$v.vacancy.requirements.$error"
										class="help-block form-error"
									>
										Это обязательное поле
									</span>
								</label>
							</div>
						</div>
						<div class="box full">
							<div class="field-wrap">
								<label class="field-label">
									<textarea
										v-model="$v.vacancy.conditions.$model"
										rows="3"
										class="field"
										placeholder="Условия"
									>Условия</textarea>
									<div class="label">Условия:</div>
									<span
										v-if="$v.vacancy.conditions.$error"
										class="help-block form-error"
									>
										Это обязательное поле
									</span>
								</label>
							</div>
						</div>
						<div class="box full select-box">
							<div class="field-wrap">
								<label class="field-label">
									<c-select
										:options="employmentOptions"
										:name="'Тип занятости:'"
										:default-data="vacancy.type_employment"
										:is-error="
											$v.vacancy.type_employment.$error
										"
										@selected="selectEmployment"
									/>
								</label>
							</div>
						</div>
						<div class="box salary">
							<div class="field-wrap">
								<label
									class="field-label field-label--postfix-rub"
								>
									<input
										v-model="vacancy.salary_from"
										v-mask="mask"
										type="text"
										class="field"
										placeholder="Заработная плата от"
									>
									<div class="label">
										Заработная плата от:
									</div>
								</label>
							</div>
						</div>
						<div class="box">
							<div class="field-wrap">
								<label
									class="field-label field-label--postfix-rub"
								>
									<input
										v-model="vacancy.salary_before"
										v-mask="mask"
										type="text"
										class="field"
										placeholder="Заработная плата до"
									>
									<div class="label">
										Заработная плата до:
									</div>
								</label>
							</div>
						</div>
					</div>
				</div>

				<div
					ref="specialisation"
					class="create-rekrut-company-section"
				>
					<div class="sub-title">
						Специализация:
					</div>

					<template v-if="isWatch">
						<div
							v-for="(item, index) in vacancy.specialisation"
							:key="index"
							class="panel sm view-mode"
						>
							<div class="text dark sm">
								<p>{{ item.name }}</p>
							</div>
						</div>
						<div class="field-wrap" />
					</template>

					<template v-else>
						<div
							v-for="(item) in vacancy.specialisation"
							:key="item.id"
							class="panel sm"
						>
							<div
								class="panel-remove"
								@click="removeSpecialisations(item.id)"
							/>
							<div class="text dark sm">
								<p>{{ item.name }}</p>
							</div>
						</div>
						<div class="field-wrap">
							<button
								class="button-control"
								@click="
									modalOpen(
										'changeSpec',
										vacancy.specialisation
									)
								"
							>
								<img
									:src="
										'/img/icons/icon-add.png' | prodSrcImage
									"
									alt="Изменить специализации"
								>
								Изменить специализации
							</button>
							<span
								v-if="$v.vacancy.specialisation.$error"
								class="help-block form-error"
							>Это обязательное поле</span>
						</div>
					</template>
				</div>
				<div
					ref="field_activity"
					class="create-rekrut-company-section padding-t"
				>
					<div class="sub-title">
						Сфера деятельности:
					</div>

					<template v-if="isWatch">
						<div
							v-for="(item, index) in vacancy.field_activity"
							:key="index"
							class="panel sm view-mode"
						>
							<div class="text dark sm">
								<p>{{ item.name }}</p>
							</div>
						</div>
						<div class="field-wrap" />
					</template>

					<template v-else>
						<div
							v-for="(item) in vacancy.field_activity"
							:key="item.id"
							class="panel sm"
						>
							<div
								class="panel-remove"
								@click="removeActivities(item.id)"
							/>
							<div class="text dark sm">
								<p>{{ item.name }}</p>
							</div>
						</div>
						<div class="field-wrap">
							<button
								class="button-control"
								@click="
									modalOpen(
										'changeFieldAct',
										vacancy.field_activity
									)
								"
							>
								<img
									:src="
										'/img/icons/icon-add.png' | prodSrcImage
									"
									alt="Изменить сферы деятельности"
								>
								Изменить сферы деятельности
							</button>
							<span
								v-if="$v.vacancy.field_activity.$error"
								class="help-block form-error"
							>Это обязательное поле</span>
						</div>
					</template>
				</div>
				<div class="create-rekrut-company-section padding-t">
					<div class="sub-title questions-title">
						<div class="questions-title__wrap">
							Вопросы к быстрому отклику
							<div
								class="questions__help-icon"
								@click="modalOpen('helpModalQuestions')"
							>
								<img
									width="20px"
									height="20px"
									:src="'/img/question.png' | prodSrcImage"
									alt=""
								>
							</div>
						</div>
					</div>

					<template v-if="isWatch">
						<div
							v-for="(item,
								index) in vacancy.questions_for_quick_apply_form"
							:key="index"
							class="panel sm view-mode"
						>
							<div class="text dark sm">
								<p>{{ item.text }}</p>
							</div>
						</div>
						<div class="field-wrap" />
					</template>

					<template v-else>
						<div
							v-for="(item,
								index) in vacancy.questions_for_quick_apply_form"
							:key="index"
							class="panel sm"
						>
							<div class="text dark sm">
								<p>{{ item.text }}</p>
							</div>
						</div>
						<div class="field-wrap">
							<button
								class="button-control"
								@click="
									modalOpen('changeQuestionVacancy');
									setQuestions(
										vacancy.questions_for_quick_apply_form
									);
								"
							>
								<img
									:src="
										'/img/icons/icon-add.png' | prodSrcImage
									"
									alt="Изменить вопросы к быстрому отклику"
								>
								Изменить вопросы к быстрому отклику
							</button>
						</div>
					</template>
				</div>
				<div
					v-if="!isWatch"
					class="create-rekrut-company-section padding-t"
				>
					<div class="field-wrap last-wrap">
						<label class="checkbox-filter blue-filter">
							<input
								v-model="vacancy.active"
								type="checkbox"
							>
							<span>Опубликовать</span>
						</label>
					</div>
				</div>

				<div class="top-btns observe">
					<button
						v-if="isWatch"
						class="button blue lg create-resume-save"
						@click="edit(vacancy)"
					>
						Редактировать
					</button>
					<template v-else>
						<button
							v-if="!isSaving"
							class="button lg create-resume-save"
							@click="save"
						>
							{{ buttonSaveText }}
						</button>
						<div
							v-else
							class="button lg orange create-resume-save button-preloader"
						>
							{{ buttonSaveText }}
							<div class="button-preloader-icons">
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
							</div>
						</div>
					</template>
					<template v-if="!isCreate">
						<button
							v-if="!isArchiving"
							class="button lg remove-response"
							@click="archive"
						>
							{{ buttonArchText }}
						</button>
						<div
							v-else
							class="button lg remove-respons button-preloader"
						>
							{{ buttonArchText }}
							<div class="button-preloader-icons">
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>
	</section>
</template>

<script>

import Re from '@/api/recruiter'
import { required } from 'vuelidate/lib/validators'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import {mapGetters, mapActions} from 'vuex';

const numberMask = createNumberMask({
    thousandsSeparatorSymbol: ',',
    prefix: '',
    decimalLimit: 0
})

export default {
    data: () => ({
        isLoaded: false,
        isSaving: false,
        isArchiving: false,
        mask: numberMask,
        field_activity_initial: new Array(),
        specialisation_initial: new Array(),
            defaultQuestions:[
                {
                    text:'Как ты выбрал свою специальность? Расскажи о своих профессиональных интересах.',
                    priority:'1',
                    id:1,
                },
                {
                    text:'Что является твоим главным достижением?',
                    priority:'2',
                    id:2,
                },
                {
                    text:'Какую цель на стажировку ты перед собой ставишь?',
                    priority:'3',
                    id:3,
                }
            ],
        vacancy: {
            name: '',
            duties: '',
            requirements: '',
            conditions: '',
            description: '',
            type_employment: '',
            salary_from: '',
            salary_before: '',
            city: '',
            street: '',
            house: '',
            apartment: '',
            specialisation: [],
            field_activity: [],
            questions_for_quick_apply_form: [],
            active: false,
            company: {
                company_name: '',
                id: undefined
            }
        }
    }),
    computed: {
        ...mapGetters('cnst', {
            employments: 'getEmployments'
        }),
        ...mapGetters('modal', {
            questions: 'getQuestions',
            specialisations: 'getSpecialisations',
            activities: 'getActivities'
        }),
        token() {
            return this.$store.state.user && this.$store.state.user.token
        },
        isWatch() {
            return this.$route.query.action === 'watch'
        },
        isEdit() {
            return this.$route.query.action === 'edit'
        },
        isCreate() {
            return this.$route.query.action === 'create'
        },
        titleText() {
            if (this.isCreate) return 'Создание вакансии'
            else if (this.isEdit) return 'Редактирование вакансии'
            return ''
        },
        buttonSaveText() {
            if (this.isCreate) return 'Создать вакансию'
            else if (this.isEdit) return 'Сохранить изменения'
            return ''
        },
        buttonArchText() {
            if (this.vacancy.status === 'archive') return 'Восстановить из архива'
            else return 'Архивировать вакансию'
        },
        employmentOptions() {
            return this.employments.map(x => x.value)
        }
    },
    validations: {
        vacancy: {
            name: { required },
            duties: { required },
            description: { required },
            requirements: { required },
            conditions: { required },
            city: { required },
            type_employment: { required },
            company: {
                company_name: { required }
            },
            specialisation: { required },
            field_activity: { required }
        }
    },
    watch: {
        questions() {
            this.vacancy.questions_for_quick_apply_form = this.questions
        },
        specialisations() {
            this.vacancy.specialisation = this.specialisations
        },
        activities() {
            this.vacancy.field_activity = this.activities
        }
    },
    created() {
        this.vacancy.type_employment = this.employments[0].value
        if (this.isCreate) {
            this.isLoaded = true
        } else if (this.isEdit || this.isWatch) {
            Re.getVacancy(this.$route.query.id)
                .then(response => response.data)
                .then(result => this.acceptVacancyResult(result))
                .finally(() => this.isLoaded = true)
        }
    },
    methods: {
        ...mapActions('modal', ['setQuestions', 'setSpecialisations', 'setActivities']),
        acceptVacancyResult(result) {
            this.vacancy = result
            this.vacancy.type_employment = this.employments.find(x => x.slug === result.type_employment).value
            this.field_activity_initial = JSON.parse(JSON.stringify(result.field_activity))
            this.specialisation_initial = JSON.parse(JSON.stringify(result.specialisation))
            this.vacancy.active = result.status === 'actual'
        },
        selectCity(val) {
            this.vacancy.city = val
        },
        selectCompany(val) {
            this.vacancy.company.company_name = val.name
            this.vacancy.company.id = val.id
        },
        selectEmployment(val) {
            this.vacancy.type_employment = val
        },
        edit(vacancy) {
            this.$router.push({
                path: `/profile?vacancy=true&action=edit&id=${this.$route.query.id}`,
                query: {
                    name: vacancy.name,
                    id: vacancy.id,
                }
            })
        },
        archive() {
            this.isArchiving = true
            this.$store.dispatch('rec/editVacancy', {
                id: this.$route.query.id,
                data: {
                    status: this.vacancy.status === 'archive' ? this.vacancy.active ? 'actual' : 'draft' : 'archive'
                }
            })
                .then(result => {
                    if (this.vacancy.status === 'archive') {
                        if (this.vacancy.active) {
                            this.modalOpen('actVacSuccess')
                        } else {
                            this.modalOpen('draftVacSuccess')
                        }
                    } else {
                        this.modalOpen('archVacSuccess')
                    }
                    this.acceptVacancyResult(result)
                })
                .finally(() => this.isArchiving = false)
        },
        save() {
            this.$v.vacancy.$touch()
            if (this.$v.vacancy.$invalid) {
                if (this.$v.vacancy.name.$error || this.$v.vacancy.company.$error || this.$v.vacancy.city.$error) this.$scrollTo(this.$refs.name)
                else if (this.$v.vacancy.description.$error || this.$v.vacancy.duties.$error || this.$v.vacancy.requirements.$error || this.$v.vacancy.conditions.$error) this.$scrollTo(this.$refs.description)
                else if (this.$v.vacancy.specialisation.$error) this.$scrollTo(this.$refs.specialisation)
                else if (this.$v.vacancy.field_activity.$error) this.$scrollTo(this.$refs.field_activity)
            } else if (!this.isSaving) {
                this.isSaving = true

                if (this.vacancy.salary_before) this.vacancy.salary_before = parseInt(this.vacancy.salary_before.replace(/,/g, ''))
                else this.vacancy.salary_before = null
                if (this.vacancy.salary_from) this.vacancy.salary_from = parseInt(this.vacancy.salary_from.replace(/,/g, ''))
                else this.vacancy.salary_from = null

                if (this.isCreate) {
                    this.$store.dispatch('rec/createVacancy', {
                        ...this.vacancy,
                        company: {
                            id: this.vacancy.company.id
                        },
                        questions_for_quick_apply_form: !this.vacancy.questions_for_quick_apply_form.length ? this.defaultQuestions : this.vacancy.questions_for_quick_apply_form,
                        type_employment: this.employments.find(x => x.value === this.vacancy.type_employment).slug,
                        status: this.vacancy.active ? 'actual' : 'draft'
                    })
                        .then(() => this.modalOpen('createVacSuccess'))
                        .catch(error => this.modalOpen('vacFail', error))
                        .finally(() => this.isSaving = false)
                } else if (this.isEdit) {
                    delete this.vacancy.date_create
                    delete this.vacancy.modified
                    delete this.vacancy.id
                    delete this.vacancy.req_vacancy
                    delete this.vacancy.is_internship
                    delete this.vacancy.section_internships
                    delete this.vacancy.vacancy_owner
                    delete this.vacancy.type_employments

                    this.$store.dispatch('rec/editVacancy', {
                        id: +this.$route.query.id,
                        data: {
                            ...this.vacancy,
                            company: {
                                id: this.vacancy.company.id
                            },
                            questions_for_quick_apply_form: !this.vacancy.questions_for_quick_apply_form.length ? this.defaultQuestions : this.vacancy.questions_for_quick_apply_form,
                            type_employment: this.employments.find(x => x.value === this.vacancy.type_employment).slug,
                            field_activity: this.field_activity_initial
                                .filter(x => !this.vacancy.field_activity.some(y => x.id === y.id))
                                .map(x => ({ name: x.name, remove: true }))
                                .concat(this.vacancy.field_activity),
                            specialisation: this.specialisation_initial
                                .filter(x => !this.vacancy.specialisation.some(y => x.id === y.id))
                                .map(x => ({ name: x.name, remove: true }))
                                .concat(this.vacancy.specialisation),
                            status: this.vacancy.status === 'archive' && this.vacancy.active ? 'actual'
                                : this.vacancy.status === 'archive' && !this.vacancy.active ? 'archive'
                                    : this.vacancy.active ? 'actual' : 'draft'
                        }
                    })
                        .then(result => {
                            this.$router.push(`/profile?vacancy=true&action=watch&id=${this.$route.query.id}`)
                            this.acceptVacancyResult(result)
                            this.modalOpen('changeVacSuccess')
                        })
                        .catch(error => this.modalOpen('vacFail', error))
                        .finally(() => this.isSaving = false)
                }
            }
        },
        removeSpecialisations(id) {
            const specialisations = [...this.specialisations];
            specialisations.splice(specialisations.findIndex(el => el.id === id), 1)
            this.setSpecialisations(specialisations);
        },
        removeActivities(id) {
            const activities = [...this.activities];
            activities.splice(activities.findIndex(el => el.id === id), 1)
            this.setActivities(activities);
        }
    }
}
</script>

<style lang="less">
.create-resume-block {
	//padding-top: 55px;
	padding-bottom: 20px;
	@media (max-width: 1100px) {
		//padding-top: 45px;
	}
	@media (max-width: 905px) {
	}
	.grid {
		margin: 0 -25px;
	}
	.box {
		width: 33.33%;
		padding: 0 25px;
		@media @mobile-end {
			width: 100%;
		}
	}
	.grey-grid {
		.box {
			@media @mobile-end {
				padding: 0;
			}
		}
	}
	.block-title {
		margin-bottom: 35px;
		@media @mobile-end {
			font-size: 25px;
		}
	}
	.button-control {
		display: block;
		height: auto;
		line-height: 1.4;
	}
	.button {
		@media @mobile-end {
			width: 100%;
			min-width: 100%;
			margin-left: 0 !important;
		}
	}
	.value-wrap {
		.field-label {
			font-size: 13px;
			color: @color-light;
			margin-bottom: 15px;
		}
		.field-value {
			color: @color-text;
			font-size: 16px;
		}
	}
	.profile-avatar-wrap {
		max-width: 200px;
	}
	.profile-avatar {
		width: 200px;
		height: 200px;
	}
}
.create-resume-section {
	padding-bottom: 25px;
	@media (max-width: 1100px) {
		padding-bottom: 20px;
	}
	@media @mobile-end {
		padding-bottom: 16px;
	}
	.text {
		p {
			small {
				display: inline;
				margin-bottom: 0;
			}
		}
	}
	.select-type-simple {
		max-width: 250px;
		margin-bottom: 40px;
	}
	.button-group {
		margin-bottom: -20px;
		.button {
			margin-right: 20px;
			margin-bottom: 20px;
			@media @mobile-end {
				width: 100%;
			}
			&:last-child {
				margin-right: 0;
			}
		}
		.button-hollow {
			margin-right: 20px;
			margin-bottom: 20px;
			@media @mobile-end {
				width: 100%;
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}
}
.questions-title__wrap {
	width: max-content;
	position: relative;
	.questions__help-icon {
		cursor: pointer;
		position: absolute;
		right: -30px;
		top: -10px;
	}
}
</style>
