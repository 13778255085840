<template>
	<div :class="editable ? 'panel-shadow' : 'panel'">
		<template v-if="editable">
			<div class="grid">
				<div class="box">
					<div class="field-wrap">
						<select-2
							v-if="isEdit"
							:options="EDU"
							:label="'Образование:'"
							:value="SP.name.length > 29 ? SP.name.slice(0, 29) + '...' : SP.name"
							@selected="selectEdu"
						/>
						<select-2
							v-else
							:options="EDU"
							:label="'Образование:'"
							:value="SP.name.length > 29 ? SP.name.slice(0, 29) + '...' : SP.name"
							:error="$v.SP.name.$dirty && !$v.SP.name.required ? 'Это обязательное поле' : ''"
							@selected="selectEdu"
						/>
					</div>
				</div>
				<template v-if="isExtraStudy">
					<div class="box">
						<div class="field-wrap">
							<label class="field-label">
								<input
									v-model="SP.specialization"
									type="text"
									class="field"
									placeholder="Специальность:"
								>
								<div class="label">Специальность:</div>
							</label>
						</div>
					</div>
					<div class="box">
						<div class="field-wrap">
							<label class="field-label">
								<c-select
									:name="'Учебное заведение:'"
									autosearch-education
									:default-data="SP.institution.name"
									:is-error="$v.SP.institution.name.$dirty && !$v.SP.institution.name.required"
									@selected="selectEduFac"
								/>
							</label>
						</div>
					</div>
					<div class="box">
						<div class="field-wrap">
							<select-2
								:options="options.years"
								:label="'Год окончания:'"
								:value="SP.graduation_year"
								:error="$v.SP.graduation_year.$dirty && $v.SP.graduation_year.$error ? 'Это обязательное поле' : ''"
								@selected="selectYear"
							/>
						</div>
					</div>
				</template>
			</div>
			<button
				class="button"
				@click="save"
				v-text="textButtonSave"
			/>
			<button
				class="button-hollow"
				style="margin-left: 20px;"
				@click="cancel"
			>
				Отменить
			</button>
		</template>
		<template v-else>
			<div
				v-if="!isWatch"
				class="panel-remove"
				@click="remove"
			/>
			<div class="cells">
				<div class="cell">
					<div class="text dark sm word-style">
						<p>{{ SP.name }}</p>
					</div>
				</div>
				<div class="cell">
					<div class="text dark sm word-style">
						<p v-if="SP.institution && SP.institution.name">
							<small>Учебное заведение:</small><br>
							{{ SP.institution.name }}
						</p>
						<p
							v-if="SP.specialization"
							class="word-style"
						>
							<small>Специальность:</small><br>
							{{ SP.specialization }}
						</p>
						<p
							v-if="SP.graduation_year"
							class="word-style"
						>
							<small>Год окончания:</small><br>
							{{ SP.graduation_year }}
						</p>
					</div>
				</div>
			</div>
			<template v-if="!isWatch">
				<br>
				<button
					class="button-control"
					@click="edit"
				>
					<img
						:src="'/img/icons/icon-edit.png' | prodSrcImage"
						alt="Редактировать образование"
					>
					Редактировать
				</button>
			</template>
		</template>
	</div>
</template>

<script>

import {required, minValue} from 'vuelidate/lib/validators'
import {mapGetters} from 'vuex';

export default {
    props: {
        spData: {
            type: Object,
            default: () => new Object()
        },
        isAdd: {
            type: Boolean,
            default: false
        },
        isWatch: {
            type: Boolean,
            default: false
        }
    },
    validations() {
        let SP = {
            name: {required}
        }

        if (this.isExtraStudy) {
            SP = {
                name: {required},
                institution: {
                    name: {required}
                },
                graduation_year: {
                    minValue: minValue(1980),
                    required
                }
            }
        }

        return {
            SP
        }
    },
    data: () => ({
        isEdit: false,
        SP: {
            name: '',
            institution: {
                name: ''
            },
            specialization:'',
            graduation_year: 0
        },
        options: {
            years: []
        }
    }),
    computed: {
        ...mapGetters('cnst', {
            EDU: 'getEducation'
        }),
        editable() {
            return this.isEdit || this.isAdd
        },
        isExtraStudy() {
            if (+this.SP.name === 0 || this.SP.name.toLowerCase() === 'неоконченное среднее' || this.SP.name.toLowerCase() === 'среднее') return false
            else return true
        },
        textButtonSave() {
            if (this.isEdit) {
                return 'Сохранить'
            } else if (this.isAdd) {
                return 'Добавить'
            } else {
                return ''
            }
        }
    },
    watch: {
        spData() {
            if (this.spData && this.spData.name) {
                this.SP = JSON.parse(JSON.stringify(this.spData))
            }
        }
    },
    created() {
        let start = 1980
        if (this.spData && this.spData.name) {
            this.SP = JSON.parse(JSON.stringify(this.spData))
            !this.SP.institution ? this.SP.institution = {name: ''} : true
            !this.SP.specialization ? this.SP.specialization = '' : true
        } else {
            this.reset()
        }

        this.$v.SP.name.$reset()

        while (start <= new Date().getFullYear() + 6) {
            this.options.years.push(start++)
        }
    },
    methods: {
        edit() {
            this.isEdit = true
            this.$emit('edit')
        },
        save() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                console.log('Error in Study Place panel', this.$v)
            } else {
                if (this.isEdit) {
                    this.$emit('saved', this.SP)
                    this.isEdit = false
                } else if (this.isAdd) {
                    !this.SP.institution.name ? this.institution = null : true
                    this.$emit('added', this.SP)
                    this.reset()
                }
            }
        },
        reset() {
            this.$v.$reset()
            this.SP = {
                name: '',
                institution: {
                    name: ''
                },
                specialization: '',
                graduation_year: 0
            }
        },
        cancel() {
            this.$emit('cancel')
            this.isEdit = false
            if (this.isAdd) {
                this.reset()
            }
        },
        remove() {
            this.$emit('remove')
        },
        selectEdu(val) {
            this.SP.name = val
        },
        selectEduFac(val) {
            this.SP.institution.name = val;
        },
        selectYear(val) {
            this.SP.graduation_year = val
        }
    }
}
</script>

<style lang="less">
.word-style {
    word-break: break-word;
    white-space: pre-line;
}
</style>
