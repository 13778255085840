<template>
	<section class="section">
		<template v-if="watchUser || $route.query.action === 'watch'">
			<watch-user-resume />
		</template>
		<template v-else>
			<div class="content">
				<router-link
					:to="`/profile/${$route.params.id}?resumes=true`"
					class="button-hollow icon-hover create-resume-back"
				>
					<span class="icon icon-back" />
					К списку резюме
				</router-link>
				<div
					v-if="isLoaded"
					class="preloader"
					style="margin-top: 100px;"
				/>
				<div
					v-else
					class="create-resume-block"
				>
					<div
						ref="mainInfo"
						class="create-resume-section"
					>
						<div class="block-title">
							Основная информация
						</div>

						<div class="profile-avatar-wrap">
							<label
								class="profile-avatar"
								:style="srcAvatar | bgrDynamicImageCover"
							>
								<input
									ref="avatar"
									type="file"
									@change="changeAvatar"
								>
							</label>
							<span
								v-if="isRemoveAvatar"
								class="link-dashed"
								@click="removeAvatar"
							>Удалить фото</span>
						</div>

						<div class="grid">
							<div class="box">
								<div class="field-wrap">
									<label
										class="field-label"
										@click="clickToUnedit('last_name')"
									>
										<input
											v-model.trim="mainInfo.last_name"
											type="text"
											class="field"
											readonly
											placeholder="Фамилия"
										>
										<div class="label">Фамилия</div>
										<span
											v-if="isLastNameClick"
											class="help-block form-warning"
										>
											Это нередактируемое поле
										</span>
									</label>
								</div>
							</div>
							<div class="box">
								<div class="field-wrap">
									<label
										class="field-label"
										@click="clickToUnedit('first_name')"
									>
										<input
											v-model.trim="mainInfo.first_name"
											type="text"
											class="field"
											readonly
											placeholder="Имя"
										>
										<div class="label">Имя</div>
										<span
											v-if="isFirstNameClick"
											class="help-block form-warning"
										>
											Это нередактируемое поле
										</span>
									</label>
								</div>
							</div>
							<div class="box">
								<div class="field-wrap">
									<label
										class="field-label"
										@click="clickToUnedit('middle_name')"
									>
										<input
											v-model.trim="mainInfo.middle_name"
											type="text"
											class="field"
											readonly
											placeholder="Отчество"
										>
										<div class="label">Отчество</div>
										<span
											v-if="isMiddleNameClick"
											class="help-block form-warning"
										>
											Это нередактируемое поле
										</span>
									</label>
								</div>
							</div>
							<div class="box">
								<div class="field-wrap">
									<label
										class="field-label"
										@click="clickToUnedit('birthday')"
									>
										<input
											:value="mainInfo.birthday"
											type="text"
											class="field"
											readonly
											placeholder="Дата рождения"
										>
										<div class="label">Дата рождения</div>
										<span
											v-if="isBirthdayClick"
											class="help-block form-warning"
										>
											Это нередактируемое поле
										</span>
									</label>
								</div>
							</div>
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model.trim="mainInfo.nationality"
											type="text"
											class="field"
											placeholder="Гражданство"
										>
										<div class="label">Гражданство</div>
										<span
											v-if="$v.mainInfo.nationality.$error"
											class="help-block form-error"
										>
											{{ !$v.mainInfo.nationality.required ? 'Это обязательное поле' : 'Ошибка поля' }}
										</span>
									</label>
								</div>
							</div>
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model="mainInfo.phone"
											v-mask="'+7(###)###-##-##'"
											type="text"
											class="field"
											placeholder="Телефон"
										>
										<div class="label">Телефон</div>
										<span
											v-if="$v.mainInfo.phone.$error"
											class="help-block form-error"
										>
											{{ !$v.mainInfo.phone.required ? 'Это обязательное поле' : !$v.mainInfo.phone.Phone ? 'Введите корректный номер телефона' : 'Ошибка поля' }}
										</span>
									</label>
								</div>
							</div>
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model.trim="mainInfo.email"
											type="email"
											class="field"
											placeholder="Email"
										>
										<div class="label">Email</div>
										<span
											v-if="$v.mainInfo.email.$error"
											class="help-block form-error"
										>
											{{ !$v.mainInfo.email.required ? 'Это обязательное поле' : !$v.mainInfo.email.email ? 'Введите корректный e-mail' : 'Ошибка поля' }}
										</span>
									</label>
								</div>
							</div>
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model.trim="mainInfo.city"
											type="text"
											class="field"
											placeholder="Город"
										>
										<div class="label">Город</div>
									</label>
								</div>
							</div>
							<div class="box full">
								<div class="field-wrap">
									<label class="field-label">
										<textarea
											v-model="mainInfo.about_me"
											rows="3"
											class="field field--no-resize"
											placeholder="О себе"
										/>
										<div class="label">О себе</div>
									</label>
								</div>
							</div>
						</div>
					</div>

					<div
						ref="spec"
						class="create-resume-section"
					>
						<div class="block-title">
							Специальность
						</div>
						<div class="grid">
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model="specialization.preferred_position"
											type="text"
											class="field"
											placeholder="Желаемая должность"
										>
										<div class="label">Желаемая должность</div>
										<span
											v-if="$v.specialization.preferred_position.$error"
											class="help-block form-error"
										>Это обязательное поле</span>
									</label>
								</div>
							</div>
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model="specialization.salary"
											v-mask="mask"
											type="text"
											class="field"
											placeholder="Зарплата"
											@keydown="deleteSalary($event)"
										>
										<div class="label">Зарплата</div>
									</label>
								</div>
							</div>
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<c-select
											:options="employmentOptions"
											:name="'Тип занятости'"
											:default-data="specialization.type_of_employment ? employments.find(x => x.slug === specialization.type_of_employment).value : ''"
											@selected="selectEmployment"
										/>
									</label>
								</div>
							</div>
						</div>
					</div>

					<div class="create-resume-section">
						<div class="block-title">
							Опыт работы
						</div>
						<template>
							<work-place
								v-for="(item, index) in workPlaces"
								:key="item.id"
								:wp-data="item"
								@saved="saveEditWorkPlace($event, index)"
								@remove="removeWorkPlace(item.id, index)"
							/>
						</template>
						<transition-group
							name="list"
							tag="div"
						>
							<work-place
								v-for="(item, index) in newWPs"
								:key="index"
								:wp-data="item"
								@remove="() => newWPs.splice(index, 1)"
							/>
						</transition-group>

						<transition name="fade">
							<work-place
								v-show="isNewWPs"
								ref="new-wp"
								:is-add="isNewWPs"
								@added="addNewWorkPlace"
								@cancel="isNewWPs = false"
							/>
						</transition>

						<div
							v-if="!isNewWPs && !isWatch"
							class="field-wrap"
							@click="isNewWPs = true"
						>
							<button class="button-control">
								<img
									:src="'/img/icons/icon-add.png' | prodSrcImage"
									alt="Добавить место работы"
								>
								Добавить место работы
							</button>
						</div>
					</div>

					<div class="create-resume-section">
						<div class="block-title">
							Образование
						</div>

						<template>
							<study-place
								v-for="(item, index) in studyPlaces"
								:key="item.id"
								:sp-data="item"
								@saved="saveEditStudyPlace($event, index)"
								@remove="removeStudyPlace(item.id, index)"
							/>
						</template>
						<transition-group
							name="list"
							tag="div"
						>
							<study-place
								v-for="(item, index) in newSPs"
								:key="index"
								:sp-data="item"
								@remove="() => newSPs.splice(index, 1)"
							/>
						</transition-group>

						<transition name="fade">
							<study-place
								v-show="isNewSPs"
								ref="new-sp"
								:is-add="isNewSPs"
								@added="addNewStudyPlace"
								@cancel="isNewSPs = false"
							/>
						</transition>

						<div
							v-if="!isNewSPs && !isWatch"
							class="field-wrap"
							@click="isNewSPs = true"
						>
							<button class="button-control">
								<img
									:src="'/img/icons/icon-add.png' | prodSrcImage"
									alt="Добавить образование"
								>
								Добавить образование
							</button>
						</div>
					</div>
					<div class="profile-buttons">
						<div class="grid">
							<div class="box" />
							<div class="box">
								<button
									v-if="!isWatch && !isSaving"
									class="button lg create-resume-save"
									@click="saveResume"
								>
									{{ buttonSaveText }}
								</button>
								<div
									v-else-if="!isWatch"
									class="button create-resume-save lg button-preloader"
								>
									{{ buttonSaveText }}
									<div class="button-preloader-icons">
										<div class="button-preloader-icon" />
										<div class="button-preloader-icon" />
										<div class="button-preloader-icon" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
	</section>
</template>

<script>

import {mapActions, mapGetters} from 'vuex'
import {required, email, maxLength} from 'vuelidate/lib/validators'
import {Phone, Birthday, alphaSpace} from '@/assets/scripts/custom_validators'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import WorkPlace from '@/components/panels/WorkPlace'
import StudyPlace from '@/components/panels/StudyPlace'
import WatchUserResume from '@/pages/cabinet/user/WatchUserResume'
import Tb from '@/api/teachbase'
import UserEd from '@/api/userEducation';
import UserJob from '@/api/userJob';
import User from '@/api/user';

const numberMask = createNumberMask({
    thousandsSeparatorSymbol: ',',
    prefix: '',
    suffix: ' ₽',
    decimalLimit: 0
})

const MONTHS = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
]

export default {
    components: {
        'work-place': WorkPlace,
        'study-place': StudyPlace,
        'watch-user-resume': WatchUserResume
    },
    filters: {
        WPDateForInnerFormat(val, slug = null) {
            if (slug === 'Y') {
                return parseInt(val.split('-')[0])
            } else if (slug === 'M') {
                return parseInt(val.split('-')[1]) - 1
            } else {
                return `${parseInt(val.split('-')[1]) - 1}.${parseInt(val.split('-')[0])}`
            }
        },
        WPDateForServerFormat(val) {
            let date = val.split('.')
            return `${date[1]}-${+date[0] + 1}-01`
        }
    },
    data: () => ({
        watchUser: false,
        mask: numberMask,
        isLoaded: true,
        isSaving: false,
        avatar: {
            id: null,
            dft: '/img/avatar.png',
            crnt: '',
            slct: '',
            isRemoved: false
        },
        mainInfo: {
            user: '',
            last_name: '',
            first_name: '',
            middle_name: '',
            birthday: '',
            phone: '',
            email: '',
            nationality: 'Российская Федерация',
            about_me: ''
        },
        specialization: {
            preferred_position: '',
            salary: '',
            type_of_employment: ''
        },
        isBirthdayClick: false,
        isFirstNameClick: false,
        isLastNameClick: false,
        isMiddleNameClick: false,
        workPlaces: undefined,
        pullEditableWPs: [],
        pullRemovedWPs: [],
        newResumeID: null,
        isNewWPs: false,
        newWPs: [],
        userStudy: null,
        studyPlaces: undefined,
        isNewSPs: false,
        newSPs: [],
        pullEditableSPs: [],
        pullRemovedSPs: [],
        sendSP: [],
        sendWP: [],
        options: {
            years: new Array(),
            months: MONTHS
        },
        isDownloaded: false,
    }),
    validations: {
        mainInfo: {
            user: {required},
            last_name: {
                required,
                alphaSpace
            },
            first_name: {
                required,
                maxLength: maxLength(30),
                alphaSpace
            },
            middle_name: { alphaSpace },
            birthday: {
                required,
                Birthday
            },
            nationality: {
                required
            },
            phone: {
                required,
                Phone
            },
            email: {
                email,
                required
            }
        },
        specialization: {
            preferred_position: {required}
        }
    },
    computed: {
        ...mapGetters('user', {
            user: 'userInfo',
            userID: 'userID'
        }),
        ...mapGetters('cnst', {
            employments: 'getEmployments'
        }),
        resumes() {
            return this.$store.state.tb.resumes
        },
        srcAvatar() {
            if (this.avatar.slct) return this.avatar.slct
            else if (this.avatar.crnt) return this.avatar.crnt
            else return IS_DEV ? this.avatar.dft : `/staticfiles${this.avatar.dft}`
        },
        isRemoveAvatar() {
            return !!this.avatar.slct || !!this.avatar.crnt
        },
        userPhoneError() {
            if (this.mainInfo.phone) return Phone(this.mainInfo.phone)
            return true
        },
        isWatch() {
            return this.$route.query.action === 'watch' ? true : false
        },
        isCreate() {
            return this.$route.query.action === 'create' ? true : false
        },
        buttonSaveText() {
            if (this.$route.query.action === 'create') return 'Создать резюме'
            else if (this.$route.query.action === 'edit') return 'Сохранить изменения'
            return ''
        },
        employmentOptions() {
            return this.employments.map(x => x.value)
        }
    },
    created() {
        if (this.$route.params.id !== JSON.stringify(this.userID)) this.watchUser = true
        if (this.$route.query.action === 'create') {
            this.getStudyPlaceUser().then(res => {
                this.studyPlaces = res
            })
            this.getWorkPlaceUser().then(res => {
                this.workPlaces = res && res.length && res.map(x => ({
                    ...x,
                    commencement_date: this.$options.filters.WPDateForInnerFormat(x.commencement_date),
                    completion_date: x.to_present ? null : this.$options.filters.WPDateForInnerFormat(x.completion_date)
                })) || []
            })
            if (this.user) {
                this.initResumeСData(this.user)
            } else {
                this.$store.dispatch('user/detail').then(user => this.initResumeСData(user))
            }
        } else if (this.$route.query.action === 'edit') {
            if (this.resumes) {
                this.initResumeEWData()
                if (this.$route.query.id) {
                    this.getStudyPlace(this.$route.query.id).then(res => {
                        this.studyPlaces = res
                    })
                    this.getWorkPlace(this.$route.query.id).then(res => {
                        this.workPlaces = res && res.length && res.map(x => ({
                            ...x,
                            commencement_date: this.$options.filters.WPDateForInnerFormat(x.commencement_date),
                            completion_date: x.to_present ? null : this.$options.filters.WPDateForInnerFormat(x.completion_date)
                        })) || []
                    })
                }
            } else {
                this.$store.dispatch('tb/getAllResumes').then(() => this.initResumeEWData())
                this.getStudyPlace(this.$route.query.id).then(res => {
                    this.studyPlaces = res
                })
                this.getWorkPlace(this.$route.query.id).then(res => {
                    this.workPlaces = res && res.length && res.map(x => ({
                        ...x,
                        commencement_date: this.$options.filters.WPDateForInnerFormat(x.commencement_date),
                        completion_date: x.to_present ? null : this.$options.filters.WPDateForInnerFormat(x.completion_date)
                    })) || []
                })
            }
        }

        let start = 1980;
        while (start <= new Date().getFullYear()) {
            this.options.years.push(start++)
        }
    },
    methods: {
        ...mapActions('user', {
            saveChanges: 'change',
            getStudyPlace: 'getStudyPlaceResume',
            getWorkPlace: 'getWorkPlaceResume',
            getStudyPlaceUser: 'getStudyPlace',
            getWorkPlaceUser: 'getWorkPlace'
        }),
        ...mapActions('cmn', {
            createImage: 'createImage',
            removeImage: 'removeImage',
            updateImage: 'updateImage'
        }),
        ...mapActions('tb', {
            getResume: 'getResume',
        }),
        initResumeСData(source) {
            let [year, month, day] = source.birthday ? source.birthday.split('-') : ['', '', '']
            this.mainInfo.user = source.id
            this.mainInfo.last_name = source.last_name
            this.mainInfo.first_name = source.first_name
            this.mainInfo.middle_name = source.middle_name
            this.mainInfo.birthday = `${day}.${month}.${year}`
            this.mainInfo.phone = source.phone
            this.mainInfo.email = source.email
            this.mainInfo.city = source.city.name
            this.mainInfo.about_me = source.about_me
            this.avatar.crnt = source.image_user.length ? source.image_user[0].image : null
            this.isLoaded = false
        },
        initResumeEWData() {
            this.getResume({
                id: this.$route.query.id})
                .then(res => {
                    let resume = res
                    let [year, month, day] = this.user.birthday ? this.user.birthday.split('-') : ['', '', ''];
                    this.mainInfo.user = this.user.id
                    this.mainInfo.last_name = this.user.last_name
                    this.mainInfo.first_name = this.user.first_name
                    this.mainInfo.middle_name = this.user.middle_name
                    this.mainInfo.birthday = `${day}.${month}.${year}`
                    this.mainInfo.phone = resume.phone ? resume.phone : resume.user.phone
                    this.mainInfo.email = resume.email ? resume.email : resume.user.email
                    this.mainInfo.city = resume.city ? resume.city : resume.user.city ? resume.user.city.name : ''
                    this.avatar.crnt = resume.candidate_photo ? resume.candidate_photo : resume.candidate_photo_url
                    this.mainInfo.about_me = resume.about_me
                    this.mainInfo.nationality = resume.nationality
                    this.specialization.preferred_position = resume.preferred_position
                    this.specialization.salary = resume.salary
                    this.specialization.type_of_employment = resume.type_of_employment
                    this.isLoaded = false
                })
        },
        changeAvatar() {
            let reader = new FileReader();
            let file = this.$refs.avatar.files[0]

            if (file) {
                if (file.size > Math.pow(2, 21)) {
                    this.modalOpen('stdFail', 'Превышен допустимый размер изображения')
                    return
                } else if (!/(jpeg)|(png)|(svg)/gi.test(file.type)) {
                    this.modalOpen('stdFail', 'Недопустимый формат изображения')
                    return
                } else {
                    reader.onload = event => {
                        this.avatar.slct = event.target.result
                        this.avatar.isRemoved = false
                    }
                    reader.readAsDataURL(file)
                }
            }
        },
        removeAvatar() {
            this.avatar.slct = null
            this.avatar.crnt = null
            this.$refs.avatar.value = null
            this.avatar.isRemoved = true
        },
        deleteSalary(event) {
            if (event.keyCode === 8 || event.key === 'Backspace') {
                if (event.target.selectionStart === this.specialization.salary.length) {
                    let [salary, sufix] = this.specialization.salary.split(' ')
                    this.specialization.salary = `${salary.substr(0, salary.length - 1)} ${sufix}`
                }
            }
        },
        clickToUnedit(slug) {
            if (slug === 'birthday') {
                this.isBirthdayClick = true
                setTimeout(() => this.isBirthdayClick = false, 2000)
            }
            if (slug === 'last_name') {
                this.isLastNameClick = true
                setTimeout(() => this.isLastNameClick = false, 2000)
            }
            if (slug === 'first_name') {
                this.isFirstNameClick = true
                setTimeout(() => this.isFirstNameClick = false, 2000)
            }
            if (slug === 'middle_name') {
                this.isMiddleNameClick = true
                setTimeout(() => this.isMiddleNameClick = false, 2000)
            }
        },
        selectCity(val) {
            this.mainInfo.city = val
        },
        selectEmployment(val) {
            this.specialization.type_of_employment = this.employments.find(x => x.value === val).slug
        },
        saveEditWorkPlace(data, index) {
            this.pullEditableWPs.push(data)
            this.workPlaces.splice(index, 1, data)
        },
        addNewWorkPlace(data) {
            this.isNewWPs = false
            this.newWPs.push(data)
        },
        removeWorkPlace(original, pseudo) {
            this.workPlaces.splice(pseudo, 1)
            this.pullRemovedWPs.push(original)
        },
        saveEditStudyPlace(data, index) {
            this.pullEditableSPs.push(data)
            this.studyPlaces.splice(index, 1, data)
        },
        addNewStudyPlace(data) {
            this.isNewSPs = false
            this.newSPs.push(data)
        },
        removeStudyPlace(original, pseudo) {
            this.studyPlaces.splice(pseudo, 1)
            this.pullRemovedSPs.push(original)
        },
        CURImage(id) {
            return new Promise(resolve => {
                if (this.avatar.slct) { // Create/Update
                    let formData = new FormData()
                    formData.append('candidate_photo', this.$refs.avatar.files[0])

                    Tb.addResumeImage({
                        id: this.$route.query.id || id,
                        data: formData
                    }).then(response => {
                        this.avatar.crnt = response.data.candidate_photo
                        resolve()
                    })
                } else if (this.avatar.isRemoved) { // Remove
                    const resume = this.resumes.get(+this.$route.query.id)
                    if (resume) {
                        if (resume.candidate_photo) Tb.removeResumeImage(this.$route.query.id).then(() => this.avatar.crnt = null).then(resolve)
                        if (resume.candidate_photo_url) this.avatar.crnt = null
                        resolve()
                    } else {
                        this.avatar.crnt = null
                        resolve()
                    }
                } else resolve()
            })
        },
        downloadResume(id) {
            this.isDownloaded = true
            User.downloadResume(id)
                .then(response => {
                    if (response.status === 204) this.downloadResume(id)
                    else {
                        window.open(response.data.download_link, '_blank')
                        this.isDownloaded = false
                    }
                })
        },
        async saveResume() {
            const delay = t => new Promise(resolve => setTimeout(resolve, t));
            this.$v.$touch()
            if (this.$v.mainInfo.$invalid) {
                console.log('Error in Main info')
                this.$scrollTo(this.$refs.mainInfo)
            } else if (this.$v.specialization.$invalid) {
                console.log('Error in Specialization info')
                this.$scrollTo(this.$refs.spec)
            } else if (!this.isSaving) {
                this.isSaving = true
                if (this.newSPs.length > 0) {
                    this.newSPs.forEach( item => {
                        item.school_class_number = null
                        item.school_number = null
                        item.city = null
                        if (item.name === 'Среднее' && item.name === 'Неоконченное среднее') {
                            item.specialization = null
                            item.graduation_year = null
                            item.institution.name = ''
                        }
                    })
                }
                if (this.$route.query.action === 'create') {
                    let salary = this.specialization.salary.includes(' ₽') ? +(this.specialization.salary.replace(' ₽', '').replaceAll(',', '')) : 0

                    this.studyPlaces.forEach( item => {
                        delete item.id
                        delete item.primary_education
                    })
                    this.workPlaces.forEach( item => {
                        delete item.id
                    })
                    let sendSP = this.studyPlaces.concat(this.newSPs)
                    let sendWP = this.workPlaces.concat(this.newWPs)
                    sendSP.forEach( item => {
                        if (item.institution !== null) {
                            if (item.institution.name === '') item.institution = null
                        }
                    })

                    sendWP.forEach(place => {
                        let [month, year] = place.commencement_date.split('.')
                        place.commencement_date = `${year}-${++month}-01`

                        if (place.to_present) {
                            place.completion_date = null
                        } else {
                            let [month, year] = place.completion_date.split('.')
                            place.completion_date = `${year}-${++month}-01`
                        }
                    })

                    if (this.newSPs.length === 0 && this.studyPlaces.length === 0) {
                        this.modalOpen('studyFail', 'Укажите образование')
                        this.isSaving = false
                    } else {
                        this.$store.dispatch('tb/createResume', {
                            ...this.mainInfo,
                            ...this.specialization,
                            [this.avatar.crnt ? 'candidate_photo_url' : 'candidate_photo']: this.avatar.crnt,
                            salary,
                            type_of_employment: this.specialization.type_of_employment
                        })
                            .then(response => {
                                this.CURImage(response.id)
                                this.newResumeID = response.id
                            })
                            .then(() => UserEd.addStudyPlaceResume({data: sendSP, id: this.newResumeID}))
                            .then(() => UserJob.addWorkPlaceResume({data: sendWP, id: this.newResumeID}))
                            .then(() => delay(2000).then(() => this.modalOpen('createResSuccess')))
                            .catch(error => this.modalOpen('createResFail', error.text))
                            .finally(() => this.isSaving = false)
                    }
                } else if (this.$route.query.action === 'edit') {
                    let salary = this.specialization.salary.includes(' ₽') ? +(this.specialization.salary.replace(' ₽', '').replaceAll(',', '')) : 0
                    await this.CURImage()
                    this.newWPs.forEach(place => {
                        let [month, year] = place.commencement_date.split('.')
                        place.commencement_date = `${year}-${++month}-01`

                        if (place.to_present) {
                            place.completion_date = null
                        } else {
                            let [month, year] = place.completion_date.split('.')
                            place.completion_date = `${year}-${++month}-01`
                        }
                    })

                    if (this.newSPs.length > 0) {
                        this.newSPs.forEach( item => {
                            if (item.institution.name === '') item.institution = null
                        })
                    }
                    if (this.newSPs.length === 0 && this.studyPlaces.length === 0 && this.pullEditableSPs.length === 0) {
                        this.modalOpen('studyFail', 'Укажите образование')
                        this.isSaving = false
                    } else {
                        this.$store.dispatch('tb/changeResume', {
                            id: +this.$route.query.id,
                            resume: {
                                ...this.mainInfo,
                                ...this.specialization,
                                [this.avatar.crnt ? 'photo' : 'candidate_photo_url']: this.avatar.crnt,
                                salary,
                                type_of_employment: this.specialization.type_of_employment
                            }
                        })
                            .then(() => UserEd.addStudyPlaceResume({data: this.newSPs, id: this.$route.query.id}))
                            .then(() => UserJob.addWorkPlaceResume({data: this.newWPs, id: this.$route.query.id}))
                            .then(() => {
                                this.pullEditableSPs.forEach(place => UserEd.changeStudyPlaceResume({
                                    resume_id: this.$route.query.id,
                                    place: {
                                        city: place.city,
                                        name: place.name,
                                        specialization: place.specialization,
                                        institution: place.institution,
                                        school_class_number: place.school_class_number,
                                        school_number: place.school_number,
                                        graduation_year: place.graduation_year,
                                        primary_education: place.primary_education
                                    },
                                    education_id: place.id
                                }))
                            })
                            .then(() => this.pullEditableWPs.forEach(place => {
                                let [year, month] = place.commencement_date.includes('.') ?
                                    place.commencement_date.split('.').reverse() :
                                    place.commencement_date.split('-')
                                place.commencement_date = `${year}-${place.commencement_date.includes('.') ? ++month : month}-01`

                                if (place.to_present) {
                                    place.completion_date = null
                                } else {
                                    let [year, month] = place.completion_date.includes('.') ?
                                        place.completion_date.split('.').reverse() :
                                        place.completion_date.split('-')
                                    place.completion_date = `${year}-${place.completion_date.includes('.') ? ++month : month}-01`
                                }

                                UserJob.changeWorkPlaceResume({
                                    place,
                                    resume_id: this.$route.query.id,
                                    job_id: place.id
                                })
                            }))
                            .then(() => {
                                this.pullRemovedWPs.forEach(item => UserJob.removeWorkPlaceResume({
                                    resume_id: this.$route.query.id,
                                    job_id: item
                                }))
                            })
                            .then(() => {
                                this.pullRemovedSPs.forEach(item => UserEd.removeStudyPlaceResume({
                                    resume_id: this.$route.query.id,
                                    education_id: item
                                }))
                            })
                            .then(() => this.modalOpen('changeResSuccess'))
                            .catch(error => this.modalOpen('saveResFail', error))
                            .finally(() => this.isSaving = false)
                    }
                }
            }
        }
    }
}
</script>

<style lang="less">
@import '../../../assets/styles/pages/cabinet/user/resume';
.fade-enter-active, .fade-leave-active {
    transition: opacity .2s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}

.list-enter-active, .list-leave-active {
    transition: all 1s;
}

.list-enter, .list-leave-to {
    opacity: 0;
    transform: translateY(30px);
}
.profile-buttons .grid {
    display: flex;
    .box {
        width: 50%;
        &:nth-child(2) {
            display: flex;
            justify-content: flex-end;
        }
    }
}
</style>
