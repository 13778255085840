<template>
	<section class="section wow simple">
		<div class="content">
			<div
				v-if="allCertificates.length === 0 && contestCertificates === 0"
				class="certificate-card-title"
			>
				Сертификатов нет
			</div>
			<template v-if="allCertificates.length > 0">
				<div class="certificate-card-block">
					<div class="certificate-card-group">
						<div class="grid">
							<div
								v-for="item of allCertificates"
								:key="item.id"
								class="box"
							>
								<div class="certificate-card-item">
									<button
										class="certificate-card-pic"
										@click="showCertificate(item)"
									>
										<img
											:src="item.image_url"
											alt=""
										>
									</button>
									<button
										class="certificate-card-link"
										@click="showCertificate(item)"
									>
										Смотреть
									</button>
									<a
										v-if="!watchUser && item.cert_file"
										:href="item.cert_file"
										target="_blank"
										class="certificate-card-link"
										rel="noopener noreferrer"
										download
									>Скачать</a>
									<a
										v-else-if="!watchUser"
										:href="item.cert_url"
										target="_blank"
										class="certificate-card-link"
										rel="noopener noreferrer"
										download
									>Скачать</a>
									<div
										v-if="item.course_name"
										class="certificate-card-title"
									>
										{{ item.course_name }}
									</div>
									<div
										v-else
										class="certificate-card-title"
									>
										{{ item.program_name }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<!--					<div-->
					<!--						v-if="currentPage * maxPerPage < totalCertificates"-->
					<!--						class="center"-->
					<!--					>-->
					<!--						<button-->
					<!--							v-if="!isMore"-->
					<!--							class="button-hollow lg"-->
					<!--							@click="showMoreCertificates"-->
					<!--						>-->
					<!--							{{ showMore }}-->
					<!--						</button>-->
					<!--						<div-->
					<!--							v-else-->
					<!--							class="button-hollow lg button-preloader"-->
					<!--						>-->
					<!--							{{ showMore }}-->
					<!--							<div class="button-preloader-icons">-->
					<!--								<div class="button-preloader-icon" />-->
					<!--								<div class="button-preloader-icon" />-->
					<!--								<div class="button-preloader-icon" />-->
					<!--							</div>-->
					<!--						</div>-->
					<!--					</div>-->
				</div>
			</template>
			<template v-if="contestCertificates.length > 0">
				<div class="certificate-card-block">
					<div class="certificate-card-group">
						<div class="grid">
							<div
								v-for="item of contestCertificates"
								:key="item.id"
								class="box"
							>
								<div class="certificate-card-item">
									<button
										class="certificate-card-pic"
										@click="showCertificate(item)"
									>
										<img
											:src="item.image_url"
											alt=""
										>
									</button>
									<button
										class="certificate-card-link"
										@click="showCertificate(item)"
									>
										Смотреть
									</button>
									<a
										v-if="item.pdf_url"
										:href="item.pdf_url"
										target="_blank"
										class="certificate-card-link"
										rel="noopener noreferrer"
										download
									>Скачать</a>
									<div
										class="certificate-card-title"
									>
										{{ item.contest_name }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
		</div>
	</section>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Contests from '@/api/contests'

export default {
    name: 'Certificates',
    data: () => ({
        watchUser: false,
        isLoading: true,
        isMore: false,
        allCertificates: [],
        coursesCertificates: [],
        programCertificates: [],
        currentPage: 1,
        maxPerPage: 12,
        showReadMore: true,
        totalCertificates: undefined,
        userInfo_id: null,
        contestCertificates: []
    }),
    computed: {
        ...mapGetters('user', {
            userID: 'userID'
        }),
        paginatedCertificates() {
            return this.allCertificates.slice(0, this.currentPage * this.maxPerPage);
        }
    },
    asyncComputed: {
        showMore() {
            return `Показать еще ${this.totalCertificates && (this.totalCertificates - this.currentPage * this.maxPerPage >= this.maxPerPage) ? this.maxPerPage : this.totalCertificates - (this.maxPerPage * this.currentPage)}`
        }
    },
    created() {
        if (this.$route.params.id !== JSON.stringify(this.userID)) this.watchUser = true
        this.getPaginatedCertificates()
        if (localStorage.getItem('jwtToken')) this.ContestCertificates()
    },
    mounted() {
        this.$scrollTo(document.querySelector('header.header-role'), 900)
    },
    methods: {
        ...mapActions('user', {
            getCertificates: 'getCertificates',
            getProgramCertificates: 'getProgramCertificates',
            getUserInfo: 'detailWatchUser'
        }),
        showCertificate(cert) {
            this.modalOpen('cert-view', cert)
        },
        showMoreCertificates() {
            ++this.currentPage
            this.getPaginatedCertificates()
        },
        ContestCertificates () {
            Contests.getCertificates().then((response) => {
               this.contestCertificates = response.data
            })
        },
        getPaginatedCertificates() {
            this.isMore = true
            if (this.$route.params.id !== JSON.stringify(this.userID)) {
                this.getUserInfo(this.$route.params.id)
                    .then(user => {
                        this.userInfo_id = user.id
                    })
                    .then(() => {
                        this.getCertificates({
                            uid: this.userInfo_id,
                            page: this.currentPage
                        })
                            .then(response => {
                                this.certificates = response
                                this.allCertificates.push(...this.certificates)
                                // if (this.currentPage > 1) this.certificates.push(...response.results)
                                // this.totalCertificates = response.count
                            })
                            .then(() => {
                                this.getProgramCertificates({
                                    uid: this.userInfo_id,
                                    page: this.currentPage
                                })
                                    .then(response => {
                                        this.programCertificates = response
                                        this.allCertificates.push(...this.programCertificates)
                                        // if (this.currentPage > 1) this.programCertificates.push(...response)
                                        // this.totalCertificates = response.count
                                    })
                                    .then(() => {
                                        this.isMore = false
                                        this.isLoading = false
                                    })
                            })
                    })
            } else {
                this.getCertificates({
                    page: this.currentPage
                })
                    .then(response => {
                        this.certificates = response
                        this.allCertificates.push(...this.certificates)
                        // if (this.currentPage > 1) this.certificates.push(...response)
                        // this.totalCertificates = response.count
                    })
                    .then(() => {
                        this.getProgramCertificates({
                            page: this.currentPage
                        })
                            .then(response => {
                                this.programCertificates = response
                                this.allCertificates.push(...this.programCertificates)
                                // if (this.currentPage > 1) this.programCertificates.push(...response)
                                // this.totalCertificates = response.count
                            })
                            .then(() => {
                                this.isMore = false
                                this.isLoading = false
                            })
                    })
            }
        },
    }
}
</script>

<style lang="less">
@import "../../../assets/styles/pages/cabinet/user/certificates";
</style>
