<template>
	<section
		class="section wow fadeIn"
		data-wow-offset="50"
	>
		<div class="content">
			<h3 class="block-title">
				Конкурсы
			</h3>

			<div class="profile-tabs button-group">
				<BaseButton
					light-border
					:orange="status === 3"
					@clickButton="getContestsByStatus(3)"
				>
					Активные
				</BaseButton>
				<BaseButton
					light-border
					:orange="status === 2"
					@clickButton="getContestsByStatus(2)"
				>
					Завершенные
				</BaseButton>
			</div>
			<div class="contests">
				<div
					v-for="contest in contests"
					:key="contest.id"
					class="contest-card"
				>
					<router-link
						:to="{
							path: `/profile/${$route.params.id}?contest=true&id=${contest.id}`,
							query: {id: contest.id,name: contest.name}}"
						class="contest-card__link"
					>
						<img
							:src="contest.image"
							alt="Фото"
							class="contest-card__pic"
						>
					</router-link>
					<div class="contest-card__content">
						<h4 class="title-element">
							{{ contest.name }}
						</h4>
						<router-link
							:to="{
								path: `/profile/${$route.params.id}?contest=true&id=${contest.id}`,
								query: {id: contest.id,name: contest.name}}"
							class="button button--s button--blue"
						>
							Подробнее
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import BaseButton from '@/components/BaseComponents/BaseButton.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
    components: {
        BaseButton
    },
    data: () => ({
        status: 3,
    }),
    computed: {
        ...mapGetters('contest', {
            contests: 'getContests'
        })
    },
    created() {
        this.getContests(this.status)
    },
    methods: {
        ...mapActions('contest', {
            getContests: 'getAllContests'
        }),
        getContestsByStatus(status) {
            this.status = status
            this.getContests(status)
        }
    }
}
</script>

<style>
@import '../../../../assets/styles/pages/cabinet/index.css';
</style>

