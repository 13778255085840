<template>
	<section class="section wow simple">
		<div class="content">
			<div
				v-if="isLoading"
				class="preloader"
				style="margin-top: 100px"
			/>
			<div
				v-else-if="tests.length === 0"
				class="certificate-card-title"
			>
				Результатов нет
			</div>
			<template v-else>
				<div class="certificate-card-block">
					<div class="certificate-card-group">
						<div class="grid">
							<div
								v-for="item of tests"
								:key="item.id"
								class="box"
							>
								<div class="certificate-card-item">
									<button
										class="certificate-card-pic"
										@click="showTestRes(item)"
									>
										<img
											:src="item.certificate_image"
											alt=""
										>
									</button>
									<button
										class="certificate-card-link"
										@click="showTestRes(item)"
									>
										Смотреть
									</button>
									<a
										target="_blank"
										:href="item.certificate_pdf"
										class="certificate-card-link"
									>
										<div>
											<span>Скачать</span>
										</div>
									</a>
									<div
										class="certificate-card-title"
									>
										{{ item.survay.survay_name }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
		</div>
	</section>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'Certificates',
    data: () => ({
        watchUser: false,
        isLoading: false,
        userInfo_id: null,
        isDownloading: false
    }),
    computed: {
        ...mapGetters('user', {
            userID: 'userID'
        }),
        ...mapGetters('tst', {
            tests: 'getUserTests'
        }),
    },
    created() {
        if (this.$route.params.id !== JSON.stringify(this.userID)) this.watchUser = true
        else this.getTests()
    },
    mounted() {
        this.$scrollTo(document.querySelector('header.header-role'), 900)
    },
    methods: {
        ...mapActions('user', {
            getUserInfo: 'detailWatchUser'
        }),
        ...mapActions('tst', {
            getUserTests: 'getUserTests'
        }),
        showTestRes(test) {
            this.modalOpen('test-view', test)
        },
        getTests () {
            this.isLoading = true
            this.getUserTests().then(() => {
                this.isLoading = false
            })
        }
    }
}
</script>

<style lang="less">
@import "../../../assets/styles/pages/cabinet/user/certificates";
</style>
