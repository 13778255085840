<template>
	<section class="section wow">
		<div class="content">
			<div class="top-btns">
				<div class="show-list">
					<h4>Показывать:</h4>
					<div class="select-type-simple">
						<c-select
							:options="statusesOptions"
							:default-data="showStatus"
							@selected="selectStatus"
						/>
					</div>
				</div>
			</div>

			<div class="vacancies rekrut-respond">
				<div class="content">
					<div class="table-caption">
						<div
							class="column-date"
							data-label="Дата отклика"
							:class="{ 'incr': currentSort.incr === 'created', 'decr': currentSort.decr === '-created' }"
							@click="sortBy('created')"
						>
							Дата отклика
						</div>
						<div
							class="column-name"
							data-label="Вакансия"
							:class="{ 'incr': currentSort.incr === 'name', 'decr': currentSort.decr === '-name' }"
							@click="sortBy('name')"
						>
							Вакансия
						</div>
						<div
							class="column-org"
							data-label="Организация"
							:class="{ 'incr': currentSort.incr === 'company_name', 'decr': currentSort.decr === '-company_name' }"
							@click="sortBy('company_name')"
						>
							Организация
						</div>
						<div
							class="column-company"
							data-label="Компания"
							:class="{ 'incr': currentSort.incr === 'company_real_name', 'decr': currentSort.decr === '-company_real_name' }"
							@click="sortBy('company_real_name')"
						>
							Компания
						</div>
						<div
							class="column-person"
							data-label="Кандидат"
							:class="{ 'incr': currentSort.incr === 'full_name', 'decr': currentSort.decr === '-full_name' }"
							@click="sortBy('full_name')"
						>
							Кандидат
						</div>
						<div
							class="column-status"
							data-label="Статус"
						>
							Статус
						</div>
					</div>
					<div
						v-if="isSorting || getIsLoaded"
						class="preloader sm"
						style="margin-top: 50px;"
					/>
					<div
						v-else-if="filteredResponses.length"
						class="table-main"
					>
						<div
							v-for="response in filteredResponses"
							:key="response.id"
							class="table-row"
						>
							<div class="column-date">
								<label class="checkbox-filter blue-filter">
									<input
										v-model="selected"
										type="checkbox"
										:value="response.id"
										@change="selectResponse"
									>
									<span />
								</label>
								<h4>{{ response.created | dateFormat }}</h4>
								<label class="checkbox-filter favorite-checkbox">
									<input
										type="checkbox"
										checked
										@change="removeFromFavorite(response.id)"
									>
									<span />
								</label>
							</div>
							<router-link
								class="column-name column-hover"
								:to="{path: `/profile?response=true&action=edit&id=${response.id}`,query: {id: response.id,name: response.vacancy.name}}"
								style="cursor: pointer; display: block; font-size: 13px"
							>
								{{ response.vacancy.name }}
							</router-link>
							<div
								class="column-org"
								data-label="Организация:"
							>
								{{
									companyName(response.vacancy.company)
								}}
							</div>
							<div
								class="column-company"
								data-label="Компания:"
							>
								{{ companyRealName(response.vacancy.company) }}
							</div>
							<div
								class="column-person"
								data-label="Кандидат:"
							>
								{{ response.user | userFullName }}
							</div>
							<div
								class="column-status"
								data-label="Статус:"
							>
								<span :class="response.status | statusClass">{{ statusFormat(response.status) }}</span>
							</div>
						</div>
						<ul
							v-if="paginationNew.ttl > 1 && showStatus === 'Новый'"
							class="pagination"
						>
							<li
								v-if="paginationNew.init"
								@click="paginationNew.init--"
							>
								<span>&lt;</span>
							</li>
							<li
								v-for="(item, index) in pagesNew"
								:key="index"
								:class="{ active: paginationNew.crnt === item }"
								@click="selectPageNew(item, index)"
							>
								<span>{{ item }}</span>
							</li>
							<li v-if="paginationNew.ttl - paginationNew.init > 4">
								<span>...</span>
							</li>
							<li
								v-if="isShowTotal"
								:class="{ active: paginationNew.crnt === paginationNew.ttl }"
								@click="selectPageNew(paginationNew.ttl)"
							>
								<span>{{ paginationNew.ttl }}</span>
							</li>
							<li
								v-if="isShowNextNew"
								@click="paginationNew.init++"
							>
								<span>&gt;</span>
							</li>
						</ul>
						<ul
							v-if="paginationUnder.ttl > 1 && showStatus === 'На рассмотрении'"
							class="pagination"
						>
							<li
								v-if="paginationUnder.init"
								@click="paginationUnder.init--"
							>
								<span>&lt;</span>
							</li>
							<li
								v-for="(item, index) in pagesUnder"
								:key="index"
								:class="{ active: paginationUnder.crnt === item }"
								@click="selectPageUnder(item, index)"
							>
								<span>{{ item }}</span>
							</li>
							<li v-if="paginationUnder.ttl - paginationUnder.init > 4">
								<span>...</span>
							</li>
							<li
								v-if="isShowTotal"
								:class="{ active: paginationUnder.crnt === paginationUnder.ttl }"
								@click="selectPageUnder(paginationUnder.ttl)"
							>
								<span>{{ paginationUnder.ttl }}</span>
							</li>
							<li
								v-if="isShowNextUnder"
								@click="paginationUnder.init++"
							>
								<span>&gt;</span>
							</li>
						</ul>
						<ul
							v-if="paginationInvite.ttl > 1 && showStatus === 'Приглашение'"
							class="pagination"
						>
							<li
								v-if="paginationInvite.init"
								@click="paginationInvite.init--"
							>
								<span>&lt;</span>
							</li>
							<li
								v-for="(item, index) in pagesInvite"
								:key="index"
								:class="{ active: paginationInvite.crnt === item }"
								@click="selectPageInvite(item, index)"
							>
								<span>{{ item }}</span>
							</li>
							<li v-if="paginationInvite.ttl - paginationInvite.init > 4">
								<span>...</span>
							</li>
							<li
								v-if="isShowTotal"
								:class="{ active: paginationInvite.crnt === paginationInvite.ttl }"
								@click="selectPageInvite(paginationInvite.ttl)"
							>
								<span>{{ paginationInvite.ttl }}</span>
							</li>
							<li
								v-if="isShowNextInvite"
								@click="paginationInvite.init++"
							>
								<span>&gt;</span>
							</li>
						</ul>
						<ul
							v-if="paginationRefusal.ttl > 1 && showStatus === 'Отказ'"
							class="pagination"
						>
							<li
								v-if="paginationRefusal.init"
								@click="paginationRefusal.init--"
							>
								<span>&lt;</span>
							</li>
							<li
								v-for="(item, index) in pagesRefusal"
								:key="index"
								:class="{ active: paginationRefusal.crnt === item }"
								@click="selectPageRefusal(item, index)"
							>
								<span>{{ item }}</span>
							</li>
							<li v-if="paginationRefusal.ttl - paginationRefusal.init > 4">
								<span>...</span>
							</li>
							<li
								v-if="isShowTotal"
								:class="{ active: paginationRefusal.crnt === paginationRefusal.ttl }"
								@click="selectPageRefusal(paginationRefusal.ttl)"
							>
								<span>{{ paginationRefusal.ttl }}</span>
							</li>
							<li
								v-if="isShowNextRefusal"
								@click="paginationRefusal.init++"
							>
								<span>&gt;</span>
							</li>
						</ul>
					</div>
					<p v-if="filteredResponses.length === 0">
						{{ noResponses }}
					</p>
				</div>
			</div>
		</div>
	</section>
</template>

<script>

import Tb from '@/api/teachbase'
import {mapActions, mapGetters, mapMutations} from 'vuex';

export default {
    filters: {
        userFullName(user) {
            return `${user.first_name} ${user.middle_name} ${user.last_name}`
        },
        statusClass(status) {
            if (status === 'under_consideration') return 'status-gray'
            else if (status === 'invitation') return 'status-green'
            else if (status === 'refusal') return 'status-orange'
            return 'status'
        }
    },
    data: () => ({
        noResponses: '',
        isLoading: false,
        isSorting: false,
        showStatus: '',
        companies: [],
        selected: [],
        currentSort: localStorage.getItem('selectedSortingFavorites') ? JSON.parse(localStorage.getItem('selectedSortingFavorites')) : {
            incr: '',
            decr: ''
        },
        responses: [],
        paginationNew: {
            init: 0,
            crnt: 1,
            ttl: undefined
        },
        paginationUnder: {
            init: 0,
            crnt: 1,
            ttl: undefined
        },
        paginationInvite: {
            init: 0,
            crnt: 1,
            ttl: undefined
        },
        paginationRefusal: {
            init: 0,
            crnt: 1,
            ttl: undefined
        },
    }),
    computed: {
        ...mapGetters('cnst', {
            statuses: 'getRS'
        }),
        // getIsLoaded заменить на isLoaded: как убедимся что isLoaded не используется
        ...mapGetters('rec', {
            selectedResponses: 'getSelectedResponses',
            getIsLoaded: 'getIsLoaded',
        }),
        ...mapGetters('ntf', {
            submit: 'getSubmit'
        }),
        statusesOptions() {
            return this.statuses.map(x => x.name)
        },
        filteredResponses() {
            let status = this.statuses.find(x => x.name === this.showStatus).slug
            return this.responses && this.responses.filter(x => x.favourite && x.status === status)
        },
        pagesNew() {
            let counter = 1;
            let temp = [];
            let max = Math.ceil(this.amount / 30) < 3 ? Math.ceil(this.amount / 30) : 3
            while (counter <= max) {
                temp.push(this.paginationNew.init + counter);
                counter++;
            }
            return temp;
        },
        pagesUnder() {
            let counter = 1;
            let temp = [];
            let max = Math.ceil(this.amount / 30) < 3 ? Math.ceil(this.amount / 30) : 3
            while (counter <= max) {
                temp.push(this.paginationUnder.init + counter);
                counter++;
            }
            return temp;
        },
        pagesInvite() {
            let counter = 1;
            let temp = [];
            let max = Math.ceil(this.amount / 30) < 3 ? Math.ceil(this.amount / 30) : 3
            while (counter <= max) {
                temp.push(this.paginationInvite.init + counter);
                counter++;
            }
            return temp;
        },
        pagesRefusal() {
            let counter = 1;
            let temp = [];
            let max = Math.ceil(this.amount / 30) < 3 ? Math.ceil(this.amount / 30) : 3
            while (counter <= max) {
                temp.push(this.paginationRefusal.init + counter);
                counter++;
            }
            return temp;
        },
        isShowTotal() {
            if (this.amount) return Math.ceil(this.amount / 30) > 3
            return false
        },
        isShowNextNew() {
            return (this.paginationNew.init !== this.paginationNew.ttl - 4) && this.isShowTotal
        },
        isShowNextUnder() {
            return (this.paginationUnder.init !== this.paginationUnder.ttl - 4) && this.isShowTotal
        },
        isShowNextInvite() {
            return (this.paginationInvite.init !== this.paginationInvite.ttl - 4) && this.isShowTotal
        },
        isShowNextRefusal() {
            return (this.paginationRefusal.init !== this.paginationRefusal.ttl - 4) && this.isShowTotal
        }
    },
    watch: {
        selectedResponses() {
            this.selected = this.selectedResponses
        },
        submit: {
            handler() {
                if(this.submit) {
                    this.getResponses()
                    this.selected = []
                }
            },
            deep: true
        },
    },
    created() {
        this.isLoaded = true
        this.showStatus = this.statuses[0].name
        this.getResponses('-created')
    },
    methods: {
        ...mapActions('rec', {
            getAllResponses: 'getAllResponses',
        }),
        ...mapActions('rec', ['setSelectedResponses']),
        ...mapMutations('ntf', [
            'setSubmit'
        ]),
        ...mapMutations('rec', [
            'setIsLoaded',
        ]),
        statusFormat(status) {
            return this.statuses.find(x => x.slug === status).name
        },
        companyName(id) {
            if (this.companies.length) {
                let company = this.companies.find(x => x.id === +id)
                return company && company.company_name
            }
            return '-'
        },
        companyRealName(id) {
            if (this.companies.length) {
                let company = this.companies.find(x => x.id === +id)
                return company && company.company_real_name
            }
            return '-'
        },
        searchUniqCompanies(companies) {
            let companyId = companies.map(response => +response.vacancy.company)
            let uniq = Array.from(new Set(companyId))
            uniq.forEach(x => Tb.getCompany(x)
                .then(response => response.data)
                .then(result => this.companies.push(result)))
        },
        getResponses(ordering) {
            let selectedStatus = ''
            if (this.currentSort.decr) ordering = this.currentSort.decr
            else ordering = this.currentSort.incr
            this.isSorting = true
            this.isLoaded = true
            if (this.showStatus === 'Новый') {
                selectedStatus = 'new'
                this.noResponses = ''
                this.getAllResponses({
                    page: this.paginationNew.crnt,
                    ordering,
                    status: selectedStatus,
                    favourite: true
                })
                    .then(result => {
                        if ( result === 'Неправильная страница') {
                            this.paginationNew.crnt = 1
                            this.getAllResponses({
                                page: this.paginationNew.crnt,
                                ordering,
                                status: selectedStatus,
                                favourite: true
                            })
                                .then(result => {
                                    if (result.results.length) {
                                        this.searchUniqCompanies(result.results)
                                        this.responses = result.results
                                        this.amount = result.count
                                        this.paginationNew.ttl = Math.ceil(result.count / 30)
                                    } else {
                                        this.noResponses = 'У вас пока еще нет откликов'
                                        this.responses = []
                                    }
                                })
                                .then(() => {
                                    this.isLoaded = false
                                    this.isSorting = false
                                    this.setSubmit(false)
                                    this.setIsLoaded(false)
                                })
                        }
                        else if (result.results.length === 0) {
                            this.noResponses = 'У вас пока еще нет откликов'
                            this.responses = []
                        }
                        else {
                            this.searchUniqCompanies(result.results)
                            this.responses = result.results
                            this.amount = result.count
                            this.paginationNew.ttl = Math.ceil(result.count / 30)
                        }
                    })
                    .then(() => {
                        this.isLoaded = false
                        this.isSorting = false
                        this.setSubmit(false)
                        this.setIsLoaded(false)
                    })
            }
            if (this.showStatus === 'На рассмотрении') {
                selectedStatus = 'under_consideration'
                this.noResponses = ''
                this.getAllResponses({
                    page: this.paginationUnder.crnt,
                    ordering,
                    status: selectedStatus,
                    favourite: true
                })
                    .then(result => {
                        if ( result === 'Неправильная страница') {
                            this.paginationUnder.crnt = 1
                            this.getAllResponses({
                                page: this.paginationUnder.crnt,
                                ordering,
                                status: selectedStatus,
                                favourite: true
                            })
                                .then(result => {
                                    if (result.results.length) {
                                        this.searchUniqCompanies(result.results)
                                        this.responses = result.results
                                        this.amount = result.count
                                        this.paginationUnder.ttl = Math.ceil(result.count / 30)
                                    } else {
                                        this.noResponses = 'У вас пока еще нет откликов'
                                        this.responses = []
                                    }
                                })
                                .then(() => {
                                    this.isLoaded = false
                                    this.isSorting = false
                                    this.setSubmit(false)
                                    this.setIsLoaded(false)
                                })
                        }
                        else if (result.results.length === 0) {
                            this.noResponses = 'У вас пока еще нет откликов'
                            this.responses = []
                        }
                        else {
                            this.searchUniqCompanies(result.results)
                            this.responses = result.results
                            this.amount = result.count
                            this.paginationUnder.ttl = Math.ceil(result.count / 30)
                        }
                    })
                    .then(() => {
                        this.isLoaded = false
                        this.isSorting = false
                        this.setSubmit(false)
                        this.setIsLoaded(false)
                    })
            }
            if (this.showStatus === 'Приглашение') {
                selectedStatus = 'invitation'
                this.noResponses = ''
                this.getAllResponses({
                    page: this.paginationInvite.crnt,
                    ordering,
                    status: selectedStatus,
                    favourite: true
                })
                    .then(result => {
                        if ( result === 'Неправильная страница') {
                            this.paginationInvite.crnt = 1
                            this.getAllResponses({
                                page: this.paginationInvite.crnt,
                                ordering,
                                status: selectedStatus,
                                favourite: true
                            })
                                .then(result => {
                                    if (result.results.length) {
                                        this.searchUniqCompanies(result.results)
                                        this.responses = result.results
                                        this.amount = result.count
                                        this.paginationInvite.ttl = Math.ceil(result.count / 30)
                                    } else {
                                        this.noResponses = 'У вас пока еще нет откликов'
                                        this.responses = []
                                    }
                                })
                                .then(() => {
                                    this.isLoaded = false
                                    this.isSorting = false
                                    this.setSubmit(false)
                                    this.setIsLoaded(false)
                                })
                        }
                        else if (result.results.length === 0) {
                            this.noResponses = 'У вас пока еще нет откликов'
                            this.responses = []
                        }
                        else {
                            this.searchUniqCompanies(result.results)
                            this.responses = result.results
                            this.amount = result.count
                            this.paginationInvite.ttl = Math.ceil(result.count / 30)
                        }
                    })
                    .then(() => {
                        this.isLoaded = false
                        this.isSorting = false
                        this.setSubmit(false)
                        this.setIsLoaded(false)
                    })
            }
            if (this.showStatus === 'Отказ') {
                selectedStatus = 'refusal'
                this.noResponses = ''
                this.getAllResponses({
                    page: this.paginationRefusal.crnt,
                    ordering,
                    status: selectedStatus,
                    favourite: true
                })
                    .then(result => {
                        if ( result === 'Неправильная страница') {
                            this.paginationRefusal.crnt = 1
                            this.getAllResponses({
                                page: this.paginationRefusal.crnt,
                                ordering,
                                status: selectedStatus,
                                favourite: true
                            })
                                .then(result => {
                                    if (result.results.length) {
                                        this.searchUniqCompanies(result.results)
                                        this.responses = result.results
                                        this.amount = result.count
                                        this.paginationRefusal.ttl = Math.ceil(result.count / 30)
                                    } else {
                                        this.noResponses = 'У вас пока еще нет откликов'
                                        this.responses = []
                                    }
                                })
                                .then(() => {
                                    this.isLoaded = false
                                    this.isSorting = false
                                    this.setSubmit(false)
                                    this.setIsLoaded(false)
                                })
                        }
                        else if (result.results.length === 0) {
                            this.noResponses = 'У вас пока еще нет откликов'
                            this.responses = []
                        }
                        else {
                            this.searchUniqCompanies(result.results)
                            this.responses = result.results
                            this.amount = result.count
                            this.paginationRefusal.ttl = Math.ceil(result.count / 30)
                        }
                    })
                    .then(() => {
                        this.isLoaded = false
                        this.isSorting = false
                        this.setSubmit(false)
                        this.setIsLoaded(false)
                    })
            }
        },
        selectStatus(val) {
            this.showStatus = val
            this.selected = new Array()
            this.hideNotify()
            this.getResponses()
        },
        selectResponse() {
            let count = this.selected.length
            if (count) {
                this.showNotify('resp', {
                    count,
                    env: this.statuses.find(x => x.name === this.showStatus).slug,
                })
                this.setSelectedResponses(this.selected)
            } else {
                this.hideNotify()
            }
        },
        removeFromFavorite(id) {
            if (!this.isLoading) {
                this.isLoading = true
                this.$store.dispatch('rec/changeResponse', {
                    id,
                    data: {
                        favourite: false
                    }
                })
                    .then(() => this.getResponses())
                    .finally(setTimeout(() => this.isLoading = false, 1000))
            }
        },
        sortBy(key) {
            if (this.currentSort.incr === key) {
                this.currentSort.incr = ''
                this.currentSort.decr = '-' + key
                localStorage.setItem('selectedSortingFavorites', JSON.stringify(this.currentSort))
                this.getResponses(key)
            } else {
                this.currentSort.incr = key
                this.currentSort.decr = ''
                localStorage.setItem('selectedSortingFavorites', JSON.stringify(this.currentSort))
                this.getResponses(key)
            }
        },
        selectPageNew(item, id) {
            this.paginationNew.crnt = item;
            this.getResponses()
            if (this.paginationNew.ttl === item) {
                if (this.paginationNew.ttl >= 4) this.paginationNew.init = this.paginationNew.ttl - 4
                else this.paginationNew.init = 0
            } else if (id === 0 && item > 1) this.paginationNew.init--;
            else if (id === 30 && item < this.paginationNew.ttl - 1) this.paginationNew.init++;
        },
        selectPageUnder(item, id) {
            this.paginationUnder.crnt = item;
            this.getResponses()
            if (this.paginationUnder.ttl === item) {
                if (this.paginationUnder.ttl >= 4) this.paginationUnder.init = this.paginationUnder.ttl - 4
                else this.paginationUnder.init = 0
            } else if (id === 0 && item > 1) this.paginationUnder.init--;
            else if (id === 30 && item < this.paginationUnder.ttl - 1) this.paginationUnder.init++;
        },
        selectPageInvite(item, id) {
            this.paginationInvite.crnt = item;
            this.getResponses()
            if (this.paginationInvite.ttl === item) {
                if (this.paginationInvite.ttl >= 4) this.paginationInvite.init = this.paginationInvite.ttl - 4
                else this.paginationInvite.init = 0
            } else if (id === 0 && item > 1) this.paginationInvite.init--;
            else if (id === 30 && item < this.paginationInvite.ttl - 1) this.paginationInvite.init++;
        },
        selectPageRefusal(item, id) {
            this.paginationRefusal.crnt = item;
            this.getResponses()
            if (this.paginationRefusal.ttl === item) {
                if (this.paginationRefusal.ttl >= 4) this.paginationRefusal.init = this.paginationRefusal.ttl - 4
                else this.paginationRefusal.init = 0
            } else if (id === 0 && item > 1) this.paginationRefusal.init--;
            else if (id === 30 && item < this.paginationRefusal.ttl - 1) this.paginationRefusal.init++;
        }
    }
}
</script>

<style>
.show-list {
    display: block;
}
.show-list .select {
    margin-bottom: 10px;
}
</style>
