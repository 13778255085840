<template>
	<div>
		<section class="section wow">
			<div
				v-if="!learningCourses"
				class="preloader"
			/>
			<div
				v-else
				class="content"
			>
				<div class="profile-tabs button-group">
					<a
						:class="{'active' : activeLearningTab }"
						class="button-hollow"
						@click="getActiveCourses()"
					>
						На изучении
					</a>
					<a
						:class="{'active' : activeFinishedTab }"
						class="button-hollow"
						@click="getFinishedCourses()"
					>
						Пройденные
					</a>
				</div>
				<div
					v-show="activeLearningTab"
					class="courses-container"
				>
					<h3
						v-if="!paginatedLearningCourses.length && !watchUser"
						class="certificate-card-title"
					>
						Вы ещё не начали обучение
					</h3>
					<h3
						v-if="!paginatedLearningCourses.length && watchUser"
						class="certificate-card-title"
					>
						Курсов на изучении нет
					</h3>
					<template v-else>
						<div class="course-card-group">
							<div class="grid">
								<div
									v-for="course in paginatedLearningCourses"
									:key="course.id"
									class="box"
								>
									<div class="course-card-item">
										<a
											class="course-card-title"
											@click="goToCourse(course)"
										>{{ course.course_name }}</a>
										<a
											class="course-card-pic"
											:style="courseBgrImage(course.image_course)"
											@click="goToCourse(course)"
										>
											<div class="marker-group">
												<div
													v-for="section in course.sections"
													:key="section.id"
													class="marker light"
												>
													{{ section.name }}
												</div>
											</div>
										</a>
										<div class="course-card-info-group">
											<div class="course-card-info-item">
												<img
													:src="'/img/icons/icon-course-members.svg' | prodSrcImage"
													alt="Количество человек на курсе"
												>
												{{ course.students_enrolled }}
											</div>
											<div
												v-if="course.course_duration"
												class="course-card-info-item"
											>
												<img
													:src="'/img/icons/icon-course-duration.svg' | prodSrcImage"
													alt="Длительность курса"
												>
												{{ course.course_duration | durationFilter }}
											</div>
										</div>
										<a
											v-if="!watchUser"
											class="button orange"
											@click="goToCourse(course)"
										>Продолжить изучение</a>
									</div>
								</div>
							</div>
							<div
								v-if="learningCourses.length < totalLearningCourses"
								class="center"
							>
								<BaseButton
									class="button-hollow"
									:transparent="!isMoreLearning"
									:is-preloader="isMoreLearning"
									light-border
									@clickButton="showMoreLearningCourses"
								>
									{{ showMoreLearning }}
								</BaseButton>
							</div>
						</div>
					</template>
				</div>
				<div
					v-show="activeFinishedTab"
					class="courses-container"
				>
					<h3
						v-if="!paginatedFinishedCourses.length && !watchUser"
						class="certificate-card-title"
					>
						Пройденных курсов нет
					</h3>
					<h3
						v-if="!paginatedFinishedCourses.length && watchUser"
						class="certificate-card-title"
					>
						Пройденных курсов нет
					</h3>
					<template v-else>
						<div class="course-card-group">
							<div class="grid">
								<div
									v-for="course in paginatedFinishedCourses"
									:key="course.course"
									class="box"
								>
									<div class="course-card-item">
										<a
											class="course-card-title"
											@click="goToCourse(course)"
										>{{ course.course_name }}</a>
										<a
											class="course-card-pic"
											:style="courseBgrImage(course.image_course)"
											@click="goToCourse(course)"
										>
											<div class="marker-group">
												<div
													v-for="section in course.sections"
													:key="section.id"
													class="marker light"
												>
													{{ section.name }}
												</div>
											</div>
										</a>
										<div class="course-card-info-group">
											<div class="course-card-info-item">
												<img
													:src="'/img/icons/icon-course-members.svg' | prodSrcImage"
													alt="Количество человек на курсе"
												>
												{{ course.students_enrolled }}
											</div>
											<div
												v-if="course.course_duration"
												class="course-card-info-item"
											>
												<img
													:src="'/img/icons/icon-course-duration.svg' | prodSrcImage"
													alt="Длительность курса"
												>
												{{ course.course_duration | durationFilter }}
											</div>
										</div>
										<a
											v-if="!watchUser"
											class="button orange"
											@click="goToCourse(course)"
										>Продолжить изучение</a>
									</div>
								</div>
							</div>
							<div
								v-if="finishedCourses.length < totalFinishedCourses"
								class="center"
							>
								<BaseButton
									v-if="!isMoreFinished"
									class="button-hollow"
									lg
									:transparent="!isMoreFinished"
									:is-preloader="isMoreFinished"
									light-border
									@clickButton="showMoreFinishedCourses"
								>
									{{ showMoreFinished }}
								</BaseButton>
							</div>
						</div>
					</template>
				</div>
			</div>
		</section>
	</div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex';
import BaseButton from '@/components/BaseComponents/BaseButton'

export default {
    components: {
      BaseButton
    },
    data: () => ({
        watchUser: false,
        isSended: false,
        isLoading: false,
        learningCourses: [],
        finishedCourses: [],
        activeLearningTab: true,
        activeFinishedTab: false,
        currentPageLearning: 1,
        currentPageFinished: 1,
        maxPerPage: 12,
        showReadMore: true,
        totalLearningCourses: undefined,
        totalFinishedCourses: undefined,
        isMoreLearning: false,
        isMoreFinished: false,
    }),
    computed: {
        ...mapGetters('user', {
            userID: 'userID'
        }),
        paginatedLearningCourses() {
            if (this.learningCourses) return this.learningCourses.slice(0, this.currentPageLearning * this.maxPerPage);
            return []
        },
        paginatedFinishedCourses() {
            if (this.finishedCourses) return this.finishedCourses.slice(0, this.currentPageFinished * this.maxPerPage);
            return []
        }
    },
    asyncComputed: {
        showMoreLearning() {
            return `Показать еще ${this.totalLearningCourses && (this.totalLearningCourses - this.currentPageLearning * this.maxPerPage >= this.maxPerPage) ? this.maxPerPage : this.totalLearningCourses - (this.maxPerPage * this.currentPageLearning)}`
        },
        showMoreFinished() {
            return `Показать еще ${this.totalFinishedCourses && (this.totalFinishedCourses - this.currentPageFinished * this.maxPerPage >= this.maxPerPage) ? this.maxPerPage : this.totalFinishedCourses - (this.maxPerPage * this.currentPageFinished)}`
        }
    },
    created() {
        if (this.$route.params.id !== JSON.stringify(this.userID)) this.watchUser = true
        this.getSortedCourses()
    },
    methods: {
        ...mapActions('user', {
            getCoursesInProgress: 'getCoursesInProgress',
            getCompletedCourses: 'getCompletedCourses'
        }),
        getSortedCourses() {
            if (this.activeLearningTab) {
                this.isMoreLearning = true
                this.isLoading = true
                this.getCoursesInProgress({
                    id: this.$route.params.id !== JSON.stringify(this.userID) ? this.$route.params.id: this.userID,
                    page: this.currentPageLearning
                }).then(response => {
                    if (this.currentPageLearning === 1) this.learningCourses = response.results
                    if (this.currentPageLearning > 1) this.learningCourses.push(...response.results)
                    this.totalLearningCourses = response.count
                })
                    .then(() => {
													this.isMoreLearning = false;
													this.isLoading = false
                    })
            }
            if (this.activeFinishedTab) {
                this.isMoreFinished = true
                this.getCompletedCourses({
                    id: this.$route.params.id !== JSON.stringify(this.userID) ? this.$route.params.id: this.userID,
                    page: this.currentPageFinished
                }).then(response => {
                    if (this.currentPageFinished === 1) this.finishedCourses = response.results
                    if (this.currentPageFinished > 1) this.finishedCourses.push(...response.results)
                    this.totalFinishedCourses = response.count
                })
                    .then(() => {
                        this.isMoreFinished = false
                        this.isLoading = false
                    })
            }
        },
        showMoreLearningCourses() {
						++this.currentPageLearning
            this.getSortedCourses()
        },
        showMoreFinishedCourses() {
            ++this.currentPageFinished
            this.getSortedCourses()
        },
        courseBgrImage(images) {
            if (images) {
                let url = images.find(img => img.type === 'course_cover');

                if (url) return `background-image: url(${url.image});`
                else return 'background-image: url(/staticfiles/img/landscape-image.png);'
            } else return 'background-image: url(/staticfiles/img/landscape-image.png);'
        },
        getActiveCourses() {
            this.activeLearningTab = true
            this.activeFinishedTab = false
            this.currentPageLearning = 1
            this.getSortedCourses()
        },
        getFinishedCourses() {
            this.activeLearningTab = false
            this.activeFinishedTab = true
            this.currentPageFinished = 1
            this.getSortedCourses()
        },
        goToCourse(course) {
            window.location.href = `/courses/${course.course_slug}-${course.course}`
        }
    }
}
</script>

<style lang="less">
@import '../../../assets/styles/pages/cabinet/user/courses.less';
.certificate-card-title {
    font-weight: 400
}
</style>
