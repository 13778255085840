<template>
	<div class="page">
		<section class="section wow">
			<div class="content">
				<div class="profile-tabs button-group">
					<BaseButton
						light-border
						:orange="status === 'in_progress,waiting'"
						@clickButton="getProgramsByStatus('in_progress,waiting')"
					>
						На изучении
					</BaseButton>
					<BaseButton
						light-border
						:orange="status === 'completed'"
						@clickButton="getProgramsByStatus('completed')"
					>
						Пройденные
					</BaseButton>
				</div>
				<h3
					v-if="!programs.length && !isLoading"
					class="certificate-card-title"
				>
					{{
						status === "in_progress,waiting"
							? "Программ на изучении нет"
							: "Пройденных программ нет"
					}}
				</h3>
				<div
					v-if="isLoading"
					class="preloader"
					style="margin-top: 100px"
				/>
				<div
					v-else
					class="course-card-group"
				>
					<div class="grid">
						<router-link
							v-for="program in programs"
							:key="program.id"
							class="box program-card"
							:to="`/courses/program/${program.slug}/${program.id}`"
						>
							<div class="course-card-item">
								<a
									class="course-card-title"
								>{{
									program.name
								}}</a>
								<a
									class="course-card-pic"
									:style="courseBgrImage(program.image)"
								>
									<div class="marker-group">
										<div
											v-for="direction in program.direction"
											:key="direction"
											class="marker light"
										>
											{{ direction }}
										</div>
									</div>
								</a>
								<div class="course-card-info-group">
									<div class="course-card-info-item">
										<img
											:src="
												'/img/icons/icon-course-members.svg'
													| prodSrcImage
											"
											alt="Members"
										>
										{{ program.total_listeners }}
									</div>
									<div class="course-card-info-item">
										<img
											:src="
												'/img/icons/icon-course-duration.svg'
													| prodSrcImage
											"
											alt="Time"
										>
										{{ program.duration | durationFilter }}
									</div>
								</div>
								<router-link
									:to="{
										path: `/courses/program/${program.slug}/${program.id}`
									}"
									class="button"
								>
									Продолжить обучение
								</router-link>
							</div>
						</router-link>
					</div>
				</div>
				<BaseButton
					v-if="
						showMoreCount > 0 &&
							countAllPrograms >= 12 &&
							!isLoading
					"
					class="more-button"
					light-border
					:is-preloader="isLoadingMore"
					@clickButton="showMore"
				>
					Показать еще {{ showMoreCount > 12 ? 12 : showMoreCount }}
				</BaseButton>
			</div>
		</section>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import BaseButton from '@/components/BaseComponents/BaseButton.vue';
export default {
	components: {
		BaseButton
	},
	data() {
		return {
			isLoadingMore: false,
			isLoading: true,
			currentPage: 1,
			maxPerPage: 12,
			programs: [],
			countAllPrograms: 0,
			status: 'in_progress,waiting'
		};
	},
	computed: {
		showMoreCount() {
			return this.countAllPrograms - this.maxPerPage * this.currentPage;
		}
	},
	created() {
		this.getProgramsByStatus(this.status)
	},
	methods: {
		...mapActions('user', {
			getPrograms: 'getPrograms'
		}),
		showMore() {
			this.isLoadingMore = true;
			this.currentPage += 1;
			this.getPrograms({
				page: this.currentPage,
				status: this.status
			}).then(res => {
				this.programs = this.programs.concat(...res.results);
				this.isLoadingMore = false;
			});
		},
		getProgramsByStatus(status) {
			this.isLoading = true;
			this.currentPage = 1;
			this.status = status;
			this.getPrograms({ page: this.currentPage, status: status }).then(
				res => {
					this.isLoading = false;
					this.countAllPrograms = res.count;
					this.programs = res.results;
				}
			);
		},
		courseBgrImage(image) {
			if (image) {
				return `background-image: url(${image});`;
			} else
				return 'background-image: url(/staticfiles/img/landscape-image.png);';
		},
	}
};
</script>

<style lang="less" >
@import '../../../assets/styles/pages/cabinet/user/courses.less';
.button-group {
	display: flex;
	button {
		&:first-child {
			margin-right: 25px;
		}
	}
}
.more-button {
	position: relative;
	display: flex;
	justify-content: center;
	margin: 0 auto;
}
.program-card {
	cursor: pointer;
}
</style>
