<template>
	<section class="section wow">
		<div class="content">
			<div class="flex-top">
				<router-link
					:to="`/profile/${$route.params.id}?responses=true`"
					class="button-hollow icon-hover create-resume-back"
				>
					<span class="icon icon-back" />
					К списку откликов
				</router-link>
			</div>

			<div
				v-if="!response"
				class="preloader"
				style="margin-top: 200px"
			/>
			<div
				v-else
				class="create-resume-block"
			>
				<div class="create-resume-section">
					<div class="block-title sup-block-title">
						Отклик на вакансию
					</div>
					<div class="block-title sub-block-title">
						{{ response.vacancy.name }}
					</div>
				</div>

				<div class="create-resume-section">
					<div class="respnser">
						<div class="response-icon">
							<img
								v-if="response.status === 'invitation' || response.status === 'refusal'"
								:src="`/img/icons/icon-status-${response.status === 'refusal' ? 'fail' : 'success'}.png` | prodSrcImage"
								:alt="`Статус отклика - ${response.status}`"
							>
							{{ statusFormat(response.status) }}
						</div>
						<div
							v-if="response.answer"
							class="response-text"
						>
							{{ response.answer }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>

import User from '@/api/user'
import {mapGetters} from 'vuex';

export default {
    data: () => ({
        response: undefined
    }),
    computed: {
        ...mapGetters('cnst', {
            statuses: 'getRS'
        }),
    },
    created() {
        User.getResponse(this.$route.query.responseId).then(response => {
            this.response = response.data
            User.changeResponse({
                id: this.response.id,
                data: {
                    date_watch: new Date()
                }
            })
        })
    },
    methods: {
        statusFormat(status) {
            if (status && this.statuses.find(x => x.slug === status)) return this.statuses.find(x => x.slug === status).name
            if (status === 'archive') return 'В архиве'
            else return ''
        },
    }
}
</script>

<style lang="less">
.create-resume-block{
    //padding-top: 55px;
    padding-bottom: 20px;
    @media(max-width: 1100px){
        //padding-top: 45px;
    }
    @media(max-width: 905px){
    }
    .grid{
        margin: 0 -25px;
    }
    .box{
        width: 33.33%;
        padding: 0 25px;
        @media @desktop-end{
        }
        @media @laptop-end{
        }
        @media @tablet-end{
        }
        @media @mobile-end{
            width: 100%;
        }
    }
    .grey-grid{
        .box{
            @media @mobile-end{
                padding: 0;
            }
        }
    }
    .block-title{
        margin-bottom: 35px;
        @media @mobile-end{
            font-size: 25px;
        }
    }
    .button-control{
        display: block;
        height: auto;
        line-height: 1.4;
    }
    .button{
        @media @desktop-end{
        }
        @media @laptop-end{
        }
        @media @tablet-end{
        }
        @media @mobile-end{
            width: 100%;
            min-width: 100%;
            margin-left: 0 !important;
        }
    }
    .value-wrap {
        .field-label {
            font-size: 13px;
            color: @color-light;
            margin-bottom: 15px;
        }
        .field-value {
            color: @color-text;
            font-size: 16px;
        }
    }
    .profile-avatar-wrap{
        max-width: 200px;
    }
    .profile-avatar{
        width: 200px;
        height: 200px;
    }
}
.create-resume-section{
    padding-bottom: 25px;
    @media(max-width: 1100px){
        padding-bottom: 20px;
    }
    @media @mobile-end{
        padding-bottom: 16px;
    }
    .text{
        p{
            small{
                display: inline;
                margin-bottom: 0;
            }
        }
    }
    .select-type-simple{
        max-width: 250px;
        margin-bottom: 40px;
    }
    .button-group{
        margin-bottom: -20px;
        .button{
            margin-right: 20px;
            margin-bottom: 20px;
            @media @desktop-end{
            }
            @media @laptop-end{
            }
            @media @tablet-end{
            }
            @media @mobile-end{
                width: 100%;
            }
            &:last-child{
                margin-right: 0;
            }
        }
        .button-hollow{
            margin-right: 20px;
            margin-bottom: 20px;
            @media @desktop-end{
            }
            @media @laptop-end{
            }
            @media @tablet-end{
            }
            @media @mobile-end{
                width: 100%;
            }
            &:last-child{
                margin-right: 0;
            }
        }
    }
}

.response-icon{
    color: @color-light;
    font-size: 13px;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    border-bottom: 1px solid #d4d4d4;
    padding-bottom: 30px;
    position: relative;
    &::before{
        content: '';
        background: url('../../../assets/img/icons/icon-corner.jpg') center no-repeat;
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 34px;
        left: 50px;
    }
    img{
        margin-right: 7px;
        position: relative;
        top: -1px;
    }
}
.response-text{
    font-size: 16px;
    line-height: 25px;
    white-space: break-spaces;
    word-break: break-word;
}

</style>
