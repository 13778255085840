<template>
	<section class="section wow">
		<div class="content">
			<router-link
				:to="'/profile?companies=true'"
				class="button-hollow icon-hover create-resume-back"
			>
				<span class="icon icon-back" />
				К списку компаний
			</router-link>

			<div
				v-if="!isLoaded"
				class="preloader"
				style="margin-top: 200px"
			/>
			<div
				v-else
				class="create-resume-block"
			>
				<div class="create-resume-section">
					<div class="block-title">
						{{ company.company_name }}
					</div>

					<div
						v-if="isWatch"
						class="grid"
					>
						<div
							v-if="company.company_name"
							class="box"
						>
							<div class="field-wrap value-wrap">
								<label class="field-label">Отображаемое название компании:</label>
								<div class="field-value">
									{{ company.company_name }}
								</div>
							</div>
						</div>
						<div
							v-if="company.company_real_name"
							class="box"
						>
							<div class="field-wrap value-wrap">
								<label class="field-label">Наименование организации:</label>
								<div class="field-value">
									{{ company.company_real_name }}
								</div>
							</div>
						</div>
						<div
							v-if="company.company_real_name"
							class="box"
						>
							<div class="field-wrap value-wrap">
								<label class="field-label">Город:</label>
								<div class="field-value">
									{{ company.city }}
								</div>
							</div>
						</div>
						<!-- <div
							v-if="company.description"
							class="box full"
						>
							<div class="field-wrap value-wrap">
								<label class="field-label">О компании:</label>
								<div
									class="field-value"
									style="white-space: pre-wrap;"
									v-html="company.description"
								/>
							</div>
						</div> -->
						<div
							v-if="company.phone"
							class="box"
						>
							<div class="field-wrap value-wrap">
								<label class="field-label">Телефон:</label>
								<div class="field-value">
									{{ company.phone }}
								</div>
							</div>
						</div>
						<div
							v-if="company.email"
							class="box"
						>
							<div class="field-wrap value-wrap">
								<label class="field-label">Email:</label>
								<div class="field-value">
									{{ company.email }}
								</div>
							</div>
						</div>
					</div>

					<div
						v-else
						class="grid"
					>
						<div class="box">
							<div class="field-wrap">
								<label class="field-label">
									<input
										v-model="company.company_name"
										readonly
										type="text"
										class="field"
										placeholder="Отображаемое название компании"
									>
									<div class="label">Отображаемое название компании:</div>
								</label>
							</div>
						</div>
						<div class="box">
							<div class="field-wrap">
								<label class="field-label">
									<input
										v-model="company.company_real_name"
										readonly
										type="text"
										class="field"
										placeholder="Наименование организации"
									>
									<div class="label">Наименование организации:</div>
								</label>
							</div>
						</div>
						<div class="box">
							<div class="field-wrap">
								<label class="field-label">
									<input
										v-model="company.city"
										readonly
										type="text"
										class="field"
										placeholder="Город"
									>
									<div class="label">Город:</div>
								</label>
							</div>
						</div>
						<!-- <div class="box full">
							<div class="field-wrap">
								<label class="field-label">
									<textarea
										v-model="company.description"
										rows="3"
										class="field"
										placeholder="О себе"
									>О компании:</textarea>
									<div class="label">О компании:</div>
								</label>
							</div>
						</div> -->
						<div
							ref="phone"
							class="box"
						>
							<div class="field-wrap">
								<label class="field-label">
									<input
										ref="input_phone"
										v-model="company.phone"
										v-mask="'+7(###)###-##-##'"
										type="text"
										class="field"
										placeholder="Телефон"
									>
									<div class="label">Телефон:</div>
									<span
										v-if="$v.company.phone.$error"
										class="help-block form-error"
									>
										Введите корректный номер телефона
									</span>
								</label>
							</div>
						</div>
						<div
							ref="email"
							class="box"
						>
							<div class="field-wrap">
								<label class="field-label">
									<input
										v-model="company.email"
										type="email"
										class="field"
										placeholder="Email"
									>
									<div class="label">Email:</div>
									<span
										v-if="$v.company.email.$error"
										class="help-block form-error"
									>
										Введите корректный e-mail
									</span>
								</label>
							</div>
						</div>
					</div>
				</div>

				<div class="create-rekrut-company-section">
					<div class="sub-title">
						Сфера деятельности:
					</div>

					<template v-if="isWatch">
						<div
							v-for="activity in company.field_activity"
							:key="activity.id"
							class="panel sm view-mode"
						>
							<div class="text dark sm">
								<p>{{ activity.name }}</p>
							</div>
						</div>
						<div class="field-wrap" />
					</template>

					<template v-else>
						<div
							v-for="activity in company.field_activity"
							:key="activity.id"
							class="panel sm"
						>
							<div
								class="panel-remove"
								@click="removeActivities(activity.id)"
							/>
							<div class="text dark sm">
								<p>{{ activity.name }}</p>
							</div>
						</div>
						<div class="field-wrap">
							<button
								class="button-control"
								@click="modalOpen('changeFieldAct', company.field_activity)"
							>
								<img
									:src="'/img/icons/icon-edit.png' | prodSrcImage"
									alt="Изменить сферы деятельности"
								>
								Изменить сферы деятельности
							</button>
						</div>
					</template>
				</div>

				<div class="create-rekrut-company-section">
					<div class="company-pics-flex">
						<div
							v-if="isWatch && srcLogo || !isWatch"
							class="company-wrap"
						>
							<div class="sub-title">
								Логотип компании:
							</div>
							<div
								v-if="isWatch"
								class="company-pics"
							>
								<div class="show-logo-company">
									<div class="ava">
										<img
											v-if="srcLogo"
											:src="srcLogo"
											alt="Логотип компании"
										>
									</div>
									<!-- <div class="logo-title"><span>mtslogo.png</span/> -->
								</div>
								<div class="add-logo" />
							</div>
							<div
								v-else
								class="company-pics"
							>
								<div
									v-if="srcLogo"
									class="show-logo-company"
								>
									<div class="ava">
										<img
											:src="srcLogo"
											alt="Логотип компании"
										>
									</div>
									<div class="logo-title">
										<span v-if="company_logo.file && company_logo.file.name">{{
											company_logo.file.name
										}}</span>
										<span @click="removeImage('company_logo', companyLogo)" />
									</div>
								</div>
								<div
									v-else
									class="add-logo"
								>
									<label class="button-control">
										<input
											ref="company_logo"
											type="file"
											@change="changeImage('company_logo')"
										>
										<img
											:src="'/img/icons/icon-add.png' | prodSrcImage"
											alt="Загрузить логотип компании"
										>
										Загрузить логотип компании
									</label>
									<span class="annotation">JPEG, PNG, SVG не более 2 МБ</span>
								</div>
							</div>
						</div>

						<div
							v-if="isWatch && srcImage || !isWatch"
							class="company-wrap"
						>
							<div class="sub-title">
								Изображение компании:
							</div>
							<div
								v-if="isWatch"
								class="company-pics"
							>
								<div class="show-logo-company">
									<div class="ava">
										<img
											v-if="srcImage"
											:src="srcImage"
											alt="Изображении компании"
										>
									</div>
									<!-- <div class="logo-title"><span>mtslogo.png</span/> -->
								</div>
								<div class="add-logo" />
							</div>
							<div
								v-else
								class="company-pics"
							>
								<div
									v-if="srcImage"
									class="show-logo-company"
								>
									<div class="ava">
										<img
											:src="srcImage"
											alt="Изображении компании"
										>
									</div>
									<div class="logo-title">
										<span v-if="company_image.file && company_image.file.name">{{
											company_image.file.name
										}}</span>
										<span @click="removeImage('company_image', companyImage)" />
									</div>
								</div>
								<div
									v-else
									class="add-logo"
								>
									<label class="button-control">
										<input
											ref="company_image"
											type="file"
											@change="changeImage('company_image')"
										>
										<img
											:src="'/img/icons/icon-add.png' | prodSrcImage"
											alt="Загрузить изображении компании"
										>
										Загрузить изображении компании
									</label>
									<span class="annotation">JPEG, PNG, SVG не более 2 МБ</span>
								</div>
							</div>
						</div>
					</div>
					<!-- end company upload pictures -->
				</div>

				<button
					v-if="isWatch"
					class="button blue lg create-resume-save"
					@click="edit(company)"
				>
					Редактировать
				</button>
				<template v-else>
					<button
						v-if="!isSaving"
						class="button lg create-resume-save"
						@click="save"
					>
						Сохранить изменения
					</button>
					<div
						v-else
						class="button orange create-resume-save lg button-preloader"
					>
						Сохранить изменения
						<div class="button-preloader-icons">
							<div class="button-preloader-icon" />
							<div class="button-preloader-icon" />
							<div class="button-preloader-icon" />
						</div>
					</div>
				</template>
			</div>
		</div>
	</section>
</template>

<script>

import {email} from 'vuelidate/lib/validators'
import {Phone} from '@/assets/scripts/custom_validators'
import Re from '@/api/recruiter'
import Cmn from '@/api/common'
import {mapGetters, mapActions} from 'vuex';

export default {
    data: () => ({
        isLoaded: false,
        isSaving: false,
        company: undefined,
        field_activity_initial: new Array(),
        image_company_removed: new Array(),
        company_logo: {
            src: '',
            name: '',
            file: undefined
        },
        company_image: {
            src: '',
            name: '',
            file: undefined
        }
    }),
    computed: {
        ...mapGetters('modal', {
            activities: 'getActivities'
        }),
        token() {
            return this.$store.state.user && this.$store.state.user.token
        },
        isWatch() {
            return this.$route.query.action === 'watch'
        },
        companyLogo() {
            return this.company && this.company.image_company.length && this.company.image_company.find(x => x.type === 'company_logo')
        },
        companyImage() {
            return this.company && this.company.image_company.length && this.company.image_company.find(x => x.type === 'company_image')
        },
        srcLogo() {
            if (this.company_logo.src) return this.company_logo.src
            else if (this.companyLogo && !this.image_company_removed.includes(this.companyLogo.id)) return this.companyLogo.image
            return ''
        },
        srcImage() {
            if (this.company_image.src) return this.company_image.src
            else if (this.companyImage && !this.image_company_removed.includes(this.companyImage.id)) return this.companyImage.image
            return ''
        }
    },
    validations() {
        if (this.company) {
            return {
                company: {
                    email: {email},
                    phone: {Phone}
                }
            }
        }
    },
    watch: {
        activities() {
            this.company.field_activity = this.activities
        }
    },
    created() {
        Re.getCompany(+this.$route.query.id)
            .then(response => response.data)
            .then(result => {
                this.company = result
                this.field_activity_initial = JSON.parse(JSON.stringify(result.field_activity))
            })
            .finally(() => this.isLoaded = true)
    },
    methods: {
        ...mapActions('modal', ['setActivities']),
        removeImage(slug, image) {
            if (image) {
                this.image_company_removed.push(image.id)
            }
            this[slug].src = ''
            this[slug].file = undefined
        },
        changeImage(slug) {
            let reader = new FileReader()
            let file = this.$refs[slug].files[0]

            if (file) {
                if (file.size > Math.pow(2, 21)) {
                    this.modalOpen('stdFail', 'Превышен допустимый размер изображения')
                    return
                } else if (!/(jpeg)|(png)|(svg)/gi.test(file.type)) {
                    this.modalOpen('stdFail', 'Недопустимый формат изображения')
                    return
                } else {
                    reader.onload = event => {
                        this[slug].src = event.target.result
                    }
                    reader.readAsDataURL(file)
                    this[slug].file = file
                    this.image_company_removed = this.image_company_removed.filter(x => {
                        if (slug === 'company_logo') return x !== this.companyLogo.id
                        else if (slug === 'company_image') return x !== this.companyImage.id
                    })
                }
            }
        },
        edit(company) {
            this.$router.push({
                path: `/profile?company=true&action=edit&id=${this.$route.query.id}`,
                query: {
                    id: company.id,
                    name: company.company_name
                }
            })
        },
        save() {
            this.$v.company.$touch()
            if (this.$v.company.$invalid) {
                if (this.$v.company.phone.$error) this.$scrollTo(this.$refs.phone)
                else if (this.$v.company.email.$error) this.$scrollTo(this.$refs.email)
            } else if (!this.isSaving) {
                let formDataLogo = new FormData(),
                    formDataImage = new FormData();

                if (this.company_logo.file) {
                    formDataLogo.append('company', this.company.id)
                    formDataLogo.append('image', this.company_logo.file)
                    formDataLogo.append('type', 'company_logo')
                }

                if (this.company_image.file) {
                    formDataImage.append('company', this.company.id)
                    formDataImage.append('image', this.company_image.file)
                    formDataImage.append('type', 'company_image')
                }

                this.isSaving = true

                Promise.all([
                    this.company_logo.file && this.companyLogo && Cmn.updateImage({
                        form: formDataLogo,
                        id: this.companyLogo.id
                    }),
                    this.company_logo.file && !this.companyLogo && Cmn.createImage(formDataLogo),
                    !this.company_logo.src && this.companyLogo && this.image_company_removed.includes(this.companyLogo.id) && Cmn.removeImage(this.companyLogo.id),

                    this.company_image.file && this.companyImage && Cmn.updateImage({
                        form: formDataImage,
                        id: this.companyImage.id
                    }),
                    this.company_image.file && !this.companyImage && Cmn.createImage(formDataImage),
                    !this.company_image.src && this.companyImage && this.image_company_removed.includes(this.companyImage.id) && Cmn.removeImage(this.companyImage.id)
                ]).then(() => {
                    Re.editCompany({
                        id: +this.$route.query.id,
                        data: {
                            description: this.company.description,
                            email: this.company.email,
                            phone: this.company.phone,
                            field_activity: this.field_activity_initial
                                .filter(x => !this.company.field_activity.some(y => x.id === y.id))
                                .map(x => ({name: x.name, remove: true}))
                                .concat(this.company.field_activity)
                        }
                    })
                        .then(response => response.data)
                        .then(result => {
                            let phone = result.phone
                            this.company = result
                            this.field_activity_initial = JSON.parse(JSON.stringify(result.field_activity))
                            this.$v.$reset()
                            this.company.phone = phone && `${phone.substr(0, 2)}(${phone.substr(2, 3)})${phone.substr(5, 3)}-${phone.substr(8, 2)}-${phone.substr(10, 2)}`
                        })
                        .then(() => {
                            this.$router.push(`/profile?company=true&action=watch&id=${this.$route.query.id}`)
                            this.modalOpen('changeCompSuccess')
                        })
                        .catch(error => this.modalOpen('stdFail', error.response.data.error_text))
                        .finally(() => this.isSaving = false)
                })
            }
        },
        removeActivities(id) {
            const activities = [...this.activities];
            activities.splice(activities.findIndex(el => el.id === id), 1)
            this.setActivities(activities);
        }
    }
}
</script>

<style lang="less">
@import "../../../assets/styles/pages/cabinet/recruiter/company";
.create-resume-section {
    padding-top: 55px;
}
</style>
