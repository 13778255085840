<template>
	<section
		v-if="isRecruiter"
		class="section wow"
	>
		<div class="content">
			<div class="top-btns">
				<div class="show-list">
					<h4>Показывать:</h4>
					<div class="select-type-simple">
						<c-select
							:options="statusesOptions"
							:default-data="showStatus"
							@selected="selectStatus"
						/>
					</div>
				</div>
			</div>
			<div
				v-if="isLoaded || getIsLoaded"
				class="preloader sm"
				style="margin: 50px auto;"
			/>
			<div
				v-else
				class="rekrut-respond"
			>
				<div class="table-caption">
					<div
						class="column-date"
						data-label="Дата отклика"
						:class="{ 'incr': currentSort.incr === 'created', 'decr': currentSort.decr === '-created' }"
						@click="sortBy('created')"
					>
						Дата отклика
					</div>
					<div
						class="column-name"
						data-label="Вакансия"
						:class="{ 'incr': currentSort.incr === 'name', 'decr': currentSort.decr === '-name' }"
						@click="sortBy('name')"
					>
						Вакансия
					</div>
					<div
						class="column-org"
						data-label="Организация"
						:class="{ 'incr': currentSort.incr === 'company_name', 'decr': currentSort.decr === '-company_name' }"
						@click="sortBy('company_name')"
					>
						Организация
					</div>
					<div
						class="column-company"
						data-label="Компания"
						:class="{ 'incr': currentSort.incr === 'company_real_name', 'decr': currentSort.decr === '-company_real_name' }"
						@click="sortBy('company_real_name')"
					>
						Компания
					</div>
					<div
						class="column-person"
						data-label="Кандидат"
						:class="{ 'incr': currentSort.incr === 'full_name', 'decr': currentSort.decr === '-full_name' }"
						@click="sortBy('full_name')"
					>
						Кандидат
					</div>
					<div
						class="column-status"
						data-label="Статус"
					>
						Статус
					</div>
				</div>
				<div
					v-if="isSorting"
					class="preloader sm"
					style="margin: 50px auto;"
				/>
				<div
					v-else-if="filteredResponses.length"
					class="table-main"
				>
					<div
						v-for="response in filteredResponses"
						:key="response.id"
						class="table-row"
					>
						<div class="column-date">
							<label class="checkbox-filter blue-filter">
								<input
									v-model="selected"
									type="checkbox"
									:value="response.id"
									@change="selectResponse"
								>
								<span />
							</label>
							<h4>{{ response.created | dateFormat }}</h4>
							<label
								class="checkbox-filter favorite-checkbox"
								@click="changeFavorite(response)"
							>
								<input
									type="checkbox"
									:checked="response.favourite"
								>
								<span />
							</label>
						</div>
						<div class="column-name column-hover">
							<router-link
								:to="{path: `/profile?response=true&action=edit&id=${response.id}`,query: {id: response.id,name: response.vacancy.name}}"
								style="cursor: pointer; display: block"
							>
								{{ response.vacancy.name }}
							</router-link>
						</div>
						<div
							class="column-org"
							data-label="Организация:"
						>
							{{
								companyName(response.vacancy.company)
							}}
						</div>
						<div
							class="column-company"
							data-label="Компания:"
						>
							{{
								companyRealName(response.vacancy.company)
							}}
						</div>
						<div
							class="column-person"
							data-label="Кандидат:"
						>
							{{ userFullName(response.user) }}
						</div>
						<div
							class="column-status"
							data-label="Статус:"
						>
							<span :class="response.status | statusClass">{{ statusFormat(response.status) }}</span>
						</div>
					</div>
					<ul
						v-if="paginationNew.ttl > 1 && showStatus === 'Новый'"
						class="pagination"
					>
						<li
							v-if="paginationNew.init"
							@click="paginationNew.init--"
						>
							<span>&lt;</span>
						</li>
						<li
							v-for="(item, index) in pagesNew"
							:key="index"
							:class="{ active: paginationNew.crnt === item }"
							@click="selectPageNew(item, index)"
						>
							<span>{{ item }}</span>
						</li>
						<li v-if="paginationNew.ttl - paginationNew.init > 4">
							<span>...</span>
						</li>
						<li
							v-if="isShowTotal"
							:class="{ active: paginationNew.crnt === paginationNew.ttl }"
							@click="selectPageNew(paginationNew.ttl)"
						>
							<span>{{ paginationNew.ttl }}</span>
						</li>
						<li
							v-if="isShowNextNew"
							@click="paginationNew.init++"
						>
							<span>&gt;</span>
						</li>
					</ul>
					<ul
						v-if="paginationUnder.ttl > 1 && showStatus === 'На рассмотрении'"
						class="pagination"
					>
						<li
							v-if="paginationUnder.init"
							@click="paginationUnder.init--"
						>
							<span>&lt;</span>
						</li>
						<li
							v-for="(item, index) in pagesUnder"
							:key="index"
							:class="{ active: paginationUnder.crnt === item }"
							@click="selectPageUnder(item, index)"
						>
							<span>{{ item }}</span>
						</li>
						<li v-if="paginationUnder.ttl - paginationUnder.init > 4">
							<span>...</span>
						</li>
						<li
							v-if="isShowTotal"
							:class="{ active: paginationUnder.crnt === paginationUnder.ttl }"
							@click="selectPageUnder(paginationUnder.ttl)"
						>
							<span>{{ paginationUnder.ttl }}</span>
						</li>
						<li
							v-if="isShowNextUnder"
							@click="paginationUnder.init++"
						>
							<span>&gt;</span>
						</li>
					</ul>
					<ul
						v-if="paginationInvite.ttl > 1 && showStatus === 'Приглашение'"
						class="pagination"
					>
						<li
							v-if="paginationInvite.init"
							@click="paginationInvite.init--"
						>
							<span>&lt;</span>
						</li>
						<li
							v-for="(item, index) in pagesInvite"
							:key="index"
							:class="{ active: paginationInvite.crnt === item }"
							@click="selectPageInvite(item, index)"
						>
							<span>{{ item }}</span>
						</li>
						<li v-if="paginationInvite.ttl - paginationInvite.init > 4">
							<span>...</span>
						</li>
						<li
							v-if="isShowTotal"
							:class="{ active: paginationInvite.crnt === paginationInvite.ttl }"
							@click="selectPageInvite(paginationInvite.ttl)"
						>
							<span>{{ paginationInvite.ttl }}</span>
						</li>
						<li
							v-if="isShowNextInvite"
							@click="paginationInvite.init++"
						>
							<span>&gt;</span>
						</li>
					</ul>
					<ul
						v-if="paginationRefusal.ttl > 1 && showStatus === 'Отказ'"
						class="pagination"
					>
						<li
							v-if="paginationRefusal.init"
							@click="paginationRefusal.init--"
						>
							<span>&lt;</span>
						</li>
						<li
							v-for="(item, index) in pagesRefusal"
							:key="index"
							:class="{ active: paginationRefusal.crnt === item }"
							@click="selectPageRefusal(item, index)"
						>
							<span>{{ item }}</span>
						</li>
						<li v-if="paginationRefusal.ttl - paginationRefusal.init > 4">
							<span>...</span>
						</li>
						<li
							v-if="isShowTotal"
							:class="{ active: paginationRefusal.crnt === paginationRefusal.ttl }"
							@click="selectPageRefusal(paginationRefusal.ttl)"
						>
							<span>{{ paginationRefusal.ttl }}</span>
						</li>
						<li
							v-if="isShowNextRefusal"
							@click="paginationRefusal.init++"
						>
							<span>&gt;</span>
						</li>
					</ul>
				</div>
				<p v-if="filteredResponses.length === 0">
					{{ noResponses }}
				</p>
			</div>
		</div>
	</section>
	<section
		v-else
		class="section"
	>
		<div class="content">
			<div class="user-respond">
				<div
					v-if="isLoaded || getIsLoaded"
					class="preloader sm"
					style="margin: 50px auto;"
				/>
				<template v-else-if="user && responses.length">
					<div class="table-caption">
						<div
							class="column-date"
							data-label="Дата"
							:class="{ 'incr': currentSort.incr === 'date_create', 'decr': currentSort.decr === '-date_create' }"
							@click="sortBy('date_create')"
						>
							Дата
						</div>
						<div
							class="column-name"
							data-label="Вакансия"
							:class="{ 'incr': currentSort.incr === 'vacancy', 'decr': currentSort.decr === '-vacancy' }"
							@click="sortBy('vacancy')"
						>
							Вакансия
						</div>
						<div
							class="column-status column-status--sort"
							data-label="Статус"
							:class="{ 'incr': currentSort.incr === 'status', 'decr': currentSort.decr === '-status' }"
							@click="sortBy('status')"
						>
							Статус
						</div>
					</div>
					<div
						v-if="isSorting"
						class="preloader sm"
						style="margin: 50px auto;"
					/>
					<div
						v-else
						class="table-main"
					>
						<div
							v-for="response in responses"
							:key="response.id"
							class="table-row"
							:class="responseClass(response)"
							@click="goToPage(response)"
						>
							<div class="column-date">
								{{ response.created | dateFormat }} г.
							</div>
							<div class="column-name column-hover">
								{{ response.vacancy.name }}
							</div>
							<div class="column-status">
								{{ response.vacancy.status === 'archive' ? 'В архиве' : statusFormat(response.status) }}
								<img
									v-if="response.vacancy.status !== 'archive' && (response.status === 'invitation' || response.status === 'refusal')"
									:src="`/img/icons/icon-status-${response.status === 'refusal' ? 'fail' : 'success'}.png` | prodSrcImage"
									:alt="`Статус отклика - ${response.status}`"
									class="responses-table-status-icon"
								>
							</div>
						</div>
					</div>
				</template>
				<p v-else>
					У вас пока ещё нет откликов
				</p>
			</div>
		</div>
	</section>
</template>


<script>

import {mapActions, mapGetters, mapMutations} from 'vuex'
import Tb from '@/api/teachbase'

export default {
    filters: {
        statusClass(status) {
            if (status === 'under_consideration') return 'status-gray'
            else if (status === 'invitation') return 'status-green'
            else if (status === 'refusal') return 'status-orange'
            return 'status'
        }
    },
    data: () => ({
        noResponses: '',
        isLoaded: false,
        isSorting: false,
        showStatus: '',
        selected: [],
        recruiter: {
            companies: [],
            statuses: undefined
        },
        currentSort: localStorage.getItem('selectedSortingResponses') ? JSON.parse(localStorage.getItem('selectedSortingResponses')) : {
            incr: '',
            decr: '-created'
        },
        allResponses: [],
        userResponses: [],
        amount: 0,
        paginationNew: {
            init: 0,
            crnt: 1,
            ttl: undefined
        },
        paginationUnder: {
            init: 0,
            crnt: 1,
            ttl: undefined
        },
        paginationInvite: {
            init: 0,
            crnt: 1,
            ttl: undefined
        },
        paginationRefusal: {
            init: 0,
            crnt: 1,
            ttl: undefined
        },
        selectedStatus: localStorage.getItem('selectedStatusResponses') ? localStorage.getItem('selectedStatusResponses') : 'new'
    }),
    computed: {
        ...mapGetters('user', {
            user: 'userInfo',
            isRecruiter: 'isRecruiter'
        }),
        ...mapGetters('cnst', {
            statuses: 'getRS'
        }),
        ...mapGetters('rec', {
            selectedResponses: 'getSelectedResponses',
            getIsLoaded: 'getIsLoaded',
        }),
        ...mapGetters('ntf', {
            submit: 'getSubmit'
        }),
        token() {
            return this.$store.state.user && this.$store.state.user.token
        },
        responses() {
            if (this.isRecruiter) return this.allResponses
            else if (this.$store.state.user && this.$store.state.user.responses) return this.userResponses
            else return []
        },
        filteredResponses() {
            let status = this.statuses.find(x => x.name === this.showStatus).slug
            return this.allResponses && this.allResponses.filter(x => x.status === status)
        },
        pagesNew() {
            let counter = 1;
            let temp = [];
            let max = Math.ceil(this.amount / 30) < 3 ? Math.ceil(this.amount / 30) : 3
            while (counter <= max) {
                temp.push(this.paginationNew.init + counter);
                counter++;
            }
            return temp;
        },
        pagesUnder() {
            let counter = 1;
            let temp = [];
            let max = Math.ceil(this.amount / 30) < 3 ? Math.ceil(this.amount / 30) : 3
            while (counter <= max) {
                temp.push(this.paginationUnder.init + counter);
                counter++;
            }
            return temp;
        },
        pagesInvite() {
            let counter = 1;
            let temp = [];
            let max = Math.ceil(this.amount / 30) < 3 ? Math.ceil(this.amount / 30) : 3
            while (counter <= max) {
                temp.push(this.paginationInvite.init + counter);
                counter++;
            }
            return temp;
        },
        pagesRefusal() {
            let counter = 1;
            let temp = [];
            let max = Math.ceil(this.amount / 30) < 3 ? Math.ceil(this.amount / 30) : 3
            while (counter <= max) {
                temp.push(this.paginationRefusal.init + counter);
                counter++;
            }
            return temp;
        },
        isShowTotal() {
            if (this.amount) return Math.ceil(this.amount / 30) > 3
            return false
        },
        isShowNextNew() {
            return (this.paginationNew.init !== this.paginationNew.ttl - 4) && this.isShowTotal
        },
        isShowNextUnder() {
            return (this.paginationUnder.init !== this.paginationUnder.ttl - 4) && this.isShowTotal
        },
        isShowNextInvite() {
            return (this.paginationInvite.init !== this.paginationInvite.ttl - 4) && this.isShowTotal
        },
        isShowNextRefusal() {
            return (this.paginationRefusal.init !== this.paginationRefusal.ttl - 4) && this.isShowTotal
        },
        statusesOptions() {
            return this.statuses.map(x => x.name)
        },
    },
    watch: {
        selectedResponses() {
            this.selected = this.selectedResponses
        },
        submit: {
            handler() {
                if(this.submit) {
                    this.getResponses()
                    this.selected = []
                }
            },
            deep: true
        },
    },
    created() {
        this.isLoaded = true
        this.recruiter.statuses = this.statuses.map(x => x.name)
        this.showStatus = localStorage.getItem('showStatusResponses') ? localStorage.getItem('showStatusResponses') : 'Новый'
        this.$store.dispatch('user/detail').then(() => {
            if (this.isRecruiter) {
                this.getResponses('-created')
            } else {
                this.getUserResponses('-date_create')
            }
        }).finally(() => this.isLoaded = false)
    },
    methods: {
        ...mapActions('rec', {
            getAllResponses: 'getAllResponses',
        }),
        ...mapActions('rec', ['setSelectedResponses']),
        ...mapActions('user', {
            getAllUserResponses: 'getAllResponses',
        }),
        ...mapMutations('ntf', [
            'setSubmit'
        ]),
        ...mapMutations('rec', [
            'setIsLoaded',
        ]),
        statusFormat(status) {
            if (status && this.statuses.find(x => x.slug === status)) return this.statuses.find(x => x.slug === status).name
            if (status === 'archive') return 'В архиве'
            else return ''
        },
        userFullName(user) {
            if (user.middle_name === null) return `${user.first_name} ${user.last_name}`
            if (user.last_name === null) return `${user.first_name} ${user.middle_name}`
            return `${user.first_name} ${user.middle_name} ${user.last_name}`
        },
        companyName(id) {
            if (this.recruiter.companies.length) {
                let company = this.recruiter.companies.find(x => x.id === +id)
                return company && company.company_name
            }
        },
        companyRealName(id) {
            if (this.recruiter.companies.length) {
                let company = this.recruiter.companies.find(x => x.id === +id)
                return company && company.company_real_name
            }
        },
        getUserResponses(order) {
            if (this.currentSort.decr) order = this.currentSort.decr
            else order = this.currentSort.incr
            if (order) this.isSorting = true
            else this.isLoaded = true
            this.getAllUserResponses(order)
                .then(result => {
                    this.userResponses = result
                })
                .then(() => {
                    this.isLoaded = false
                    this.isSorting = false
                })
        },
        selectStatus(val) {
            this.showStatus = val
            if (this.showStatus === 'Новый') this.selectedStatus = 'new'
            if (this.showStatus === 'На рассмотрении') this.selectedStatus = 'under_consideration'
            if (this.showStatus === 'Приглашение') this.selectedStatus = 'invitation'
            if (this.showStatus === 'Отказ') this.selectedStatus = 'refusal'
            localStorage.setItem('selectedStatusResponses', this.selectedStatus)
            localStorage.setItem('showStatusResponses', this.showStatus)
            this.selected = new Array()
            this.hideNotify()
            this.getResponses()
        },
        getResponses(ordering) {
            this.selectedStatus = localStorage.getItem('selectedStatusResponses') ? localStorage.getItem('selectedStatusResponses') : 'new'
            if (this.currentSort.decr) ordering = this.currentSort.decr
            else ordering = this.currentSort.incr
            if (ordering) this.isSorting = true
            else this.isLoaded = true
            if (this.showStatus === 'Новый') {
                this.noResponses = ''
                this.getAllResponses({
                    page: this.paginationNew.crnt,
                    ordering,
                    status: this.selectedStatus
                })
                    .then(result => {
                        if ( result === 'Неправильная страница') {
                            this.paginationNew.crnt = 1
                            this.getAllResponses({
                                page: this.paginationNew.crnt,
                                ordering,
                                status: this.selectedStatus
                            })
                                .then(result => {
                                    if (result.results.length) {
                                        this.searchUniqCompanies(result.results)
                                        this.allResponses = result.results
                                        this.amount = result.count
                                        this.paginationNew.ttl = Math.ceil(result.count / 30)
                                    } else {
                                        this.noResponses = 'У вас пока еще нет откликов'
                                        this.allResponses = []
                                    }
                                })
                                .then(() => {
                                    this.isLoaded = false
                                    this.isSorting = false
                                    this.setSubmit(false)
                                    this.setIsLoaded(false)
                                })
                        } else if (result.results.length === 0) {
                            this.noResponses = 'У вас пока еще нет откликов'
                            this.allResponses = []
                        }
                        else {
                            this.searchUniqCompanies(result.results)
                            this.allResponses = result.results
                            this.amount = result.count
                            this.paginationNew.ttl = Math.ceil(result.count / 30)
                        }
                    })
                    .then(() => {
                        this.isLoaded = false
                        this.isSorting = false
                        this.setSubmit(false)
                        this.setIsLoaded(false)
                    })
            }
            if (this.showStatus === 'На рассмотрении') {
                this.noResponses = ''
                this.getAllResponses({
                    page: this.paginationUnder.crnt,
                    ordering,
                    status: this.selectedStatus
                })
                    .then(result => {
                        if ( result === 'Неправильная страница') {
                            this.paginationUnder.crnt = 1
                            this.getAllResponses({
                                page: this.paginationUnder.crnt,
                                ordering,
                                status: this.selectedStatus
                            })
                                .then(result => {
                                    if (result.results.length) {
                                        this.searchUniqCompanies(result.results)
                                        this.allResponses = result.results
                                        this.amount = result.count
                                        this.paginationUnder.ttl = Math.ceil(result.count / 30)
                                    } else {
                                        this.noResponses = 'У вас пока еще нет откликов'
                                        this.allResponses = []
                                    }
                                })
                                .then(() => {
                                    this.isLoaded = false
                                    this.isSorting = false
                                    this.setSubmit(false)
                                })
                        }
                        else if (result.results.length === 0) {
                            this.noResponses = 'У вас пока еще нет откликов'
                            this.allResponses = []
                        }
                        else {
                            this.searchUniqCompanies(result.results)
                            this.allResponses = result.results
                            this.amount = result.count
                            this.paginationUnder.ttl = Math.ceil(result.count / 30)
                        }
                    })
                    .then(() => {
                        this.isLoaded = false
                        this.isSorting = false
                        this.setSubmit(false)
                        this.setIsLoaded(false)
                    })
            }
            if (this.showStatus === 'Приглашение') {
                this.noResponses = ''
                this.getAllResponses({
                    page: this.paginationInvite.crnt,
                    ordering,
                    status: this.selectedStatus
                })
                    .then(result => {
                        if ( result === 'Неправильная страница') {
                            this.paginationInvite.crnt = 1
                            this.getAllResponses({
                                page: this.paginationInvite.crnt,
                                ordering,
                                status: this.selectedStatus
                            })
                                .then(result => {
                                    if (result.results.length) {
                                        this.searchUniqCompanies(result.results)
                                        this.allResponses = result.results
                                        this.amount = result.count
                                        this.paginationInvite.ttl = Math.ceil(result.count / 30)
                                    } else {
                                        this.noResponses = 'У вас пока еще нет откликов'
                                        this.allResponses = []
                                    }
                                })
                                .then(() => {
                                    this.isLoaded = false
                                    this.isSorting = false
                                    this.setSubmit(false)
                                    this.setIsLoaded(false)
                                })
                        }
                        else if (result.results.length === 0) {
                            this.noResponses = 'У вас пока еще нет откликов'
                            this.allResponses = []
                        }
                        else {
                            this.searchUniqCompanies(result.results)
                            this.allResponses = result.results
                            this.amount = result.count
                            this.paginationInvite.ttl = Math.ceil(result.count / 30)
                        }
                    })
                    .then(() => {
                        this.isLoaded = false
                        this.isSorting = false
                        this.setSubmit(false)
                        this.setIsLoaded(false)
                    })
            }
            if (this.showStatus === 'Отказ') {
                this.noResponses = ''
                this.getAllResponses({
                    page: this.paginationRefusal.crnt,
                    ordering,
                    status: this.selectedStatus
                })
                    .then(result => {
                        if ( result === 'Неправильная страница') {
                            this.paginationRefusal.crnt = 1
                            this.getAllResponses({
                                page: this.paginationRefusal.crnt,
                                ordering,
                                status: this.selectedStatus
                            })
                                .then(result => {
                                    if (result.results.length) {
                                        this.searchUniqCompanies(result.results)
                                        this.allResponses = result.results
                                        this.amount = result.count
                                        this.paginationRefusal.ttl = Math.ceil(result.count / 30)
                                    } else {
                                        this.noResponses = 'У вас пока еще нет откликов'
                                        this.allResponses = []
                                    }
                                })
                                .then(() => {
                                    this.isLoaded = false
                                    this.isSorting = false
                                    this.setSubmit(false)
                                    this.setIsLoaded(false)
                                })
                        }
                        else if (result.results.length === 0) {
                            this.noResponses = 'У вас пока еще нет откликов'
                            this.allResponses = []
                        }
                        else {
                            this.searchUniqCompanies(result.results)
                            this.allResponses = result.results
                            this.amount = result.count
                            this.paginationRefusal.ttl = Math.ceil(result.count / 30)
                        }
                    })
                    .then(() => {
                        this.isLoaded = false
                        this.isSorting = false
                        this.setSubmit(false)
                        this.setIsLoaded(false)
                    })
            }
        },
        searchUniqCompanies(companies) {
            let compnayId = companies.map(response => +response.vacancy.company)
            let uniq = Array.from(new Set(compnayId))
            uniq.forEach(x => Tb.getCompany(x)
                .then(response => response.data)
                .then(result => {
                    return this.recruiter.companies.push(result)
                }))
        },
        selectResponse() {
            let count = this.selected.length
            if (count) {
                this.showNotify('resp', {
                    count,
                    env: this.statuses.find(x => x.name === this.showStatus).slug
                })
                this.setSelectedResponses(this.selected)
            } else {
                this.hideNotify()
            }
        },
        sortBy(key) {
            if (this.currentSort.incr === key) {
                this.currentSort.incr = ''
                this.currentSort.decr = '-' + key
                localStorage.setItem('selectedSortingResponses', JSON.stringify(this.currentSort))
                if (this.isRecruiter) this.getResponses(key)
                else this.getUserResponses(key)
            } else {
                this.currentSort.incr = key
                this.currentSort.decr = ''
                localStorage.setItem('selectedSortingResponses', JSON.stringify(this.currentSort))
                if (this.isRecruiter) this.getResponses(key)
                else this.getUserResponses(key)
            }
        },
        changeFavorite(response) {
            if (!this.isLoaded) {
                this.isLoaded = true
                this.$store.dispatch('rec/changeResponse', {
                    id: response.id,
                    data: {
                        favourite: !response.favourite
                    }
                })
                    .then(() => this.getResponses())
                    .finally(setTimeout(() => this.isLoaded = false, 1000))
            }
        },
        responseClass(response) {
            if (response.date_update) {
                if (!response.date_watch) return 'updated'
                else {
                    let time_update = new Date(response.date_update).getTime()
                    let time_watch = new Date(response.date_watch).getTime()

                    if (time_update > time_watch) return 'updated'
                }
            }
        },
        removeResponse(id) {
            this.$store.dispatch('rec/removeResponse', id)
        },
        sortDateCreated(array) {
            return array.sort((x, y) => {
                let xCreated = new Date(x.created).getTime()
                let yCreated = new Date(y.created).getTime()

                if (xCreated > yCreated) return -1
                else if (xCreated < yCreated) return 1
                else return 0
            })
        },
        selectPageNew(item, id) {
            this.paginationNew.crnt = item;
            this.getResponses()
            if (this.paginationNew.ttl === item) {
                if (this.paginationNew.ttl >= 4) this.paginationNew.init = this.paginationNew.ttl - 4
                else this.paginationNew.init = 0
            } else if (id === 0 && item > 1) this.paginationNew.init--;
            else if (id === 30 && item < this.paginationNew.ttl - 1) this.paginationNew.init++;
        },
        selectPageUnder(item, id) {
            this.paginationUnder.crnt = item;
            this.getResponses()
            if (this.paginationUnder.ttl === item) {
                if (this.paginationUnder.ttl >= 4) this.paginationUnder.init = this.paginationUnder.ttl - 4
                else this.paginationUnder.init = 0
            } else if (id === 0 && item > 1) this.paginationUnder.init--;
            else if (id === 30 && item < this.paginationUnder.ttl - 1) this.paginationUnder.init++;
        },
        selectPageInvite(item, id) {
            this.paginationInvite.crnt = item;
            this.getResponses()
            if (this.paginationInvite.ttl === item) {
                if (this.paginationInvite.ttl >= 4) this.paginationInvite.init = this.paginationInvite.ttl - 4
                else this.paginationInvite.init = 0
            } else if (id === 0 && item > 1) this.paginationInvite.init--;
            else if (id === 30 && item < this.paginationInvite.ttl - 1) this.paginationInvite.init++;
        },
        selectPageRefusal(item, id) {
            this.paginationRefusal.crnt = item;
            this.getResponses()
            if (this.paginationRefusal.ttl === item) {
                if (this.paginationRefusal.ttl >= 4) this.paginationRefusal.init = this.paginationRefusal.ttl - 4
                else this.paginationRefusal.init = 0
            } else if (id === 0 && item > 1) this.paginationRefusal.init--;
            else if (id === 30 && item < this.paginationRefusal.ttl - 1) this.paginationRefusal.init++;
        },
        goToPage(response) {
            this.$router.push({
                path: `/profile/${this.$route.params.id}?response=true&action=watch`,
                query: {
                    responseId: response.id,
                    name: response.vacancy.name
                }
            })
        }
    }
}
</script>

<style lang="less">
@import "../../assets/styles/pages/cabinet/responses";
</style>

<style lang="less">
.show-list {
    display: block;
}
.show-list .select {
    margin-bottom: 10px;
}
.pagination {
    margin-top: 20px;
    text-align: center;
}

.user-respond {
    .updated {
        background-color: rgba(239, 114, 46, 6%)
    }
}

.table-caption-user {
    border-bottom: 0;

    div {
        padding: 0;
        text-align: left;
        border-bottom: 0;
        font-family: 'Merriweather', serif;
        font-size: 18px;
        font-weight: 700;
        cursor: pointer;
        transition: all 0.2s ease-in-out
    }

    .column-date {
        width: 23%;
        padding: 22px 15px;
    }

    .column-name {
        width: 65%;
        padding: 22px 15px;
    }

    .column-org {
        width: 15%;
        padding: 22px 15px;
    }
}
</style>
