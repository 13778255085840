<template>
	<section class="section wow simple">
		<div class="content">
			<div
				v-if="!watchUser"
				class="button blue create-resume-button"
				@click="create"
			>
				<img
					:src="'/img/icons/icon-add-white.png' | prodSrcImage"
					alt=""
				>
				Создать резюме
			</div>
			<resumes
				:key="updateComponentResume"
				:user-id="userID"
				:update="updateComponentResume"
				@update="updatePage"
			/>
		</div>
	</section>
</template>

<script>
import resumesComponent from './resumesComponent';
import {mapGetters, mapMutations} from 'vuex';

export default {
    components: {
        'resumes': resumesComponent
    },
    data: () => ({
        updateComponentResume: 0,
        watchUser: false,
    }),
    computed: {
        ...mapGetters('user', {
            userID: 'userID',
        })
    },
    created() {
        if (this.$route.params.id !== JSON.stringify(this.userID)) this.watchUser = true
    },
    mounted() {
        this.$scrollTo(document.querySelector('header.header-role'))
    },
    methods: {
        ...mapMutations('user', [
            'setShowUserCreate'
        ]),
        create() {
            if (this.$store.getters['user/userIsCreateResume']) {
                this.$router.push(`/profile/${this.$route.params.id}?resume=true&action=create`)
            } else {
                this.setShowUserCreate(true)
                this.modalOpen('stdFail', 'Для того, чтобы вы могли создавать резюме, пожалуйста, заполните свой профиль!')
            }
        },
        updatePage() {
            this.updateComponentResume += 1
        }
    }

}
</script>

<style lang="less">
.resume-enter-active, .resume-leave-active {
    transition: all .4s;
}

.resume-enter, .resume-leave-to {
    opacity: 0;
    transform: translateX(30px);
}
</style>
