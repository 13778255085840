<template>
	<div :class="editable ? 'panel-shadow' : 'panel'">
		<div class="profile-experience-block">
			<template v-if="editable">
				<div class="grid">
					<div class="box">
						<div class="field-wrap">
							<select-2
								v-if="isEdit"
								:options="EDU"
								:label="'Образование:'"
								:value="SP.name.length > 29 ? SP.name.slice(0, 29) + '...' : SP.name"
								@selected="selectEdu"
							/>
							<select-2
								v-else
								:options="EDU"
								:label="'Образование:'"
								:value="SP.name.length > 29 ? SP.name.slice(0, 29) + '...' : SP.name"
								:error="$v.SP.name.$dirty && !$v.SP.name.required ? 'Это обязательное поле' : ''"
								@selected="selectEdu"
							/>
						</div>
					</div>
					<div class="box">
						<div class="field-wrap">
							<label class="field-label">
								<c-select
									:name="'Учебное заведение:'"
									autosearch-education
									:default-data="SP.institution.name"
									:is-error="$v.SP.institution.name.$dirty && !$v.SP.institution.name.required"
									@selected="selectEduFac"
								/>
							</label>
						</div>
					</div>
					<div class="box">
						<div class="field-wrap">
							<label class="field-label">
								<input
									v-model="SP.specialization"
									type="text"
									class="field"
									placeholder="Специальность:"
								>
								<div class="label">Специальность:</div>
								<span
									v-if="$v.SP.specialization.$error"
									class="help-block form-error"
								>Это обязательное поле</span>
							</label>
						</div>
					</div>
					<div
						v-if="SP.name === 'Неоконченное среднее' || SP.name === 'Среднее'"
						class="box"
					>
						<div class="field-wrap">
							<label class="field-label">
								<c-select
									:options="classList.map(x => x.number)"
									is-onboarding
									:name="'Выбери свой класс'"
									:default-data="SP.school_class_number"
									:is-error="$v.SP.school_class_number.$dirty && !$v.SP.school_class_number.required"
									@selected="selectClass"
								/>
							</label>
						</div>
					</div>
					<div
						v-else-if="SP.name !== ''"
						class="box"
					>
						<div class="field-wrap">
							<label class="field-label">
								<input
									v-model="SP.number_course"
									type="text"
									class="field"
									placeholder="Курс:"
								>
								<div class="label">Курс:</div>
								<span
									v-if="$v.SP.number_course.$error"
									class="help-block form-error"
								>Это обязательное поле</span>
							</label>
						</div>
					</div>
					<div class="box">
						<div class="field-wrap">
							<label class="field-label">
								<input
									v-model.trim="SP.city"
									type="text"
									class="field"
									placeholder="Город"
								>
								<div class="label">Город:</div>
								<span
									v-if="$v.SP.city.$error"
									class="help-block form-error"
								>Это обязательное поле</span>
							</label>
						</div>
					</div>
					<div class="box">
						<div class="field-wrap">
							<select-2
								:options="options.years"
								:label="'Год окончания:'"
								:value="SP.graduation_year"
								:error="$v.SP.graduation_year.$dirty && $v.SP.graduation_year.$error ? 'Это обязательное поле' : ''"
								@selected="selectYear"
							/>
						</div>
					</div>
					<div class="box full">
						<div class="field-wrap">
							<label class="checkbox-filter">
								<input
									v-model="SP.primary_education"
									type="checkbox"
								>
								<span>Выбрать как основное</span>
							</label>
						</div>
					</div>
				</div>
				<button
					class="button"
					@click="save"
					v-text="textButtonSave"
				/>
				<button
					class="button-hollow"
					style="margin-left: 20px;"
					@click="cancel"
				>
					Отменить
				</button>
			</template>
			<template v-else>
				<div
					v-if="!isWatch && !editing"
					class="panel-remove"
					@click="remove"
				/>
				<div
					v-if="SP.primary_education && !isWatch"
					class="info green"
					style="margin-bottom: 25px"
				>
					<small>Выбрано как основное</small>
				</div>
				<div class="cells">
					<div class="cell">
						<div class="text dark sm">
							<p>{{ SP.name }}</p>
							<p v-if="SP.graduation_year">
								<small>Год окончания: {{ SP.graduation_year }}</small>
							</p>
							<p v-if="SP.city">
								г. {{ SP.city }}
							</p>
						</div>
					</div>
					<div class="cell">
						<div class="text dark sm">
							<p v-if="SP.institution && SP.institution.name">
								<small>Учебное заведение:</small><br>
								{{ SP.institution.name }}
							</p>
							<p v-if="SP.school_class_number && SP.name === 'Неоконченное среднее' || SP.name === 'Среднее'">
								<small>Класс:</small><br>
								{{ SP.school_class_number }}
							</p>
							<p v-else-if="SP.number_course">
								<small>Курс:</small><br>
								{{ SP.number_course }}
							</p>
							<p v-if="SP.specialization !== null && SP.specialization !== ''">
								<small>Специальность:</small><br>
								{{ SP.specialization }}
							</p>
						</div>
					</div>
				</div>
				<template v-if="!isWatch">
					<br>
					<button
						class="button-control"
						@click="edit"
					>
						<img
							:src="'/img/icons/icon-edit.png' | prodSrcImage"
							alt="Редактировать образование"
						>
						Редактировать
					</button>
				</template>
			</template>
		</div>
	</div>
</template>

<script>

import { required, minValue } from 'vuelidate/lib/validators'
import {mapGetters} from 'vuex';

export default {
    props: {
        spData: {
            type: Object,
            default: () => new Object()
        },
        isAdd: {
            type: Boolean,
            default: false
        },
        isWatch: {
            type: Boolean,
            default: false
        },
        editing: {
            type: Boolean,
            default: false
        }
    },
    validations() {
        if (this.SP.name === 'Неоконченное среднее' || this.SP.name === 'Среднее') {
            let SP = {
                name: { required },
                institution: {
                    name: {required}
                },
                graduation_year: {
                    minValue: minValue(1980),
                    required
                },
                specialization: { required },
                city: { required },
                school_class_number: { required }
            }
            return {
                SP
            }
        }
        else {
           let SP = {
               name: { required },
               institution: {
                   name: {required}
               },
               graduation_year: {
                   minValue: minValue(1980),
                   required
               },
               specialization: { required },
               city: { required },
               number_course: { required }
           }
            return {
                SP
            }

        }
    },
    data: () => ({
        isEdit: false,
        SP: {
            name: '',
            institution: {
                name: ''
            },
            specialization: '',
            school_class_number: null,
            number_course: null,
            school_number: null,
            city: '',
            graduation_year: 0,
            primary_education: false
        },
        options: {
            years: []
        },
        classList: [
            {
                number: '1'
            },
            {
                number: '2'
            },
            {
                number: '3'
            },
            {
                number: '4'
            },
            {
                number: '5'
            },
            {
                number: '6'
            },
            {
                number: '7'
            },
            {
                number: '8'
            },
            {
                number: '9'
            },
            {
                number: '10'
            },
            {
                number: '11'
            }
        ]
    }),
    computed: {
        ...mapGetters('cnst', {
            EDU: 'getEducation'
        }),
        editable() {
            return this.isEdit || this.isAdd
        },
        textButtonSave() {
            if (this.isEdit) {
                return 'Сохранить'
            } else if (this.isAdd) {
                return 'Добавить'
            } else {
                return ''
            }
        }
    },
    watch: {
        spData() {
            if (this.spData && this.spData.name) {
                this.SP = JSON.parse(JSON.stringify(this.spData))
            }
        }
    },
    created() {
        let start = 1980
        if (this.spData && this.spData.name) {
            this.SP = JSON.parse(JSON.stringify(this.spData))
            !this.SP.institution ? this.SP.institution = {name: ''} : true
        } else {
            this.reset()
        }

        this.$v.SP.name.$reset()

        while (start <= new Date().getFullYear() + 11) {
            this.options.years.push(start++)
        }
    },
    methods: {
        edit() {
            this.isEdit = true
            this.$emit('edit')
        },
        save() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                console.log('Error in Study Place panel', this.$v)
            } else {
                if (this.isEdit) {
                    this.$emit('saved', this.SP)
                    this.isEdit = false
                } else if (this.isAdd) {
                    !this.SP.institution.name ? this.institution = null : true
                    this.$emit('added', this.SP)
                    this.reset()
                }
            }
        },
        reset() {
            this.$v.$reset()
            this.SP = {
                name: '',
                institution: {
                    name: ''
                },
                specialization: '',
                school_class_number: null,
                school_number: null,
                graduation_year: 0,
                primary_education: false
            }
        },
        cancel() {
            this.$emit('cancel')
            this.isEdit = false
            if (this.isAdd) {
                this.reset()
            }
        },
        remove() {
            this.$emit('remove')
        },
        selectEdu(val) {
            this.SP.name = val
        },
        selectEduFac(val) {
            this.SP.institution.name = val.toUpperCase();
        },
        selectYear(val) {
            this.SP.graduation_year = val
        },
        selectClass(val) {
            this.SP.school_class_number = val
        },
        selectCity(val) {
            this.SP.city = val;
        },
    }
}
</script>

<style>
.info.green {
    color: #0d9f47
}
</style>
