<template>
	<section class="section wow">
		<div class="content">
			<div
				v-if="isLoaded"
				class="preloader sm"
				style="margin-top: 50px;"
			/>
			<div
				v-else-if="companies.length"
				class="companies"
			>
				<div class="table-caption">
					<div
						class="column-company-name"
						data-label="Отображаемое название"
						:class="{ 'incr': currentSort.incr === 'company_name', 'decr': currentSort.decr === '-company_name' }"
						@click="sortBy('company_name')"
					>
						Отображаемое название
					</div>
					<div
						class="column-company-org"
						data-label="Организация"
						:class="{ 'incr': currentSort.incr === 'company_real_name', 'decr': currentSort.decr === '-company_real_name' }"
						@click="sortBy('company_real_name')"
					>
						Организация
					</div>
					<div
						class="column-company-city"
						data-label="Город"
						:class="{ 'incr': currentSort.incr === 'city', 'decr': currentSort.decr === '-city' }"
						@click="sortBy('city')"
					>
						Город
					</div>
				</div>
				<div
					v-if="isSorting"
					class="preloader sm"
					style="margin-top: 50px;"
				/>
				<div
					v-else
					class="table-main"
				>
					<router-link
						v-for="company in companies"
						:key="company.id"
						class="table-row"
						:to="{path: `/profile?company=true&action=watch&id=${company.id}`,query: {id: company.id,name: company.company_name}}"
					>
						<div class="column-company-name">
							{{ company.company_name ? company.company_name : '-' }}
						</div>
						<div
							class="column-company-org"
							data-label="Организация:"
						>
							{{ company.company_real_name ? company.company_real_name : '-' }}
						</div>
						<div
							class="column-company-city"
							data-label="Город:"
						>
							{{ company.city ? company.city : '-' }}
						</div>
					</router-link>
					<ul
						v-if="pagination.ttl > 1"
						class="pagination"
					>
						<li
							v-if="pagination.init"
							@click="pagination.init--"
						>
							<span>&lt;</span>
						</li>
						<li
							v-for="(item, index) in pages"
							:key="index"
							:class="{ active: pagination.crnt === item }"
							@click="selectPage(item, index)"
						>
							<span>{{ item }}</span>
						</li>
						<li v-if="pagination.ttl - pagination.init > 4">
							<span>...</span>
						</li>
						<li
							v-if="isShowTotal"
							:class="{ active: pagination.crnt === pagination.ttl }"
							@click="selectPage(pagination.ttl)"
						>
							<span>{{ pagination.ttl }}</span>
						</li>
						<li
							v-if="isShowNext"
							@click="pagination.init++"
						>
							<span>&gt;</span>
						</li>
					</ul>
				</div>
			</div>
			<div
				v-if="companies.length === 0"
				class="companies-empty"
			>
				{{ noCompanies }}
			</div>
		</div>
	</section>
</template>

<script>
import {mapActions} from 'vuex';

export default {
    data: () => ({
        noCompanies: '',
        isLoaded: false,
        isSorting: false,
        currentSort: localStorage.getItem('selectedSortingCompanies') ? JSON.parse(localStorage.getItem('selectedSortingCompanies')) :{
            incr: '',
            decr: ''
        },
        pagination: {
            init: 0,
            crnt: 1,
            ttl: undefined
        },
        amount: 0,
        companies: []
    }),
    computed: {
        pages() {
            let counter = 1;
            let temp = [];
            let max = Math.ceil(this.amount / 30) < 3 ? Math.ceil(this.amount / 30) : 3
            while (counter <= max) {
                temp.push(this.pagination.init + counter);
                counter++;
            }
            return temp;
        },
        isShowTotal() {
            if (this.amount) return Math.ceil(this.amount / 30) > 3
            return false
        },
        isShowNext() {
            return (this.pagination.init !== this.pagination.ttl - 4) && this.isShowTotal
        },
    },
    created() {
        this.getCompanies()
    },
    methods: {
        ...mapActions('rec', {
            getAllCompanies: 'getAllCompanies',
        }),
        getCompanies(ordering) {
            if (this.currentSort.decr) ordering = this.currentSort.decr
            else ordering = this.currentSort.incr
            if (ordering) this.isSorting = true
            else this.isLoaded = true
            this.noCompanies = ''
            this.getAllCompanies({
                page: this.pagination.crnt,
                ordering
            })
                .then(result => {
                    if (!result.results.length) {
                        this.noCompanies = 'Пусто'
                    }
                    this.companies = result.results
                    this.amount = result.count
                    this.pagination.ttl = Math.ceil(result.count / 30)
                })
                .then(() => {
                    this.isLoaded = false
                    this.isSorting = false
                })
        },
        sortBy(key) {
            if (this.currentSort.incr === key) {
                this.currentSort.incr = ''
                this.currentSort.decr = '-' + key
                localStorage.setItem('selectedSortingCompanies', JSON.stringify(this.currentSort))
                this.getCompanies(key)
            } else {
                this.currentSort.incr = key
                this.currentSort.decr = ''
                localStorage.setItem('selectedSortingCompanies', JSON.stringify(this.currentSort))
                this.getCompanies(key)
            }
        },
        selectPage(item, id) {
            this.pagination.crnt = item;
            this.getCompanies()

            if (this.pagination.ttl === item) {
                if (this.pagination.ttl >= 4) this.pagination.init = this.pagination.ttl - 4
                else this.pagination.init = 0
            }
            else if (id === 0 && item > 1) this.pagination.init--;
            else if (id === 30 && item < this.pagination.ttl - 1) this.pagination.init++;
        }
    }
}
</script>

<style lang="less">
.companies {
    margin-top: 40px;
    &-empty {
        margin-top: 40px;
    }
    .column-company-name {
        width: 45%;
        &::before {
            display: none !important;
        }
    }

    .column-company-org {
        width: 40%;
    }

    .column-company-city {
        width: 15%;
    }

}
</style>

<style lang="less">
.companies {
    .table-main {
        .table-row {
            cursor: pointer;
        }
    }
}
.pagination {
    margin-top: 20px;
    text-align: center;
}
</style>
