<template>
	<section
		class="section wow simple"
	>
		<template v-if="watchUser">
			<UserInfo />
		</template>
		<template v-else>
			<MainUserInfo />
		</template>
	</section>
</template>

<script>

import {mapGetters} from 'vuex'
import UserInfo from '@/pages/cabinet/UserInfo';
import MainUserInfo from '@/pages/cabinet/MainUserInfo';

export default {
    components: {
        UserInfo,
        MainUserInfo

    },
    data: () => ({
        openUserInfo: false,
    }),
    computed: {
        ...mapGetters('user', {
            userID: 'userID',
            isRecruiter: 'isRecruiter'
        }),
        watchUser() {
            if (this.$route.params.id && this.isRecruiter) return this.$route.params.id && this.$route.params.id !== JSON.stringify(this.userID)
            else return false
        }
    }
}
</script>

<style lang="less">
@import "../../assets/styles/pages/cabinet/profile";
</style>
