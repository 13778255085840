<template>
	<ul
		class="pagination"
		:class="{'courses-pagination': isCourses}"
	>
		<li
			v-if="pagination.init"
			@click="pagination.init--"
		>
			<span>&lt;</span>
		</li>
		<li
			v-for="(item, index) in pages"
			:key="index"
			:class="{ active: pagination.crnt === item }"
			@click="changePage(item, index)"
		>
			<span>{{ item }}</span>
		</li>
		<li v-if="pagination.ttl - pagination.init > 4">
			<span class="not-hover">...</span>
		</li>
		<li
			v-if="isShowTotal"
			:class="{ active: pagination.crnt === pagination.ttl }"
			@click="changePage(pagination.ttl)"
		>
			<span>{{ pagination.ttl }}</span>
		</li>
		<li
			v-if="isShowNext"
			@click="pagination.init++"
		>
			<span>&gt;</span>
		</li>
	</ul>
</template>

<script>
export default {
    name: 'PagePagination',

    props: {
        pagination: {
            type: Object,
            default: function () {
                return new Object();
            }
        },
        pages: {
            type: Array,
            default: function () {
                return new Array()
            }
        },
        isShowTotal: {
            type: Boolean,
            default: false
        },
        isShowNext: {
            type: Boolean,
            default: false
        },
        isCourses: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        changePage(item, index) {
            this.$emit('changePage', {item: item, index: index});
        },
    },
}
</script>

<style lang="less" scoped>
.courses-pagination {
    margin-top: 30px;
    text-align: center;
}
.pagination {
    font-size: 0;
}

.not-hover {
    pointer-events: none;
}
.pagination li span{
    width: 100%;
    height: auto;
    line-height: 15px;
    padding: 5px 10px;
}
</style>
