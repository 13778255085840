<template>
	<div v-if="prize">
		<div
			class="prize"
			:class="{ 'prize--ready': prize.used && prize.present.type !== 'method' }"
		>
			<div class="prize__header">
				<img
					:src="prize.present.icon"
					alt="Приз"
					class="prize__icon"
				>
				<div class="prize__header-content">
					<div class="prize__header-title">
						<h4 class="title-element">
							{{ prize.present.title_main }}
						</h4>
					</div>
					<div
						v-if="prize.present.duration"
						class="prize__duration"
					>
						{{ prize.present.duration }}
					</div>
				</div>
			</div>
			<div class="prize__grid">
				<div class="prize__content">
					<h3 class="title-block">
						<span>
							{{ prize.present.title }}
						</span>
						<img
							v-if="prize.used"
							:src="'/img/icons/icon__check-achieved.svg' | prodSrcImage"
							alt="Иконка"
						>
					</h3>
					<div class="prize__text">
						{{ prize.present.text }}
					</div>
					<div
						v-if="prize.present.action !== 'not_action'"
						class="prize__buttons"
					>
						<button
							v-if="!isLoading && !prize.used || (!isLoading && prize.present.type === 'method')"
							class="button"
							:class="{ 'button--disabled': !prize.available }"
							@click="getData(prize, prize.present.type === 'url' ? 'Записаться' :
								coupon === '' ? 'Забрать приз': 'Активировать')"
						>
							{{ prize.present.type === 'url' ? 'Записаться' :
								coupon === '' ? 'Забрать приз': 'Активировать' }}
						</button>
						<div
							v-if="coupon !== ''"
							class="promocode"
							:class="{'promocode--active': isCopied}"
							@click="copyToClipBoard(coupon)"
						>
							{{ coupon }}
							<div
								class="promocode__tooltip"
							>
								Промокод скопирован
							</div>
						</div>
						<div
							v-if="isLoading"
							class="button orange button-preloader"
						>
							Немного подождите...
							<div class="button-preloader-icons">
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
							</div>
						</div>
					</div>
				</div>
				<div
					class="prize__pic"
					:style="prize.present.banner | bgrDynamicImageCover"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import User from '@/api/user'

export default {
    name: 'PrizesBlock',
    props: {
        prize: {
            type: Object,
            default: null
        }
    },
    data: () => ({
        isLoading: false,
        coupon: '',
        isCopied: false
    }),
    methods: {
        getData(item, text) {
            if (item.present.type === 'url' || item.present.type === 'file') window.open(item.present.action, '_blank')
            if (item.present.type === 'method' && text === 'Забрать приз') {
                this.isLoading = true
                User.getPrize(item.present.action)
                    .then( res => {
                        this.coupon = res.data.coupon
                        this.isLoading = false
                    })
                    .catch(err => console.log(err))
            } else if (item.present.type === 'method' && text === 'Активировать') {
                if (localStorage.getItem('userRole') === 'Student' ||
                    localStorage.getItem('userRole') === 'College') window.open('https://www.ozon.ru/my/codes', '_blank')
                if (localStorage.getItem('userRole') === 'Schoolboy') window.open('https://music.vk.com/promocode', '_blank')
            }
            if (item.present.type !== 'method') User.UsedPrize(item.id, 'used=true')
        },
        copyToClipBoard(textToCopy){
            navigator.clipboard.writeText(textToCopy);
            this.isCopied = true
            setTimeout(() => this.isCopied = false, 3000)
        }
    }
}
</script>
