<template>
	<div class="modal">
		<div class="modal-container">
			<div class="modal-inner">
				<div class="modal-body">
					<div
						class="modal-close"
						@click="close()"
					/>
					<div class="modal-content">
						<div class="center">
							<h3 class="modal-title">
								Удалить ключ
							</h3>
							<br>
							<div class="text dark">
								<p>Вы действительно хотите удалить свой ключ?</p>
							</div>
							<div class="buttons">
								<div class="cells">
									<div class="cell">
										<div
											class="button-hollow lg"
											@click="deleteKey"
										>
											Удалить
										</div>
									</div>
									<div class="cell">
										<div
											class="button orange lg"
											@click="close()"
										>
											Отменить
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	methods: {
		deleteKey() {
			this.$emit('remove');
		},
    close() {
      this.$emit('close')
    }
	}
};
</script>
