<template>
	<section class="section wow">
		<div class="content">
			<div class="top-btns">
				<div class="show-list">
					<h4>Показывать:</h4>
					<div class="select-type-simple">
						<c-select
							:options="statusesOptions"
							:default-data="showStatus"
							@selected="selectStatus"
						/>
					</div>
				</div>


				<div
					class="button blue create-resume-button"
					@click="$router.push('/profile?vacancy=true&action=create')"
				>
					<img :src="'/img/icons/icon-add-white.png' | prodSrcImage">
					Добавить вакансию
				</div>
			</div>
			<div
				v-if="isLoaded || getIsLoaded"
				class="preloader sm"
				style="margin: 50px auto;"
			/>
			<div
				v-else
				class="vacancies rekrut-vacancies"
			>
				<div class="content">
					<div class="table-caption">
						<div class="column-checkbox" />
						<div
							class="column-name"
							data-label="Название"
							:class="{ 'incr': currentSort.incr === 'name', 'decr': currentSort.decr === '-name' }"
							@click="sortBy('name')"
						>
							Название
						</div>
						<div
							class="column-org"
							data-label="Организация"
							:class="{ 'incr': currentSort.incr === 'company_name', 'decr': currentSort.decr === '-company_name' }"
							@click="sortBy('company_name')"
						>
							Организация
						</div>
						<div
							class="column-company"
							data-label="Компания"
							:class="{ 'incr': currentSort.incr === 'company_real_name', 'decr': currentSort.decr === '-company_real_name' }"
							@click="sortBy('company_real_name')"
						>
							Компания
						</div>
						<div
							class="column-city"
							data-label="Город"
							:class="{ 'incr': currentSort.incr === 'city', 'decr': currentSort.decr === '-city' }"
							@click="sortBy('city')"
						>
							Город
						</div>
						<div
							class="column-date"
							data-label="Дата добавления"
							:class="{ 'incr': currentSort.incr === 'date_create', 'decr': currentSort.decr === '-date_create' }"
							@click="sortBy('date_create')"
						>
							Дата добавления
						</div>
						<div
							class="column-status"
							data-label="Статус"
						>
							Статус
						</div>
					</div>
					<div
						v-if="isSorting"
						class="preloader sm"
						style="margin-top: 50px;"
					/>
					<div
						v-else-if="filteredVacancies.length"
						class="table-main"
					>
						<div
							v-for="vacancy in filteredVacancies"
							:key="vacancy.id"
							class="table-row"
						>
							<div class="column-checkbox">
								<label class="checkbox-filter blue-filter">
									<input
										v-model="selected"
										type="checkbox"
										:value="vacancy.id"
										@change="selectVacancy"
									>
									<span />
								</label>
							</div>
							<div class="column-name">
								<router-link
									:to="{path: `/profile?vacancy=true&action=watch&id=${vacancy.id}`, query: {slug: vacancy.slug, id: vacancy.id, name: vacancy.name}}"
									style="cursor: pointer"
								>
									{{ vacancy.name }}
								</router-link>
							</div>
							<div
								class="column-org"
								data-label="Организация:"
								@click="$router.push(`/profile?vacancy=true&action=watch&id=${vacancy.id}`).catch(() => {})"
							>
								{{ vacancy.company.company_name ? vacancy.company.company_name : '-' }}
							</div>
							<div
								class="column-company"
								data-label="Компания:"
							>
								{{ vacancy.company.company_real_name ? vacancy.company.company_real_name : '-' }}
							</div>
							<div
								class="column-city city-styles"
								data-label="Город:"
							>
								{{ vacancy.city ? vacancy.city : '-' }}
							</div>
							<div
								class="column-date"
								data-label="Дата добавления:"
							>
								{{ vacancy.date_create | dateFormat }}
							</div>
							<div
								class="column-status"
								data-label="Статус:"
							>
								<span :class="vacancy.status | statusClass">{{ statusFormat(vacancy.status) }}</span>
							</div>
						</div>
						<ul
							v-if="paginationActual.ttl > 1 && showStatus === 'Актуальные' "
							class="pagination"
						>
							<li
								v-if="paginationActual.init"
								@click="paginationActual.init--"
							>
								<span>&lt;</span>
							</li>
							<li
								v-for="(item, index) in pagesActual"
								:key="index"
								:class="{ active: paginationActual.crnt === item }"
								@click="selectPageActual(item, index)"
							>
								<span>{{ item }}</span>
							</li>
							<li v-if="paginationActual.ttl - paginationActual.init > 4">
								<span>...</span>
							</li>
							<li
								v-if="isShowTotal"
								:class="{ active: paginationActual.crnt === paginationActual.ttl }"
								@click="selectPageActual(paginationActual.ttl)"
							>
								<span>{{ paginationActual.ttl }}</span>
							</li>
							<li
								v-if="isShowNextActual"
								@click="paginationActual.init++"
							>
								<span>&gt;</span>
							</li>
						</ul>
						<ul
							v-if="paginationDraft.ttl > 1 && showStatus === 'Черновик' "
							class="pagination"
						>
							<li
								v-if="paginationDraft.init"
								@click="paginationDraft.init--"
							>
								<span>&lt;</span>
							</li>
							<li
								v-for="(item, index) in pagesDraft"
								:key="index"
								:class="{ active: paginationDraft.crnt === item }"
								@click="selectPageDraft(item, index)"
							>
								<span>{{ item }}</span>
							</li>
							<li v-if="paginationDraft.ttl - paginationDraft.init > 4">
								<span>...</span>
							</li>
							<li
								v-if="isShowTotal"
								:class="{ active: paginationDraft.crnt === paginationDraft.ttl }"
								@click="selectPageDraft(paginationDraft.ttl)"
							>
								<span>{{ paginationDraft.ttl }}</span>
							</li>
							<li
								v-if="isShowNextDraft"
								@click="paginationDraft.init++"
							>
								<span>&gt;</span>
							</li>
						</ul>
						<ul
							v-if="paginationArchive.ttl > 1 && showStatus === 'Архивные' "
							class="pagination"
						>
							<li
								v-if="paginationArchive.init"
								@click="paginationArchive.init--"
							>
								<span>&lt;</span>
							</li>
							<li
								v-for="(item, index) in pagesArchive"
								:key="index"
								:class="{ active: paginationArchive.crnt === item }"
								@click="selectPageArchive(item, index)"
							>
								<span>{{ item }}</span>
							</li>
							<li v-if="paginationArchive.ttl - paginationArchive.init > 4">
								<span>...</span>
							</li>
							<li
								v-if="isShowTotal"
								:class="{ active: paginationArchive.crnt === paginationArchive.ttl }"
								@click="selectPageArchive(paginationArchive.ttl)"
							>
								<span>{{ paginationArchive.ttl }}</span>
							</li>
							<li
								v-if="isShowNextArchive"
								@click="paginationArchive.init++"
							>
								<span>&gt;</span>
							</li>
						</ul>
					</div>
					<p v-if="filteredVacancies.length === 0">
						{{ noVacancies }}
					</p>
				</div>
			</div>
		</div>
	</section>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from 'vuex';

export default {
    filters: {
        statusClass(status) {
            if (status === 'archive') return 'status-gray'
            else if (status === 'actual') return 'status-green'
            else if (status === 'draft') return 'status-orange'
        }
    },
    data: () => ({
        noVacancies: '',
        isLoaded: false,
        isSorting: false,
        selected: [],
        currentSort: localStorage.getItem('selectedSortingVacancies') ? JSON.parse(localStorage.getItem('selectedSortingVacancies')) : {
            incr: '',
            decr: ''
        },
        showStatus: '',
        vacancies: [],
        amount: 0,
        paginationActual: {
            init: 0,
            crnt: 1,
            ttl: undefined
        },
        paginationDraft: {
            init: 0,
            crnt: 1,
            ttl: undefined
        },
        paginationArchive: {
            init: 0,
            crnt: 1,
            ttl: undefined
        },
        selectedStatus: localStorage.getItem('selectedStatusVacancies') ? localStorage.getItem('selectedStatusVacancies') : 'actual'
    }),
    computed: {
        ...mapGetters('cnst', {
            statuses: 'getVS'
        }),
        ...mapGetters('rec', {
            selectedVacancies: 'getSelectedVacancies',
            getIsLoaded: 'getIsLoaded',
        }),
        ...mapGetters('ntf', {
            submit: 'getSubmit'
        }),
        statusesOptions() {
            return this.statuses.map(x => x.name)
        },
        token() {
            return this.$store.state.user.token
        },
        filteredVacancies() {
            let status = this.statuses.find(x => x.name === this.showStatus).slug
            return this.vacancies && this.vacancies.filter(x => x.status === status)
        },
        pagesActual() {
            let counter = 1;
            let temp = [];
            let max = Math.ceil(this.amount / 30) < 3 ? Math.ceil(this.amount / 30) : 3
            while (counter <= max) {
                temp.push(this.paginationActual.init + counter);
                counter++;
            }
            return temp;
        },
        pagesDraft() {
            let counter = 1;
            let temp = [];
            let max = Math.ceil(this.amount / 30) < 3 ? Math.ceil(this.amount / 30) : 3
            while (counter <= max) {
                temp.push(this.paginationDraft.init + counter);
                counter++;
            }
            return temp;
        },
        pagesArchive() {
            let counter = 1;
            let temp = [];
            let max = Math.ceil(this.amount / 30) < 3 ? Math.ceil(this.amount / 30) : 3
            while (counter <= max) {
                temp.push(this.paginationArchive.init + counter);
                counter++;
            }
            return temp;
        },
        isShowTotal() {
            if (this.amount) return Math.ceil(this.amount / 30) > 3
            return false
        },
        isShowNextActual() {
            return (this.paginationActual.init !== this.paginationActual.ttl - 4) && this.isShowTotal
        },
        isShowNextDraft() {
            return (this.paginationDraft.init !== this.paginationDraft.ttl - 4) && this.isShowTotal
        },
        isShowNextArchive() {
            return (this.paginationArchive.init !== this.paginationArchive.ttl - 4) && this.isShowTotal
        },
    },
    watch: {
        selectedVacancies() {
            this.selected = this.selectedVacancies
        },
        submit: {
            handler() {
                if(this.submit) {
                    this.getVacancies()
                    this.selected = []
                }
            },
            deep: true
        },
    },
    created() {
        this.showStatus = localStorage.getItem('showStatusVacancies') ? localStorage.getItem('showStatusVacancies') : 'Актуальные'
        this.getVacancies()
    },
    methods: {
        ...mapActions('rec', {
            getAllVacancies: 'getAllVacancies',
        }),
        ...mapActions('rec', ['setSelectedVacancies']),
        ...mapMutations('ntf', [
            'setSubmit'
        ]),
        ...mapMutations('rec', [
            'setIsLoaded',
        ]),
        statusFormat(status) {
            return this.statuses.find(x => x.slug === status).name
        },
        formatStatus(val) {
            return this.statuses.values[this.statuses.slugs.findIndex(x => x === val)]
        },
        selectStatus(val) {
            this.showStatus = val
            if (this.showStatus === 'Актуальные') this.selectedStatus = 'actual'
            if (this.showStatus === 'Черновик') this.selectedStatus = 'draft'
            if (this.showStatus === 'Архивные') this.selectedStatus = 'archive'
            localStorage.setItem('selectedStatusVacancies', this.selectedStatus)
            localStorage.setItem('showStatusVacancies', this.showStatus)
            this.selected = new Array()
            this.hideNotify()
            this.getVacancies()
        },
        selectVacancy() {
            if (this.selected.length) {
                this.showNotify('vac', {
                    count: this.selected.length,
                    env: this.statuses.find(x => x.name === this.showStatus).slug,
                })
                this.setSelectedVacancies(this.selected)
            } else {
                this.hideNotify()
            }
        },
        getVacancies(ordering) {
            this.showStatus = localStorage.getItem('showStatusVacancies') ? localStorage.getItem('showStatusVacancies') : 'Актуальные'
            this.selectedStatus = localStorage.getItem('selectedStatusVacancies') ? localStorage.getItem('selectedStatusVacancies') : 'actual'
            if (this.currentSort.decr) ordering = this.currentSort.decr
            else ordering = this.currentSort.incr
            if (ordering) this.isSorting = true
            else this.isLoaded = true
            if (this.showStatus === 'Актуальные') {
                this.noVacancies = ''
                this.getAllVacancies({
                    page: this.paginationActual.crnt,
                    ordering,
                    status: this.selectedStatus
                })
                    .then(result => {
                        if ( result === 'Неправильная страница') {
                            this.paginationActual.crnt = 1
                            this.getAllVacancies({
                                page: this.paginationActual.crnt,
                                ordering,
                                status: this.selectedStatus
                            })
                                .then(result => {
                                    if (result.results.length) {
                                        this.vacancies = result.results
                                        this.amount = result.count
                                        this.paginationActual.ttl = Math.ceil(result.count / 30)
                                    } else {
                                        this.noVacancies = 'У вас пока еще нет вакансий'
                                        this.vacancies = []
                                    }
                                })
                                .then(() => {
                                    this.isLoaded = false
                                    this.isSorting = false
                                    this.setSubmit(false)
                                    this.setIsLoaded(false)
                                })
                        }
                        else if (result.results.length === 0) {
                            this.noVacancies = 'У вас пока еще нет вакансий'
                            this.vacancies = []
                        }
                        else {
                            this.vacancies = result.results
                            this.amount = result.count
                            this.paginationActual.ttl = Math.ceil(result.count / 30)
                        }
                    })
                    .then(() => {
                        this.isLoaded = false
                        this.isSorting = false
                        this.setSubmit(false)
                        this.setIsLoaded(false)
                    })
            }
            if (this.showStatus === 'Черновик') {
                this.noVacancies = ''
                this.getAllVacancies({
                    page: this.paginationDraft.crnt,
                    ordering,
                    status: this.selectedStatus
                })
                    .then(result => {
                        if ( result === 'Неправильная страница') {
                            this.paginationDraft.crnt = 1
                            this.getAllVacancies({
                                page: this.paginationDraft.crnt,
                                ordering,
                                status: this.selectedStatus
                            })
                                .then(result => {
                                    if (result.results.length) {
                                        this.vacancies = result.results
                                        this.amount = result.count
                                        this.paginationDraft.ttl = Math.ceil(result.count / 30)
                                    } else {
                                        this.noVacancies = 'У вас пока еще нет вакансий'
                                        this.vacancies = []
                                    }
                                })
                                .then(() => {
                                    this.isLoaded = false
                                    this.isSorting = false
                                    this.setSubmit(false)
                                    this.setIsLoaded(false)
                                })
                        }
                        else if (result.results.length === 0) {
                            this.noVacancies = 'У вас пока еще нет вакансий'
                            this.vacancies = []
                        }
                        else {
                            this.vacancies = result.results
                            this.amount = result.count
                            this.paginationDraft.ttl = Math.ceil(result.count / 30)
                        }
                    })
                    .then(() => {
                        this.isLoaded = false
                        this.isSorting = false
                        this.setSubmit(false)
                        this.setIsLoaded(false)
                    })
            }
            if (this.showStatus === 'Архивные') {
                this.noVacancies = ''
                this.getAllVacancies({
                    page: this.paginationArchive.crnt,
                    ordering,
                    status: this.selectedStatus
                })
                    .then(result => {
                        if ( result === 'Неправильная страница') {
                            this.paginationArchive.crnt = 1
                            this.getAllVacancies({
                                page: this.paginationArchive.crnt,
                                ordering,
                                status: this.selectedStatus
                            })
                                .then(result => {
                                    if (result.results.length) {
                                        this.vacancies = result.results
                                        this.amount = result.count
                                        this.paginationArchive.ttl = Math.ceil(result.count / 30)
                                    } else {
                                        this.noVacancies = 'У вас пока еще нет вакансий'
                                        this.vacancies = []
                                    }
                                })
                                .then(() => {
                                    this.isLoaded = false
                                    this.isSorting = false
                                    this.setSubmit(false)
                                    this.setIsLoaded(false)
                                })
                        }
                        else if (result.results.length === 0) {
                            this.noVacancies = 'У вас пока еще нет вакансий'
                            this.vacancies = []
                        }
                        else {
                            this.vacancies = result.results
                            this.amount = result.count
                            this.paginationArchive.ttl = Math.ceil(result.count / 30)
                        }
                    })
                    .then(() => {
                        this.isLoaded = false
                        this.isSorting = false
                        this.setSubmit(false)
                        this.setIsLoaded(false)
                    })
            }
        },
        removeVacancy(id) {
            this.$store.dispatch('rec/removeVacancy', id)
        },
        sortBy(path) {
            let [path1, path2] = path.split('/'),
                key = undefined;

            if (!path2) key = path1

            if (this.currentSort.incr === key) {
                this.currentSort.incr = ''
                this.currentSort.decr = '-' + key
                localStorage.setItem('selectedSortingVacancies', JSON.stringify(this.currentSort))
                this.getVacancies(key)
            } else {
                this.currentSort.incr = key
                this.currentSort.decr = ''
                localStorage.setItem('selectedSortingVacancies', JSON.stringify(this.currentSort))
                this.getVacancies(key)
            }
        },
        selectPageActual(item, id) {
            this.paginationActual.crnt = item;
            this.getVacancies()

            if (this.paginationActual.ttl === item) {
                if (this.paginationActual.ttl >= 4) this.paginationActual.init = this.paginationActual.ttl - 4
                else this.paginationActual.init = 0
            }
            else if (id === 0 && item > 1) this.paginationActual.init--;
            else if (id === 30 && item < this.paginationActual.ttl - 1) this.paginationActual.init++;
        },
        selectPageDraft(item, id) {
            this.paginationDraft.crnt = item;
            this.getVacancies()

            if (this.paginationDraft.ttl === item) {
                if (this.paginationDraft.ttl >= 4) this.paginationDraft.init = this.paginationDraft.ttl - 4
                else this.paginationDraft.init = 0
            }
            else if (id === 0 && item > 1) this.paginationDraft.init--;
            else if (id === 30 && item < this.paginationDraft.ttl - 1) this.paginationDraft.init++;
        },
        selectPageArchive(item, id) {
            this.paginationArchive.crnt = item;
            this.getVacancies()

            if (this.paginationArchive.ttl === item) {
                if (this.paginationArchive.ttl >= 4) this.paginationArchive.init = this.paginationArchive.ttl - 4
                else this.paginationArchive.init = 0
            }
            else if (id === 0 && item > 1) this.paginationArchive.init--;
            else if (id === 30 && item < this.paginationArchive.ttl - 1) this.paginationArchive.init++;
        }
    }
}
</script>

<style lang="less">
.rekrut-vacancies {
    &-empty {
        margin-top: 40px;
    }
    .column-checkbox, .column-name, .column-org, .column-company, .column-city, .column-date, .column-status{
        padding-left: 10px;
        padding-right: 10px;
        &::before{
            //display: none !important;
        }
    }
    .column-checkbox{
        width: 5%;
        &::before{
            display: none !important;
        }
        &:after{
            display: none !important;
        }
    }
    .column-name{
        width: 19%;
        &::before{
            display: none !important;
        }
    }
    .column-org{
        width: 18%;
    }
    .column-company{
        width: 18%;
    }
    .column-city{
        width: 13%;
    }
    .column-date{
        width: 17%;
    }
    .column-status{
        width: 10%;
    }
    .column-close{
        width: 3%;
        text-align: center;
    }
}

.rekrut-vacancies{
    margin-top: 45px;
}
</style>

<style scoped>
.show-list {
    display: block;
}
.show-list .select {
    margin-bottom: 10px;
}
.city-styles {
    word-break: break-word;
}
.pagination {
    margin-top: 20px;
    text-align: center;
}
</style>
