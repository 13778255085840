<template>
	<div :class="editable ? 'panel-shadow' : 'panel'">
		<template v-if="editable">
			<div class="block-title sm">
				{{ isEdit ? 'Редактирование': 'Добавление' }} места работы
			</div>
			<div class="grid">
				<div class="box">
					<div class="field-wrap">
						<select-2
							v-if="isEdit"
							:options="availableYearStart"
							:label="'Год начала работы:'"
							:value="valueYearStart"
							@selected="selectYear($event, 'commencement_date')"
						/>
						<select-2
							v-else
							:options="availableYearStart"
							:label="'Год начала работы:'"
							:value="valueYearStart"
							:error="$v.WP.commencement_date.$dirty && !($v.WP.commencement_date.required && $v.WP.commencement_date.WPYear) ? 'Это обязательное поле' : ''"
							@selected="selectYear($event, 'commencement_date')"
						/>
					</div>
				</div>
				<div class="box">
					<div class="field-wrap">
						<select-2
							v-if="isEdit"
							:options="availableMonthsStart"
							:label="'Месяц начала работы:'"
							:value="valueMonthStart"
							@selected="selectMonth($event, 'commencement_date')"
						/>
						<select-2
							v-else
							:options="availableMonthsStart"
							:label="'Месяц начала работы:'"
							:value="valueMonthStart"
							:error="$v.WP.commencement_date.$dirty && !($v.WP.commencement_date.required && $v.WP.commencement_date.WPMonth) ? 'Это обязательное поле' : ''"
							@selected="selectMonth($event, 'commencement_date')"
						/>
					</div>
				</div>
				<div class="box">
					<div class="field-wrap">
						<label class="checkbox-filter">
							<input
								v-model="WP.to_present"
								type="checkbox"
								@change="changePresent"
							>
							<span>Продолжаю работать</span>
						</label>
					</div>
				</div>
				<template v-if="!WP.to_present">
					<div class="box">
						<div class="field-wrap">
							<select-2
								v-if="isEdit"
								:options="availableYearEnd"
								:label="'Год окончания работы:'"
								:value="valueYearEnd"
								@selected="selectYear($event, 'completion_date')"
							/>
							<select-2
								v-else
								:options="availableYearEnd"
								:label="'Год окончания работы:'"
								:value="valueYearEnd"
								:error="$v.WP.completion_date.$dirty && !($v.WP.completion_date.required && $v.WP.completion_date.WPYear) ? 'Это обязательное поле' : ''"
								@selected="selectYear($event, 'completion_date')"
							/>
						</div>
					</div>
					<div class="box">
						<div class="field-wrap">
							<select-2
								v-if="isEdit"
								:options="availableMonthsEnd"
								:label="'Месяц окончания работы:'"
								:value="valueMonthEnd"
								@selected="selectMonth($event, 'completion_date')"
							/>
							<select-2
								v-else
								:options="availableMonthsEnd"
								:label="'Месяц окончания работы:'"
								:value="valueMonthEnd"
								:error="$v.WP.completion_date.$dirty && !($v.WP.completion_date.required && $v.WP.completion_date.WPMonth) ? 'Это обязательное поле' : ''"
								@selected="selectMonth($event, 'completion_date')"
							/>
						</div>
					</div>
					<div class="box" />
				</template>
				<div class="box">
					<div class="field-wrap">
						<label class="field-label">
							<input
								v-model.trim="WP.organization"
								type="text"
								class="field"
								placeholder="Организация:"
							>
							<div class="label">Организация:</div>
							<span
								v-if="$v.WP.organization.$error"
								class="help-block form-error"
							>Это обязательное поле</span>
						</label>
					</div>
				</div>
				<div class="box">
					<div class="field-wrap">
						<label class="field-label">
							<input
								v-model.trim="WP.city"
								type="text"
								class="field"
								placeholder="Город:"
							>
							<div class="label">Город:</div>
							<span
								v-if="$v.WP.city.$error"
								class="help-block form-error"
							>Это обязательное поле</span>
						</label>
					</div>
				</div>
				<div class="box">
					<div class="field-wrap">
						<label class="field-label">
							<input
								v-model.trim="WP.position"
								type="text"
								class="field"
								placeholder="Должность:"
							>
							<div class="label">Должность:</div>
							<span
								v-if="$v.WP.position.$error"
								class="help-block form-error"
							>Это обязательное поле</span>
						</label>
					</div>
				</div>
				<div class="box full">
					<div class="field-wrap">
						<label class="field-label">
							<textarea
								v-model="WP.duties_at_work"
								rows="3"
								class="field"
								placeholder="Обязанности на рабочем месте:"
							/>
							<div class="label">Обязанности на рабочем месте:</div>
							<span
								v-if="$v.WP.duties_at_work.$error"
								class="help-block form-error"
							>
								{{ !$v.WP.duties_at_work.required ? 'Это обязательное поле' :
									!$v.WP.duties_at_work.minLength && `Не менее ${$v.WP.duties_at_work.$params.minLength.min} символов` }}
							</span>
						</label>
					</div>
				</div>
			</div>
			<button
				class="button"
				@click="save"
				v-text="textButtonSave"
			/>
			<button
				class="button-hollow"
				style="margin-left: 20px;"
				@click="cancel"
			>
				Отменить
			</button>
		</template>
		<template v-else>
			<div
				v-if="!isWatch"
				class="panel-remove"
				@click="remove"
			/>
			<div class="cells">
				<div class="cell">
					<div class="text dark sm">
						<p>{{ wpData.organization }}</p>
						<small>{{ wpData.commencement_date | dateForViewFormat }} - {{ wpData.to_present ? 'По настоящее время' : wpData.completion_date | dateForViewFormat }}</small>
						<p>г. {{ wpData.city }}</p>
					</div>
				</div>
				<div class="cell">
					<div class="text dark sm">
						<p>{{ wpData.position }}</p>
						<small class="panel-text--white-pre">{{ wpData.duties_at_work }}</small>
					</div>
				</div>
			</div>
			<br>
			<button
				v-if="!isWatch"
				class="button-control"
				@click="edit"
			>
				<img
					:src="'/img/icons/icon-edit.png' | prodSrcImage"
					alt="Редактировать место работы"
				>
				Редактировать
			</button>
		</template>
	</div>
</template>

<script>

import { required, minLength } from 'vuelidate/lib/validators'
import { WPYear, WPMonth } from '@/assets/scripts/custom_validators'

const MONTHS = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
]

export default {
    filters: {
        dateForViewFormat(val) {
            if (val) {
                let [year, month, day] = val.includes('.') ? val.split('.').reverse() : val.split('-')

                if (day) {
                    return `${MONTHS[parseInt(month) - 1]} ${year} г.`
                } else if (month) {
                    return `${MONTHS[month]} ${year} г.`
                } else {
                    return val
                }
            } else {
                return ''
            }
        },
        WPDateForInnerFormat(val, slug = null) {
            if (slug === 'Y') {
                return parseInt(val.split('-')[0])
            } else if (slug === 'M') {
                return parseInt(val.split('-')[1]) - 1
            } else {
                return `${parseInt(val.split('-')[1]) - 1}.${parseInt(val.split('-')[0])}`
            }
        },
        WPDateForServerFormat(val) {
            let date = val.split('.')

            return `${date[1]}-${parseInt(date[0]) + 1}-01`
        }
    },
    props: {
        wpData: {
            type: Object,
            default: () => new Object()
        },
        isAdd: {
            type: Boolean,
            default: false
        },
        isWatch: {
            type: Boolean,
            default: false
        }
    },
    validations() {
        const WP = {
            commencement_date: {
                required,
                WPYear,
                WPMonth
            },
            organization: {
                required
            },
            city: {
                required
            },
            position: {
                required
            },
            duties_at_work: {
                required,
                minLength: minLength(10)
            }
        }

        if (!this.WP.to_present) {
            WP.completion_date = {
                required,
                WPYear,
                WPMonth
            }
        }

        return {
            WP
        }
    },
    data: () => ({
        isEdit: false,
        WP: new Object(),
        options: {
            years: [],
            months: MONTHS
        }
    }),
    computed: {
        editable() {
            return this.isEdit || this.isAdd
        },
        textButtonSave() {
            if (this.isEdit) {
                return 'Сохранить'
            } else if (this.isAdd) {
                return 'Добавить'
            } else {
                return ''
            }
        },
        availableYearStart() {
            if (this.WP.to_present) {
                return this.options.years
            } else {
                let yearEnd = this.WP.completion_date.split('.')[1]

                if (yearEnd) return this.options.years.slice(0, this.options.years.findIndex(x => x == yearEnd) + 1)
                else return this.options.years
            }
        },
        availableYearEnd() {
            let yearStart = this.WP.commencement_date.split('.')[1]

            if (yearStart) return this.options.years.slice(this.options.years.findIndex(x => x == yearStart))
            else return this.options.years
        },
        availableMonthsStart() {
            if (this.WP.to_present) {
                return MONTHS
            } else {
                let yearStart = this.WP.commencement_date.split('.')[1],
                    yearEnd = this.WP.completion_date.split('.')[1],
                    monthEnd = this.WP.completion_date.split('.')[0];

                if (yearStart) {
                    if (+yearStart < new Date().getFullYear()) {
                        if (yearStart < yearEnd) return MONTHS
                        // yearStart == yearEnd
                        else {
                            if (monthEnd) return MONTHS.slice(0, parseInt(monthEnd) + 1)
                            else return MONTHS
                        }
                    }
                    else return MONTHS.slice(0, new Date().getMonth() + 1)
                } else {
                    return MONTHS
                }
            }
        },
        availableMonthsEnd() {
            let yearEnd = this.WP.completion_date.split('.')[1],
                yearStart = this.WP.commencement_date.split('.')[1],
                monthStart = this.WP.commencement_date.split('.')[0];

            if (yearEnd) {
                if (+yearEnd < new Date().getFullYear()) {
                    if (yearStart < yearEnd) return MONTHS
                    // yearStart == yearEnd
                    else {
                        if (monthStart) return MONTHS.slice(parseInt(monthStart))
                        else return MONTHS
                    }
                }
                else return MONTHS.slice(0, new Date().getMonth() + 1)
            } else {
                return MONTHS
            }
        },
        valueYearStart() {
            let result = this.WP.commencement_date.split('.')[1]

            if (result) return +result
            else return ''
        },
        valueYearEnd() {
            let result = this.WP.completion_date.split('.')[1]
            if (result) return +result
            else return ''
        },
        valueMonthStart() {
            return this.options.months[this.WP.commencement_date.split('.')[0]]
        },
        valueMonthEnd() {
            return this.options.months[this.WP.completion_date.split('.')[0]]
        }
    },
    created() {
        let start = 1980

        if (this.wpData && this.wpData.organization) {
            this.WP = JSON.parse(JSON.stringify(this.wpData))
        } else {
            this.reset()
        }

        while (start <= new Date().getFullYear()) {
            this.options.years.push(start++)
        }
    },
    methods: {
        edit() {
            this.isEdit = true
            this.$emit('edit')
        },
        save() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                console.log('Error in Work Place panel')
            } else {
                if (this.isEdit) {
                    this.$emit('saved', this.WP)
                    this.isEdit = false
                } else if (this.isAdd) {
                    this.$emit('added', this.WP)
                    this.reset()
                }
            }
        },
        remove() {
            this.$emit('remove')
        },
        reset() {
            this.WP = {
                commencement_date: '',
                completion_date: '',
                to_present: false,
                city: '',
                organization: '',
                position: '',
                duties_at_work: ''
            }
            this.$v.$reset()
        },
        cancel() {
            this.$emit('cancel')
            this.isEdit = false
        },
        selectYear(val, slug) {
            if (this.WP[slug]) {
                this.WP[slug] = `${this.WP[slug].split('.')[0]}.${val}`
            } else {
                this.WP[slug] = `.${val}`
            }
        },
        selectMonth(val, slug) {
            if (this.WP[slug]) {
                this.WP[slug] = `${MONTHS.findIndex(x => x === val)}.${this.WP[slug].split('.')[1]}`
            } else {
                this.WP[slug] = `${MONTHS.findIndex(x => x === val)}.`
            }
        },
        changePresent(event) {
            if (event.target.checked) this.WP.to_present = true
            else {
                this.WP.to_present = false
                this.WP.completion_date = ''
            }
        },
        selectCity(val) {
            this.WP.city = val
        }
    }
}
</script>
