<template>
	<section
		v-wow
		class="section wow fadeIn"
		data-wow-delay="0.2s"
	>
		<div class="prizes-progress">
			<div class="content">
				<PrizesProgress :board="board" />
				<!--				<Material-->
				<!--					v-if="fileUpload"-->
				<!--					:materials-info="materialsInfo"-->
				<!--					is-profile-->
				<!--				/>-->
			</div>
		</div>
		<div class="prizes">
			<div class="content">
				<h2 class="title-section">
					Призы
				</h2>
				<div class="prizes__list">
					<PrizesOnline
						:online-prizes="onlinePrizes"
					/>
					<PrizesBlock
						v-for="(item, index) in prizes"
						:key="index"
						:prize="item"
					/>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import PrizesProgress from '@/pages/cabinet/user/PrizesProgress';
import {mapActions, mapGetters} from 'vuex';
import PrizesBlock from '@/pages/cabinet/user/PrizesBlock';
import PrizesOnline from '@/pages/cabinet/user/PrizesOnline';

export default {
    name: 'Prizes',
    components: {
        PrizesProgress,
        PrizesOnline,
        PrizesBlock
    },
    data: () => ({
        materialsInfo: {
            file_upload_title: 'Подтверждение оценок',
            file_upload_description: localStorage.getItem('userRole') === 'Student' ? 'Загрузи на облако или отправь фотографии страниц своей зачетки — первую, с твоим именем и учебным заведением, и те, которые соответствуют последней пройденной сессии. Мы проверим их в ближайшее время и зачтем тебе результат. Проверь, чтобы в зачетке были заполнены все поля и поставлены все печати': 'Сделай копию страницы электронного журнала успеваемости в школе, где видны оценки за последнюю четверть или триместр. Загрузи картинку, где видно твое имя и оценки (без троек) в поле справа. Мы проверим их и в ближайшее время, зачтем тебе этот результат.'
        }
    }),
    computed: {
        ...mapGetters('scholar', {
            board: 'getBoard',
            prizes: 'getPrizes',
            onlinePrizes: 'getOnlinePrizes',
            fileUpload: 'getFileUpload',
        })
    },
    created() {
        this.getBoard('board')
        this.getPrizes('prize')
        this.getOnlinePrizes('prize_online')
    },
    methods: {
        ...mapActions('scholar', {
            getBoard: 'getBoard',
            getPrizes: 'getPrizes',
            getOnlinePrizes: 'getOnlinePrizes'
        })
    }
}
</script>
