<template>
	<div class="content">
		<div
			v-if="isLoading"
			class="preloader"
		/>
		<div
			v-else
		>
			<div class="profile-block">
				<div class="form">
					<div class="profile-section">
						<div class="profile-info">
							<div class="grid">
								<div class="box">
									<div class="profile-avatar-block">
										<h3 class="block-title">
											Основная информация
										</h3>
										<div class="profile-avatar-wrap">
											<div class="profile-avatar-view">
												<img
													:src="srcAvatar"
													alt="Аватар пользователя"
													class="profile-avatar-pic"
												>
											</div>
										</div>
									</div>
								</div>
								<div class="box">
									<div class="profile-section">
										<div class="profile-fields">
											<h3 class="block-title">
												Основная информация
											</h3>
											<div class="cells">
												<div class="cell">
													<div class="field-view-wrap">
														<div class="field-view-label">
															Фамилия:
														</div>
														<div class="field-view-value">
															{{ userInfo.last_name }}
														</div>
													</div>
												</div>
												<div class="cell">
													<div class="field-view-wrap">
														<div class="field-view-label">
															Имя:
														</div>
														<div class="field-view-value">
															{{ userInfo.first_name }}
														</div>
													</div>
												</div>
												<div class="cell">
													<div class="field-view-wrap">
														<div class="field-view-label">
															Отчество:
														</div>
														<div class="field-view-value">
															{{ userInfo.middle_name }}
														</div>
													</div>
												</div>
												<div class="cell">
													<div class="field-view-wrap">
														<div class="field-view-label">
															Дата рождения:
														</div>
														<div class="field-view-value">
															{{ userInfo.birthday }}
														</div>
													</div>
												</div>
												<div class="cell">
													<div class="field-view-wrap">
														<div class="field-view-label">
															Телефон:
														</div>
														<div class="field-view-value">
															{{ userInfo.phone }}
														</div>
													</div>
												</div>
												<div class="cell">
													<div class="field-view-wrap">
														<div class="field-view-label">
															Email:
														</div>
														<div class="field-view-value">
															{{ userInfo.email }}
														</div>
													</div>
												</div>
												<div class="cell">
													<div class="field-view-wrap">
														<div class="field-view-label">
															Регион:
														</div>
														<div class="field-view-value">
															{{ userInfo.region }}
														</div>
													</div>
												</div>
												<div class="cell">
													<div class="field-view-wrap">
														<div class="field-view-label">
															Город:
														</div>
														<div class="field-view-value">
															{{ userInfo.city }}
														</div>
													</div>
												</div>
												<div class="cell full">
													<div class="field-view-wrap">
														<div
															class="field-view-label"
															style="font-weight: 700"
														>
															О себе:
														</div>
														<div class="field-view-value">
															{{ userInfo.about_me }}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						v-if="studyPlaces.length"
						class="profile-section"
					>
						<h3 class="block-title">
							Образование
						</h3>
						<template>
							<user-study-place
								v-for="(item, index) in studyPlaces"
								:key="index"
								is-watch
								:sp-data="item"
							/>
						</template>
					</div>
					<div
						v-if="workPlaces.length"
						class="profile-section"
					>
						<h3 class="block-title">
							Опыт работы
						</h3>
						<template>
							<user-work-place
								v-for="item in workPlaces"
								:key="item.id"
								is-watch
								:wp-data="item"
							/>
						</template>
					</div>
					<div
						v-if="userInfo.employment_type.length"
						class="profile-section"
					>
						<h3 class="block-title">
							Предпочитаемые типы занятости
						</h3>
						<div class="field-view-value">
							<span
								v-for="(item, key) in employmentsType"
								:key="key"
							>
								<span
									v-if="employmentsType.length === 1"
								>{{ item.value }}</span>
								<span v-else>
									<span
										v-if="employmentsType[0] === item"
									>{{ item.value }},</span>
									<span
										v-else-if=" employmentsType.at(-1) === item"
										style="text-transform: lowercase"
									> {{ item.value }}</span>
									<span
										v-else
										style="text-transform: lowercase"
									> {{ item.value }},</span>
								</span>
							</span>
						</div>
					</div>
					<div class="profile-buttons">
						<div class="grid">
							<div class="box" />
							<div
								class="box"
							>
								<button
									class="button-control"
									@click="downloadProfile(userInfo.id)"
								>
									<a
										ref="link"
										style="display: none;"
									/>
									<div
										v-if="isDownloaded || isLoading"
										class="preloader xs"
									/>
									<div v-else>
										<img
											:src="'/img/icons/icon-pdf-2.svg' | prodSrcImage"
											alt="Скачать профиль"
										>
										<span>Скачать профиль</span>
									</div>
								</button>
							</div>
						</div>
					</div>
				</div>
				<div class="profile-block-footer">
					<div class="grid">
						<div
							v-if="careerUrl"
							class="box"
						>
							<h3 class="title">
								Результаты профориентационного теста
							</h3>
							<a
								href="#"
								class="button blue"
								@click.stop.prevent="modalOpen('payload', careerUrl)"
							>Смотреть</a>
						</div>
						<div class="box" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import UserWorkPlace from '@/pages/cabinet/user/UserWorkPlace';
import UserStudyPlace from '@/pages/cabinet/user/UserStudyPlace';
import User from '@/api/user';

const MONTHS = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
]

export default {
    name: 'UserInfo',
    components: {
        'user-work-place': UserWorkPlace,
        'user-study-place': UserStudyPlace
    },
    filters: {
        WPDateForInnerFormat(val, slug = null) {
            if (slug === 'Y') {
                return parseInt(val.split('-')[0])
            } else if (slug === 'M') {
                return parseInt(val.split('-')[1]) - 1
            } else {
                return `${parseInt(val.split('-')[1]) - 1}.${parseInt(val.split('-')[0])}`
            }
        }
    },
    data: () => ({
        isLoading: true,
        isDownloaded: false,
        userInfo: {
            id: '',
            first_name: '',
            last_name: '',
            middle_name: '',
            phone: '',
            city: '',
            email: '',
            birthday: '',
            region: '',
            about_me: '',
            employment_type: []
        },
        avatar: {
            dft: '/img/avatar.png',
            crnt: '',
            slct: ''
        },
        specialization: {
            preferred_position: '',
            salary: '',
            type_of_employment: ''
        },
        workPlaces: [],
        studyPlaces: [],
        userPDF: null,
        options: {
            years: new Array(),
            months: MONTHS
        },
    }),
    computed: {
        ...mapGetters('user', {
            user: 'watchUserInfo',
            isRecruiter: 'isRecruiter'
        }),
        ...mapState('user', [
            'watchUser'
        ]),
        ...mapGetters('cnst', {
            userEmployments: 'getUserEmployments'
        }),
        srcAvatar() {
            if (this.avatar.slct) return this.avatar.slct
            else if (this.avatar.crnt) return this.avatar.crnt
            else return IS_DEV ? this.avatar.dft : `/staticfiles${this.avatar.dft}`
        },
        careerUrl() {
            return this.userInfo.career_guidance
        },
        employmentsType() {
            const types = []
            this.userEmployments.forEach( item => {
                this.userInfo.employment_type.forEach( subItem => {
                    if (item.slug === subItem) types.push(item)
                })
            })
            return types.sort((a, b) => a.priority - b.priority)
        }
    },
    created() {
        this.getUserData()
        let start = 1980;
        while (start <= new Date().getFullYear()) {
            this.options.years.push(start++)
        }
    },
    methods: {
        ...mapActions('user', {
            getUserInfo: 'detailWatchUser',
            download: 'downloadProfile',
            getStudyPlace: 'getStudyPlaceAnotherUser',
            getWorkPlace: 'getWorkPlaceAnotherUser'
        }),
        ...mapActions('searchUsers', {
            sendTurbo: 'sendTurbo'
        }),
        getUserData() {
            if (this.isRecruiter) {
                this.sendTurbo(this.$route.params.id)
            }
            this.getUserInfo(this.$route.params.id)
                .then(user => {
                    let [year, month, day] = user.birthday ? user.birthday.split('-') : ['', '', '']
                    this.userInfo.id = user.id
                    this.userInfo.first_name = user.first_name
                    this.userInfo.last_name = user.last_name
                    this.userInfo.middle_name = user.middle_name
                    this.userInfo.phone = user.phone
                    this.userInfo.region = user.region
                    this.userInfo.city = user.city ? user.city.name : ''
                    this.userInfo.email = user.email
                    this.userInfo.birthday = `${day}.${month}.${year}`
                    this.userInfo.career_guidance = user.career_guidance
                    this.userInfo.about_me = user.about_me
                    this.userInfo.employment_type = user.employment_type
                    this.avatar.crnt = user.image_user.length ? user.image_user[0].image : '';
                    this.idAvatar = user.image_user.length && user.image_user[0].id;
                    this.isLoading = false
                })
                .then(() => {
                    this.getStudyPlace(this.userInfo.id).then(res => {
                        this.studyPlaces = res
                    })
                    this.getWorkPlace(this.userInfo.id).then(res => {
                        this.workPlaces = res && res.length && res.map(x => ({
                            ...x,
                            commencement_date: this.$options.filters.WPDateForInnerFormat(x.commencement_date),
                            completion_date: x.to_present ? null : this.$options.filters.WPDateForInnerFormat(x.completion_date)
                        })) || []
                    })
                })
        },
        downloadProfile(id) {
            this.isDownloaded = true
            User.downloadProfile(id)
                .then(response => {
                    if (response.status === 204) this.downloadProfile(id)
                    else {
                        window.open(response.data.download_link, '_blank')
                        this.isDownloaded = false
                    }
                })
        },
    }
}
</script>

<style lang="less" scoped>
.block-title {
    margin-bottom: 30px;
    font-family: 'Merriweather', serif;
    font-size: 27px;
    font-weight: 700;
}
.field-view-wrap {
    margin-bottom: 35px;
}
.content {
    position: relative;
    width: 100%;
    max-width: 1080px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 30px;
}
.profile-avatar-pic {
    height: 100%;
    object-fit: cover;
}
</style>
