<template>
	<div class="content">
		<router-link
			:to="`/profile/${$route.params.id}?resumes=true`"
			class="button-hollow icon-hover"
		>
			<span class="icon icon-back" />
			К списку резюме
		</router-link>

		<div class="view-resume-block">
			<div class="view-resume-section">
				<div class="block-title">
					Основная информация
				</div>
				<div class="profile-avatar-wrap">
					<div class="profile-avatar-view">
						<img
							:src="srcAvatar"
							alt="Аватар пользователя"
							class="profile-avatar-pic picture-resume"
						>
					</div>
				</div>
				<div class="grid">
					<div class="box">
						<div class="field-view-wrap">
							<div class="field-view-label">
								Фамилия:
							</div>
							<div class="field-view-value">
								{{ userResume.last_name }}
							</div>
						</div>
					</div>
					<div class="box">
						<div class="field-view-wrap">
							<div class="field-view-label">
								Имя:
							</div>
							<div class="field-view-value">
								{{ userResume.first_name }}
							</div>
						</div>
					</div>
					<div class="box">
						<div class="field-view-wrap">
							<div class="field-view-label">
								Отчество:
							</div>
							<div class="field-view-value">
								{{ userResume.middle_name }}
							</div>
						</div>
					</div>
					<div class="box">
						<div class="field-view-wrap">
							<div class="field-view-label">
								Дата рождения:
							</div>
							<div class="field-view-value">
								{{ userResume.birthday }}
							</div>
						</div>
					</div>
					<div class="box">
						<div class="field-view-wrap">
							<div class="field-view-label">
								Гражданство:
							</div>
							<div class="field-view-value">
								{{ userResume.nationality }}
							</div>
						</div>
					</div>
					<div class="box">
						<div class="field-view-wrap">
							<div class="field-view-label">
								Телефон:
							</div>
							<div class="field-view-value">
								{{ userResume.phone }}
							</div>
						</div>
					</div>
					<div class="box">
						<div class="field-view-wrap">
							<div class="field-view-label">
								Email:
							</div>
							<div class="field-view-value">
								{{ userResume.email }}
							</div>
						</div>
					</div>
					<div class="box">
						<div class="field-view-wrap">
							<div class="field-view-label">
								Город:
							</div>
							<div class="field-view-value">
								{{ userResume.city }}
							</div>
						</div>
					</div>
					<div class="box full">
						<div class="field-view-wrap">
							<div class="field-view-label">
								О себе:
							</div>
							<div class="field-view-value">
								{{ userResume.about_me }}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="view-resume-section">
				<div class="block-title">
					Специальность
				</div>
				<div class="grid">
					<div class="box">
						<div class="field-view-wrap">
							<div class="field-view-label">
								Желаемая должность:
							</div>
							<div class="field-view-value">
								{{ specialization.preferred_position }}
							</div>
						</div>
					</div>
					<div class="box">
						<div class="field-view-wrap">
							<div class="field-view-label">
								Зарплата:
							</div>
							<div class="field-view-value">
								{{ specialization.salary }}
							</div>
						</div>
					</div>
					<div class="box">
						<div class="field-view-wrap">
							<div class="field-view-label">
								Тип занятости:
							</div>
							<div class="field-view-value">
								{{ specialization.type_of_employment ? employments.find(x => x.slug === specialization.type_of_employment).value : '' }}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="view-resume-section">
				<div class="block-title">
					Опыт работы
				</div>
				<template v-if="workPlaces">
					<work-place
						v-for="item in workPlaces"
						:key="item.id"
						is-watch
						:wp-data="item"
					/>
				</template>
				<div
					v-else
					class="text dark sm"
				>
					<small>Не указано</small>
				</div>
			</div>

			<div class="view-resume-section">
				<div class="block-title">
					Образование
				</div>
				<template v-if="studyPlaces">
					<study-place
						v-for="item in studyPlaces"
						:key="item.id"
						is-watch
						:sp-data="item"
					/>
				</template>
				<div
					v-else
					class="text dark sm"
				>
					<small>Не указано</small>
				</div>
			</div>
		</div>
		<div class="profile-buttons">
			<div class="grid">
				<div class="box" />
				<div class="box">
					<button
						class="button-control"
						@click="downloadResume(userResume.resumeID)"
					>
						<a
							ref="link"
							style="display: none;"
						/>
						<div
							v-if="isDownloaded"
							class="preloader xs"
						/>
						<div v-else>
							<img
								:src="'/img/icons/icon-pdf-2.svg' | prodSrcImage"
								alt="Скачать резюме"
							>
							<span>Скачать резюме</span>
						</div>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

import WorkPlace from '@/components/panels/WorkPlace'
import StudyPlace from '@/components/panels/StudyPlace'
import User from '@/api/user';

export default {
    name: 'WatchUserResume',
    components: {
        'work-place': WorkPlace,
        'study-place': StudyPlace
    },
    filters: {
        WPDateForInnerFormat(val, slug = null) {
            if (slug === 'Y') {
                return parseInt(val.split('-')[0])
            } else if (slug === 'M') {
                return parseInt(val.split('-')[1]) - 1
            } else {
                return `${parseInt(val.split('-')[1]) - 1}.${parseInt(val.split('-')[0])}`
            }
        }
    },
    data: () => ({
        isDownloaded: false,
        isLoading: true,
        avatar: {
            id: null,
            dft: '/img/avatar.png',
            crnt: '',
            slct: '',
            isRemoved: false
        },
        userResume: {
            user: '',
            last_name: '',
            first_name: '',
            middle_name: '',
            birthday: '',
            phone: '',
            email: '',
            nationality: 'Российская Федерация',
            about_me: ''
        },
        specialization: {
            preferred_position: '',
            salary: '',
            type_of_employment: ''
        },
        studyPlaces: undefined,
        workPlaces: undefined
    }),
    computed: {
        ...mapGetters('cnst', {
            employments: 'getEmployments'
        }),
        ...mapGetters('user', {
            isRecruiter: 'isRecruiter'
        }),
        srcAvatar() {
            if (this.avatar.slct) return this.avatar.slct
            else if (this.avatar.crnt) return this.avatar.crnt
            else return IS_DEV ? this.avatar.dft : `/staticfiles${this.avatar.dft}`
        },
    },
    created() {
        this.getUserData()
        this.initResumeEWData()
    },
    methods: {
        ...mapActions('user', {
            getUserInfo: 'detailWatchUser',
            getStudyPlace: 'getStudyPlaceResume',
            getWorkPlace: 'getWorkPlaceResume',
        }),
        ...mapActions('tb', {
            getResume: 'getResume',
        }),
        getUserData() {
            this.getUserInfo(this.$route.params.id)
                .then(user => {
                    let [year, month, day] = user.birthday ? user.birthday.split('-') : ['', '', '']
                    this.userResume.id = user.id
                    this.userResume.first_name = user.first_name
                    this.userResume.last_name = user.last_name
                    this.userResume.middle_name = user.middle_name
                    this.userResume.region = user.region
                    this.userResume.birthday = `${day}.${month}.${year}`
                    this.isLoading = false
                })
            this.getStudyPlace(this.$route.query.resumeId).then(res => {
                this.studyPlaces = res
            })
            if (this.$route.query.resumeId) {
                this.getWorkPlace(this.$route.query.resumeId).then(res => {
                    this.workPlaces = res && res.length && res.map(x => ({
                        ...x,
                        commencement_date: this.$options.filters.WPDateForInnerFormat(x.commencement_date),
                        completion_date: x.to_present ? null : this.$options.filters.WPDateForInnerFormat(x.completion_date)
                    })) || []
                })
            }
        },
        initResumeEWData() {
            this.getResume({
                id: this.$route.query.resumeId,
                uid: this.$route.params.id})
                .then(res => {
                    let resume = res
                    this.userResume.resumeID = resume.id
                    this.userResume.phone = resume.phone ? resume.phone : resume.user.phone
                    this.userResume.email = resume.email ? resume.email : resume.user.email
                    this.userResume.city = resume.city ? resume.city : resume.user.city ? resume.user.city.name : ''
                    this.avatar.crnt = resume.candidate_photo ? resume.candidate_photo : resume.candidate_photo_url
                    this.userResume.about_me = resume.about_me
                    this.userResume.nationality = resume.nationality
                    this.specialization.preferred_position = resume.preferred_position
                    this.specialization.salary = resume.salary
                    this.specialization.type_of_employment = resume.type_of_employment
                    this.isLoading = false
                })
        },
        downloadResume(id) {
            this.isDownloaded = true
            User.downloadResume(id)
                .then(response => {
                    if (response.status === 204) this.downloadResume(id)
                    else {
                        window.open(response.data.download_link, '_blank')
                        this.isDownloaded = false
                    }
                })
        },
    }
}
</script>

<style lang="less">
.picture-resume {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>
