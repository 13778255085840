<template>
	<section class="section simple">
		<div class="content">
			<base-button
				class="button"
				orange
				@clickButton="addKey"
			>
				Добавить ключ
			</base-button>

			<div class="data-access__bar">
				<div class="grid">
					<div class="box">
						<div class="data-access__amount">
							Всего ключей: <span>{{ count }}</span>
						</div>
					</div>
					<div class="box">
						<div class="data-access__select">
							Показывать на странице:
							<c-select
								user-style
								:options="accessOptions"
								:default-data="showAccessPage"
								@selected="selectPagination"
							/>
						</div>
					</div>
				</div>
			</div>

			<div class="data-access__table">
				<div class="data-access__header">
					<div class="data-access__row">
						<div class="data-access__cell">
							ID
							<div
								class="data-access__sorting"
								@click="filtersKey()"
							/>
						</div>
						<div class="data-access__cell">
							Ключ API
						</div>
						<div class="data-access__cell">
							Секрет API
						</div>
					</div>
				</div>
				<div class="data-access__body">
					<data-access-item
						v-for="key in keys"
						:key="key.id"
						:item-key="key"
						@remove="setId"
					/>
				</div>
			</div>
			<PagePagination
				v-if="pagination.ttl > 1"
				:pagination="pagination"
				:pages="pages"
				:is-show-total="isShowTotal"
				:is-show-next="isShowNext"
				@changePage="selectPage"
			/>
		</div>
		<DeleteKey
			v-if="isOpenModal"
			@remove="removeKey"
			@close="isOpenModal = !isOpenModal"
		/>
	</section>
</template>

<script>
import BaseButton from '@/components/BaseComponents/BaseButton';
import DeleteKey from '@/components/modal/DeleteKey';
import { mapGetters, mapActions } from 'vuex';
import DataAccessItem from '@/pages/cabinet/recruiter/DataAccessItem';
import PagePagination from '@/components/PagePagination';
export default {
	name: 'DataAccess',
	components: {
		BaseButton,
		DataAccessItem,
		PagePagination,
		DeleteKey
	},
	data: () => ({
		showAccessPage: '20',
		isReverse: false,
		pageNumber: 1,
		pagination: {
			init: 0,
			crnt: 1,
			ttl: undefined
		},
		idKey: null,
		isOpenModal: false
	}),
	computed: {
		...mapGetters('cnst', {
			accessOptions: 'getAccessPages'
		}),
		...mapGetters('access', {
			keys: 'getKeys',
			count: 'getCount'
		}),
		isShowTotal() {
			if (this.count)
				return Math.ceil(this.count / this.showAccessPage) > 3;
			return false;
		},
		isShowNext() {
			return (
				this.pagination.init !== this.pagination.ttl - 4 &&
				this.isShowTotal
			);
		},
		pages() {
			let counter = 1;
			let temp = [];
			let max =
				Math.ceil(this.count / Number(this.showAccessPage)) < 3
					? Math.ceil(this.count / Number(this.showAccessPage))
					: 3;
			while (counter <= max) {
				temp.push(this.pagination.init + counter);
				counter++;
			}
			return temp;
		}
	},

	created() {
		this.getKeys(1).then(() => {
			this.pagination.ttl = Math.ceil(this.count / this.showAccessPage);
		});
	},
	methods: {
		...mapActions('access', [
			'getKeys',
			'createKey',
			'filterKeys',
			'remove'
		]),
		selectPagination(val) {
			this.showAccessPage = val;
			this.pageNumber = 1;
			this.pagination.crnt = 1;
			this.getKeys({
				pageNumber: this.pageNumber,
				pageSize: this.showAccessPage
			});
			this.pagination.ttl = Math.ceil(this.count / this.showAccessPage);
		},
		selectPage(page) {
			this.$scrollTo(document.querySelector('header.header-role'));
			this.pageNumber = page.item;
			this.pagination.crnt = page.item;
			this.getKeys({
				pageNumber: this.pageNumber,
				pageSize: this.showAccessPage
			});
			this.pagination.ttl = Math.ceil(this.count / this.showAccessPage);
		},
		filtersKey() {
			this.isReverse = !this.isReverse;
			this.filterKeys(this.isReverse);
		},
		async addKey() {
			await this.createKey({
				pageNumber: this.pageNumber,
				pageSize: this.showAccessPage
			}).then(() => this.modalOpen('addKeySuc'))
		},
		setId(id) {
			this.idKey = id;
			this.isOpenModal = !this.isOpenModal;
		},
		removeKey() {
			this.isOpenModal = !this.isOpenModal;
			this.remove({
				id: this.idKey,
				isReverse: this.isReverse,
				pageNumber: this.pageNumber,
				pageSize: this.showAccessPage
			}).then(() => {
				this.pagination.ttl = Math.ceil(
					this.count / this.showAccessPage
				)
			});
		}
	}
};
</script>

<style lang="less">
@import "../../../assets/styles/pages/cabinet/recruiter/data-access";
</style>
