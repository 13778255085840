<template>
	<div>
		<section
			v-if="watchUser && $route.params.id"
			class="section section-profile wow"
		>
			<div class="content">
				<Breadcrumb
					:page-name="
						watchUserInfoLastName +
							' ' +
							watchUserInfoFirstName +
							' ' +
							watchUserInfoMiddleName
					"
					:additional-pages="[
						{
							name: 'Поиск по пользователям',
							link: '/search-users'
						}
					]"
				/>
				<h3
					class="page-title"
				>
					{{ watchUserInfoLastName }} {{ watchUserInfoFirstName }}
					{{ watchUserInfoMiddleName }}
				</h3>

				<div class="profile-navigation">
					<div class="profile-navigation__list">
						<router-link
							:to="`/profile/${$route.params.id}`"
							class="profile-navigation__link"
							:class="{
								'profile-navigation__link--active': cabinetPage === 'Profile'
							}"
						>
							<span class="iconify iconify-user" />
							Профиль
						</router-link>
						<router-link
							:to="
								`/profile/${$route.params.id}?courses=true`
							"
							class="profile-navigation__link"
							:class="{
								'profile-navigation__link--active': cabinetPage === 'Courses'
							}"
						>
							<span class="iconify iconify-graduate" />
							Курсы
						</router-link>
						<router-link
							:to="
								`/profile/${$route.params.id}?program=true`
							"
							class="profile-navigation__link"
							:class="{
								'profile-navigation__link--active': cabinetPage === 'Program'
							}"
						>
							<span class="iconify iconify-list" />
							Программы
						</router-link>
						<router-link
							:to="
								`/profile/${$route.params.id}?certificates=true`
							"
							class="profile-navigation__link"
							:class="{
								'profile-navigation__link--active':
									cabinetPage === 'Certificates'
							}"
						>
							<span class="iconify iconify-certificate" />
							Сертификаты
						</router-link>
						<router-link
							:to="
								`/profile/${$route.params.id}?resumes=true`
							"
							class="profile-navigation__link"
							:class="{
								'profile-navigation__link--active':
									cabinetPage === 'Resumes' ||
									cabinetPage === 'Resume'
							}"
						>
							<span class="iconify iconify-document" />
							Резюме
						</router-link>
					</div>
					<div
						class="select select-navigation profile_nav"
					>
						<c-select
							:options="pagesWatchUser"
							:default-data="pageName"
							@selected="selectPage"
						/>
					</div>
				</div>
			</div>
		</section>
		<section
			v-else-if="isRecruiter && !$route.params.id"
			class="section section-profile wow"
		>
			<div class="content">
				<div v-if="$route.fullPath === '/profile'">
					<Breadcrumb :page-name="'Личный кабинет'" />
				</div>
				<div
					v-if="$route.fullPath === '/profile?companies=true'"
				>
					<Breadcrumb
						:page-name="'Список компаний'"
						:additional-pages="[
							{ name: 'Личный кабинет', link: '/profile' }
						]"
					/>
				</div>
				<div
					v-else-if="
						$route.query.action === 'watch' &&
							$route.query.company
					"
				>
					<Breadcrumb
						:page-name="$route.query.name"
						:additional-pages="[
							{
								name: 'Личный кабинет',
								link: '/profile'
							},
							{
								name: 'Список компаний',
								link: '/profile?companies=true'
							}
						]"
					/>
				</div>
				<div
					v-else-if="
						$route.query.action === 'edit' &&
							$route.query.company
					"
				>
					<Breadcrumb
						:page-name="'Редактирование компании'"
						:additional-pages="[
							{
								name: 'Личный кабинет',
								link: '/profile'
							},
							{
								name: 'Список компаний',
								link: '/profile?companies=true'
							},
							{
								name: $route.query.name,
								link:
									'profile?company=true&action=watch&id=${$route.query.id}'
							}
						]"
					/>
				</div>
				<div
					v-else-if="
						$route.fullPath === '/profile?vacancies=true'
					"
				>
					<Breadcrumb
						:page-name="'Список вакансий'"
						:additional-pages="[
							{ name: 'Личный кабинет', link: '/profile' }
						]"
					/>
				</div>
				<div
					v-else-if="
						$route.fullPath ===
							'/profile?vacancy=true&action=create'
					"
				>
					<Breadcrumb
						:page-name="'Создание вакансии'"
						:additional-pages="[
							{
								name: 'Личный кабинет',
								link: '/profile'
							},
							{
								name: 'Список вакансий',
								link: '/profile?vacancies=true'
							}
						]"
					/>
				</div>
				<div
					v-else-if="
						$route.query.action === 'watch' &&
							$route.query.vacancy
					"
				>
					<Breadcrumb
						:page-name="$route.query.name"
						:additional-pages="[
							{
								name: 'Личный кабинет',
								link: '/profile'
							},
							{
								name: 'Список вакансий',
								link: '/profile?vacancies=true'
							}
						]"
					/>
				</div>
				<div
					v-else-if="
						$route.query.action === 'edit' &&
							$route.query.vacancy
					"
				>
					<Breadcrumb
						:page-name="'Редактирование вакансии'"
						:additional-pages="[
							{
								name: 'Личный кабинет',
								link: '/profile'
							},
							{
								name: 'Список вакансий',
								link: '/profile?vacancies=true'
							},
							{
								name: $route.query.name,
								link:
									'profile?vacancy=true&action=watch&id=${$route.query.id}'
							}
						]"
					/>
				</div>
				<div
					v-else-if="
						$route.fullPath === '/profile?responses=true'
					"
				>
					<Breadcrumb
						:page-name="'Отклики'"
						:additional-pages="[
							{ name: 'Личный кабинет', link: '/profile' }
						]"
					/>
				</div>
				<div
					v-else-if="
						$route.query.action === 'edit' &&
							$route.query.response === 'true'
					"
				>
					<Breadcrumb
						:page-name="$route.query.name"
						:additional-pages="[
							{
								name: 'Личный кабинет',
								link: '/profile'
							},
							{
								name: 'Отклики',
								link: '/profile?responses=true'
							}
						]"
					/>
				</div>
				<div
					v-else-if="
						$route.fullPath === '/profile?favorites=true'
					"
				>
					<Breadcrumb
						:page-name="'Избранные кандидаты'"
						:additional-pages="[
							{ name: 'Личный кабинет', link: '/profile' }
						]"
					/>
				</div>
				<div
					v-else-if="
						$route.fullPath === '/profile?access=true'
					"
				>
					<Breadcrumb
						:page-name="'Доступ к данным'"
						:additional-pages="[
							{ name: 'Личный кабинет', link: '/profile' }
						]"
					/>
				</div>
				<h3
					class="page-title"
				>
					Личный кабинет
				</h3>
				<div class="profile-navigation">
					<div class="profile-navigation__list">
						<router-link
							:to="'/profile'"
							class="profile-navigation__link"
							:class="{
								'profile-navigation__link--active': cabinetPage === 'Profile'
							}"
						>
							<span class="iconify iconify-user" />
							Профиль
						</router-link>
						<router-link
							:to="'/profile?companies=true'"
							class="profile-navigation__link"
							:class="{
								'profile-navigation__link--active':
									cabinetPage === 'Companies' ||
									cabinetPage === 'Company'
							}"
						>
							Компании
						</router-link>
						<router-link
							:to="'/profile?vacancies=true'"
							class="profile-navigation__link"
							:class="{
								'profile-navigation__link--active':
									cabinetPage === 'Vacancies' ||
									cabinetPage === 'Vacancy'
							}"
						>
							<span class="iconify iconify-favorite" />
							Вакансии
						</router-link>
						<router-link
							:to="'/profile?responses=true'"
							class="profile-navigation__link"
							:class="{
								'profile-navigation__link--active':
									cabinetPage === 'Responses' ||
									cabinetPage === 'ReResponse' ||
									cabinetPage === 'UserResponse'
							}"
						>
							<span class="iconify iconify-checked" />
							Отклики
						</router-link>
						<router-link
							:to="'/profile?favorites=true'"
							class="profile-navigation__link"
							:class="{
								'profile-navigation__link--active': cabinetPage === 'Favorites'
							}"
						>
							Избранные кандидаты
						</router-link>
						<router-link
							:to="'/profile?access=true'"
							class="profile-navigation__link"
							:class="{
								'profile-navigation__link--active': cabinetPage === 'DataAccess'
							}"
						>
							Доступ к данным
						</router-link>
					</div>
					<div
						class="select select-navigation profile_nav"
					>
						<select-2
							:options="pagesRecruiter"
							:value="pageName"
							@selected="selectPage"
						/>
					</div>
				</div>
			</div>
		</section>
		<section
			v-else-if="!isRecruiter && !watchUser"
			class="section section-profile wow"
		>
			<div class="content">
				<div
					v-if="
						$route.fullPath ===
							`/profile/${$route.params.id}`
					"
				>
					<Breadcrumb :page-name="'Личный кабинет'" />
				</div>
				<div
					v-if="
						($route.fullPath ===
							`/profile/${$route.params.id}?prizes=true`)
							&& ((user.user_role === 'Student') ||
								(user.user_role === 'College') ||
								(user.user_role === 'Schoolboy'))
					"
				>
					<Breadcrumb
						:page-name="'Статистика'"
						:additional-pages="[
							{
								name: 'Личный кабинет',
								link: `/profile/${$route.params.id}`
							}
						]"
					/>
				</div>
				<div
					v-if="
						$route.fullPath ===
							`/profile/${$route.params.id}?contests=true`
					"
				>
					<Breadcrumb
						:page-name="'Конкурсы'"
						:additional-pages="[
							{
								name: 'Личный кабинет',
								link: `/profile/${$route.params.id}`
							}
						]"
					/>
				</div>
				<div
					v-if="$route.query.contest"
				>
					<Breadcrumb
						:page-name="$route.query.name"
						:additional-pages="[
							{
								name: 'Личный кабинет',
								link: '/profile'
							},
							{
								name: 'Конкурсы',
								link: `/profile/${$route.params.id}?contests=true`
							}
						]"
					/>
				</div>
				<div
					v-if="
						$route.fullPath ===
							`/profile/${$route.params.id}?courses=true`
					"
				>
					<Breadcrumb
						:page-name="'Курсы'"
						:additional-pages="[
							{
								name: 'Личный кабинет',
								link: `/profile/${$route.params.id}`
							}
						]"
					/>
				</div>
				<div
					v-if="
						$route.fullPath ===
							`/profile/${$route.params.id}?program=true`
					"
				>
					<Breadcrumb
						:page-name="'Программы'"
						:additional-pages="[
							{
								name: 'Личный кабинет',
								link: `/profile/${$route.params.id}`
							}
						]"
					/>
				</div>
				<div
					v-else-if="
						$route.fullPath ===
							`/profile/${$route.params.id}?certificates=true`
					"
				>
					<Breadcrumb
						:page-name="'Сертификаты'"
						:additional-pages="[
							{
								name: 'Личный кабинет',
								link: `/profile/${$route.params.id}`
							}
						]"
					/>
				</div>
				<div
					v-else-if="
						$route.fullPath ===
							`/profile/${$route.params.id}?tests=true`
					"
				>
					<Breadcrumb
						:page-name="'Тесты'"
						:additional-pages="[
							{
								name: 'Личный кабинет',
								link: `/profile/${$route.params.id}`
							}
						]"
					/>
				</div>
				<div
					v-else-if="
						$route.fullPath ===
							`/profile/${$route.params.id}?resumes=true`
					"
				>
					<Breadcrumb
						:page-name="'Резюме'"
						:additional-pages="[
							{
								name: 'Личный кабинет',
								link: `/profile/${$route.params.id}`
							}
						]"
					/>
				</div>
				<div
					v-else-if="
						$route.query.action === 'create' &&
							$route.query.resume
					"
				>
					<Breadcrumb
						:page-name="'Создание резюме'"
						:additional-pages="[
							{
								name: 'Личный кабинет',
								link: `/profile/${$route.params.id}`
							},
							{
								name: 'Резюме',
								link: `/profile/${$route.params.id}?resumes=true`
							}
						]"
					/>
				</div>

				<div
					v-else-if="
						$route.query.action === 'edit' &&
							$route.query.resume
					"
				>
					<Breadcrumb
						:page-name="'Редактирование резюме'"
						:additional-pages="[
							{
								name: 'Личный кабинет',
								link: `/profile/${$route.params.id}`
							},
							{
								name: 'Резюме',
								link: `/profile/${$route.params.id}?resumes=true`
							}
						]"
					/>
				</div>
				<div
					v-else-if="
						$route.fullPath ===
							`/profile/${$route.params.id}?responses=true`
					"
				>
					<Breadcrumb
						:page-name="'Отклики'"
						:additional-pages="[
							{
								name: 'Личный кабинет',
								link: `/profile/${$route.params.id}`
							}
						]"
					/>
				</div>
				<div
					v-else-if="
						$route.query.action === 'watch' &&
							$route.query.response === 'true'
					"
				>
					<Breadcrumb
						:page-name="$route.query.name"
						:additional-pages="[
							{
								name: 'Личный кабинет',
								link: `/profile/${$route.params.id}`
							},
							{
								name: 'Отклики',
								link: `/profile/${$route.params.id}?responses=true`
							}
						]"
					/>
				</div>
				<div
					v-else-if="
						$route.fullPath ===
							`/profile/${$route.params.id}?favoritesVacancies=true`
					"
				>
					<Breadcrumb
						:page-name="'Избранные вакансии'"
						:additional-pages="[
							{
								name: 'Личный кабинет',
								link: `/profile/${$route.params.id}`
							}
						]"
					/>
				</div>
				<h3
					class="page-title"
				>
					Личный кабинет
				</h3>
				<div class="profile-navigation">
					<div class="profile-navigation__list">
						<router-link
							v-if="(user && user.user_role === 'Student') || (user && user.user_role === 'Schoolboy') || (user && user.user_role === 'College')"
							:to="`/profile/${$route.params.id}?prizes=true`"
							class="profile-navigation__link"
							:class="{
								'profile-navigation__link--active': cabinetPage === 'Prizes'
							}"
						>
							<span class="iconify iconify-stats" />
							Статистика
						</router-link>
						<router-link
							:to="`/profile/${$route.params.id}`"
							class="profile-navigation__link"
							:class="{
								'profile-navigation__link--active': cabinetPage === 'Profile'
							}"
						>
							<span class="iconify iconify-user" />
							Профиль
						</router-link>
						<router-link
							:to="
								`/profile/${$route.params.id}?contests=true`
							"
							class="profile-navigation__link"
							:class="{
								'profile-navigation__link--active': cabinetPage === 'Contests' || cabinetPage === 'Contest'
							}"
						>
							<span class="iconify iconify-cup" />
							Конкурсы
						</router-link>
						<router-link
							:to="
								`/profile/${$route.params.id}?courses=true`
							"
							class="profile-navigation__link"
							:class="{
								'profile-navigation__link--active': cabinetPage === 'Courses'
							}"
						>
							<span class="iconify iconify-graduate" />
							Курсы
						</router-link>
						<router-link
							:to="
								`/profile/${$route.params.id}?tests=true`
							"
							class="profile-navigation__link"
							:class="{
								'profile-navigation__link--active':
									cabinetPage === 'Tests'
							}"
						>
							<span class="iconify iconify-test" />
							Тесты
						</router-link>
						<router-link
							:to="
								`/profile/${$route.params.id}?program=true`
							"
							class="profile-navigation__link"
							:class="{
								'profile-navigation__link--active': cabinetPage === 'Programs'
							}"
						>
							<span class="iconify iconify-list" />
							Программы
						</router-link>
						<router-link
							:to="
								`/profile/${$route.params.id}?certificates=true`
							"
							class="profile-navigation__link"
							:class="{
								'profile-navigation__link--active':
									cabinetPage === 'Certificates'
							}"
						>
							<span class="iconify iconify-certificate" />
							Сертификаты
						</router-link>
						<router-link
							:to="
								`/profile/${$route.params.id}?resumes=true`
							"
							class="profile-navigation__link"
							:class="{
								'profile-navigation__link--active':
									cabinetPage === 'Resumes' ||
									cabinetPage === 'Resume'
							}"
						>
							<span class="iconify iconify-document" />
							Резюме
						</router-link>
						<router-link
							:to="
								`/profile/${$route.params.id}?responses=true`
							"
							class="profile-navigation__link"
							:class="{
								'profile-navigation__link--active':
									cabinetPage === 'Responses' ||
									cabinetPage === 'ReResponse' ||
									cabinetPage === 'UserResponse'
							}"
						>
							<span class="iconify iconify-checked" />
							Отклики
						</router-link>
						<router-link
							:to="
								`/profile/${$route.params.id}?favoritesVacancies=true`
							"
							class="profile-navigation__link"
							:class="{
								'profile-navigation__link--active':
									cabinetPage ===
									'FavoritesVacancies'
							}"
						>
							<span class="iconify iconify-favorite" />
							Избранные вакансии
						</router-link>
					</div>
					<div
						class="select select-navigation profile_nav"
					>
						<select-2
							:options="pages"
							:value="pageName"
							@selected="selectPage"
						/>
					</div>
				</div>
			</div>
		</section>
		<component :is="cabinetPage" />
	</div>
</template>

<script>
/* Common */
import Profile from './Profile';
import Responses from './Responses';
/* User */
import Courses from './user/Courses';
import Programs from './user/Programs';
import Resumes from './user/Resumes';
import Certificates from './user/Certificates';
import Tests from './user/Tests';
import Resume from './user/Resume';
import UserResponse from './user/Response';
import FavoritesVacancies from './user/FavoritesVacancies';
import Contests from './user/contests/index.vue';
import Contest from './user/contests/Contest.vue';
/* Recruiter */
import Companies from './recruiter/Companies';
import Company from './recruiter/Company';
import Vacancies from './recruiter/Vacancies';
import Vacancy from './recruiter/Vacancy';
import ReResponse from './recruiter/Response';
import Favorites from './recruiter/Favorites';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import Breadcrumb from '@/components/Breadcrumb';
import DataAccess from '@/pages/cabinet/recruiter/DataAccess';
import Prizes from '@/pages/cabinet/user/Prizes';

export default {
	components: {
		Programs,
		Profile,
		Courses,
		Resumes,
		Certificates,
		Tests,
		Responses,
		Resume,
		Companies,
		Company,
		Vacancies,
		Vacancy,
		UserResponse,
		ReResponse,
		Favorites,
		DataAccess,
		Breadcrumb,
		FavoritesVacancies,
        Prizes,
        Contests,
        Contest
	},
	data: () => ({
		currentPage: Profile,

		pages: localStorage.getItem('userRole') === 'Specialist' || localStorage.getItem('userRole') === 'Teacher' ? [
			'Профиль',
			'Курсы',
			'Тесты',
			'Программы',
			'Сертификаты',
			'Резюме',
			'Отклики',
			'Избранные вакансии'
		] : [
            'Статистика',
            'Профиль',
            'Конкурсы',
            'Курсы',
            'Тесты',
            'Программы',
            'Сертификаты',
            'Резюме',
            'Отклики',
            'Избранные вакансии'
        ],
		pagesRecruiter: [
			'Профиль',
			'Компании',
			'Вакансии',
			'Отклики',
			'Избранные кандидаты',
			'Доступ к данным'
		],
		pagesWatchUser: [
			'Профиль',
			'Курсы',
			'Программы',
			'Сертификаты',
			'Резюме'
		],
		watchUserInfo: null,
		recruiter: false,
		prevRoute:null,
        watchUser: null
	}),
	computed: {
		...mapGetters('user', {
			userID: 'userID',
            isOnboarding: 'isOnboarding',
			pageName: 'pageName',
			isRecruiter: 'isRecruiter'
		}),
		user() {
			return this.$store.state.user.user;
		},
		watchUserInfoLastName() {
			if (this.watchUserInfo && this.watchUserInfo.last_name !== null)
				return this.watchUserInfo && this.watchUserInfo.last_name;
			else return '';
		},
		watchUserInfoFirstName() {
			if (this.watchUserInfo && this.watchUserInfo.first_name !== null)
				return this.watchUserInfo && this.watchUserInfo.first_name;
			else return '';
		},
		watchUserInfoMiddleName() {
			if (this.watchUserInfo && this.watchUserInfo.middle_name !== null)
				return this.watchUserInfo && this.watchUserInfo.middle_name;
			else return '';
		},
		cabinetPage() {
			if (this.$route.query.courses) return 'Courses';
			else if (this.$route.query.prizes) return 'Prizes';
			else if (this.$route.query.contests) return 'Contests';
			else if (this.$route.query.contest) return 'Contest';
			else if (this.$route.query.program) return 'Programs';
			else if (this.$route.query.resumes) return 'Resumes';
			else if (this.$route.query.certificates) return 'Certificates';
			else if (this.$route.query.tests) return 'Tests';
			else if (this.$route.query.responses) return 'Responses';
			else if (this.$route.query.response) {
				if (this.user) {
					if (this.isRecruiter) return 'ReResponse';
					else return 'UserResponse';
				} else {
					return '';
				}
			} else if (this.$route.query.resume) return 'Resume';
			else if (this.$route.query.companies) return 'Companies';
			else if (this.$route.query.company) return 'Company';
			else if (this.$route.query.vacancies) return 'Vacancies';
			else if (this.$route.query.vacancy) return 'Vacancy';
			else if (this.$route.query.favorites) return 'Favorites';
			else if (this.$route.query.access) return 'DataAccess';
			else if (this.$route.query.favoritesVacancies)
				return 'FavoritesVacancies';
			else if (this.$route.query.programs) return 'Programs';
			else return 'Profile';
		}
	},
	watch: {
		prevRoute() {
			this.setPrevRoute(this.prevRoute);
		}
	},
	created() {
		if (
			this.$route.params.id &&
			this.$route.params.id !== JSON.stringify(this.userID)
		) {
			this.detailWatchUser(this.$route.params.id).then(res => {
				this.watchUserInfo = res;
			});
		}
        this.detail().then(user => {
            if (user.is_recruiter) {
                this.recruiter = user.is_recruiter
            }
            else if (
                !this.recruiter &&
                this.userID &&
                this.$route.params.id !== JSON.stringify(this.userID) &&
                this.isOnboarding
            ) {
                this.$router.push(`/profile/${this.userID}`)
            }
        });
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.prevRoute = from
		});
	},
	mounted() {
		this.$scrollTo(document.querySelector('header.header-role'));
		if (
			this.$route.query.modal_open === 'changePass' ||
			this.$route.query.modal_open === 'changepass'
		)
			this.modalOpen('changePass', { title: 'Установите пароль' });

        if (
            this.$route.params.id &&
            this.$route.params.id !== JSON.stringify(this.userID)
        ) this.watchUser = true
	},
	methods: {
		...mapActions('user', ['changePageName', 'detailWatchUser', 'detail']),
		...mapMutations('user', ['setPrevRoute']),
		selectPage(val) {
			let pageName = val;
			if (this.watchUser) {
				if (pageName === 'Профиль') {
					this.changePageName('Профиль');
					if (
						this.$route.fullPath !==
						`/profile/${this.$route.params.id}`
					)
						this.$router.push(`/profile/${this.$route.params.id}`);
				}
				if (pageName === 'Курсы') {
					if (
						this.$route.fullPath !==
						`/profile/${this.$route.params.id}?courses=true`
					)
						this.$router.push(
							`/profile/${this.$route.params.id}?courses=true`
						);
				}
				if (pageName === 'Сертификаты') {
					this.changePageName('Сертификаты');
					if (
						this.$route.fullPath !==
						`/profile/${this.$route.params.id}?certificates=true`
					)
						this.$router.push(
							`/profile/${this.$route.params.id}?certificates=true`
						);
				}
				if (pageName === 'Резюме') {
					if (
						this.$route.fullPath !==
						`/profile/${this.$route.params.id}?resumes=true`
					)
						this.$router.push(
							`/profile/${this.$route.params.id}?resumes=true`
						);
				}
			} else if (this.isRecruiter) {
				if (pageName === 'Профиль') {
					this.changePageName('Профиль');
					if (this.$route.fullPath !== '/profile')
						this.$router.push('/profile');
				}
				if (pageName === 'Компании') {
					this.changePageName('Компании');
					if (this.$route.fullPath !== '/profile?companies=true')
						this.$router.push('/profile?companies=true');
				}
				if (pageName === 'Вакансии') {
					this.changePageName('Вакансии');
					if (this.$route.fullPath !== '/profile?vacancies=true')
						this.$router.push('/profile?vacancies=true');
				}
				if (pageName === 'Избранные кандидаты') {
					this.changePageName('Избранные кандидаты');
					if (this.$route.fullPath !== '/profile?favorites=true')
						this.$router.push('/profile?favorites=true');
				}
				if (pageName === 'Доступ к данным') {
					this.changePageName('Доступ к данным');
					if (this.$route.fullPath !== '/profile?access=true')
						this.$router.push('/profile?access=true');
				}
				if (pageName === 'Отклики') {
					this.changePageName('Отклики');
					if (this.$route.fullPath !== '/profile?responses=true')
						this.$router.push('/profile?responses=true');
				}
			} else {
				if (pageName === 'Статистика' && (this.user.user_role === 'Student' || this.user.user_role === 'Schoolboy' || this.user.user_role === 'College')) {
					this.changePageName('Статистика');
					if (
						this.$route.fullPath !==
						`/profile/${this.$route.params.id}?prizes=true`
					)
						this.$router.push(`/profile/${this.$route.params.id}?prizes=true`);
				}
				if (pageName === 'Профиль') {
					this.changePageName('Профиль');
					if (
						this.$route.fullPath !==
						`/profile/${this.$route.params.id}`
					)
						this.$router.push(`/profile/${this.$route.params.id}`);
				}
				if (pageName === 'Конкурсы') {
					this.changePageName('Конкурсы');
					if (
						this.$route.fullPath !==
						`/profile/${this.$route.params.id}?contests=true`
					)
						this.$router.push(
							`/profile/${this.$route.params.id}?contests=true`
						);
				}
				if (pageName === 'Конкурс') {
					this.changePageName('Конкурс');
					if (
						this.$route.fullPath !==
						`/profile/${this.$route.params.id}?contest=true`
					)
						this.$router.push(
							`/profile/${this.$route.params.id}?contest=true`
						);
				}
				if (pageName === 'Курсы') {
					this.changePageName('Курсы');
					if (
						this.$route.fullPath !==
						`/profile/${this.$route.params.id}?courses=true`
					)
						this.$router.push(
							`/profile/${this.$route.params.id}?courses=true`
						);
				}
				if (pageName === 'Программы') {
					this.changePageName('Программы');
					if (
						this.$route.fullPath !==
						`/profile/${this.$route.params.id}?programs=true`
					)
						this.$router.push(
							`/profile/${this.$route.params.id}?programs=true`
						);
				}
				if (pageName === 'Сертификаты') {
					this.changePageName('Сертификаты');
					if (
						this.$route.fullPath !==
						`/profile/${this.$route.params.id}?certificates=true`
					)
						this.$router.push(
							`/profile/${this.$route.params.id}?certificates=true`
						);
				}
				if (pageName === 'Тесты') {
					this.changePageName('Тесты');
					if (
						this.$route.fullPath !==
						`/profile/${this.$route.params.id}?tests=true`
					)
						this.$router.push(
							`/profile/${this.$route.params.id}?tests=true`
						);
				}
				if (pageName === 'Резюме') {
					this.changePageName('Резюме');
					if (
						this.$route.fullPath !==
						`/profile/${this.$route.params.id}?resumes=true`
					)
						this.$router.push(
							`/profile/${this.$route.params.id}?resumes=true`
						);
				}
				if (pageName === 'Избранные вакансии') {
					this.changePageName('Избранные вакансии');
					if (
						this.$route.fullPath !==
						`/profile/${this.$route.params.id}?favoritesVacancies=true`
					)
						this.$router.push(
							`/profile/${this.$route.params.id}?favoritesVacancies=true`
						);
				}
				if (pageName === 'Отклики') {
					this.changePageName('Отклики');
					if (
						this.$route.fullPath !==
						`/profile/${this.$route.params.id}?responses=true`
					)
						this.$router.push(
							`/profile/${this.$route.params.id}?responses=true`
						);
				}
			}
		}
	}
};
</script>

<style lang="less">
@import "../../assets/styles/pages/cabinet/index";
.profile_nav .select .select-dropdown {
	z-index: 10;
	position: relative;
}
.profile-navigation a {
    padding: 0 10px;
}
</style>
