<template>
	<section class="section wow">
		<div class="content">
			<div class="section-bar">
				<div class="grid">
					<div class="box">
						<router-link
							:to="'/profile?responses=true'"
							class="button-hollow icon-hover create-resume-back"
						>
							<span class="icon icon-back" />
							К списку откликов
						</router-link>
					</div>
					<div class="box">
						<div class="buttons-column">
							<br class="visible-mobile">
							<button
								class="button-control repair"
								@click="downloadPDF"
							>
								<a
									ref="link"
									style="display: none;"
								/>
								<div
									v-if="isDownloaded || !isLoaded"
									class="preloader xs"
								/>
								<img
									v-else
									:src="'/img/icons/icon-pdf.svg' | prodSrcImage"
									alt="Сохранить отклик как pdf-файл"
								>
								Сохранить в pdf
							</button>

							<span
								class="favorites"
								:class="{ 'active': response.favourite, 'preloading': isSended || !isLoaded }"
								@click="changeFavorite"
							>
								<div
									v-if="isSended || !isLoaded"
									class="preloader xs"
								/>
								{{ response.favourite ? 'Убрать из избранного' : 'Добавить в избранные' }}
							</span>
						</div>
					</div>
				</div>
			</div>

			<div
				v-if="!isLoaded"
				class="preloader"
				style="margin-top: 200px"
			/>
			<div
				v-else
				class="create-resume-block create-rekrut-resume"
			>
				<div class="create-resume-section">
					<div class="block-title sup-block-title">
						Отклик на вакансию
					</div>
					<div class="block-title sub-block-title">
						{{ response.vacancy.name }}
					</div>
					<div class="grid">
						<div
							class="box full"
						>
							<div class="field-wrap value-wrap">
								<label class="field-label">Сопроводительное письмо:</label>
								<div class="field-value text--white-pre text--word-break">
									{{
										response.cover_letter
									}}
								</div>
							</div>
						</div>
						<div
							v-if="response.video_file"
							class="box full"
						>
							<div class="field-wrap value-wrap">
								<label class="field-label">Этот отклик содержит видеорезюме:</label>
								<div class="button-group">
									<button
										class="button-hollow md icon-hover"
										@click="modalOpen('mBanVideo', { id: 'video_file',src: response.video_file })"
									>
										<span class="icon icon-button-play" />
										Смотреть видеоотклик
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="create-resume-section">
					<div class="block-title">
						Основная информация
					</div>
					<div
						v-if="userAvatar"
						class="user-avatar"
						:style="userAvatar | bgrDynamicImageCover"
					/>
					<div class="grid">
						<div class="box">
							<div class="field-wrap value-wrap">
								<label class="field-label">
									ФИО:
								</label>
								<div class="field-value">
									{{ userLastName }} {{ userFirstName }} {{ userMiddleName }}
								</div>
							</div>
						</div>
						<div class="box">
							<div class="field-wrap value-wrap">
								<label class="field-label">
									Дата рождения:
								</label>
								<div class="field-value">
									{{ userBirthday }}
								</div>
							</div>
						</div>
						<div class="box">
							<div class="field-wrap value-wrap">
								<label class="field-label">
									Гражданство:
								</label>
								<div class="field-value">
									{{ userNationality }}
								</div>
							</div>
						</div>
						<div class="box">
							<div class="field-wrap value-wrap">
								<label class="field-label">
									Телефон:
								</label>
								<div class="field-value">
									{{ userPhone }}
								</div>
							</div>
						</div>
						<div class="box">
							<div class="field-wrap value-wrap">
								<label class="field-label">
									Email:
								</label>
								<div class="field-value">
									{{ userEmail }}
								</div>
							</div>
						</div>
						<div class="box">
							<div class="field-wrap value-wrap">
								<label class="field-label">
									Город:
								</label>
								<div class="field-value">
									{{ userCity }}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div
					v-if="isSummary"
					class="create-resume-section"
				>
					<div class="block-title">
						Специальность
					</div>
					<div class="grid">
						<div class="box">
							<div class="field-wrap value-wrap">
								<label class="field-label">
									Желаемая должность:
								</label>
								<div class="field-value">
									{{ response.summary_new.preferred_position }}
								</div>
							</div>
						</div>
						<div class="box">
							<div class="field-wrap value-wrap">
								<label class="field-label">
									Зарплата:
								</label>
								<div class="field-value">
									{{ response.summary_new.salary }} ₽
								</div>
							</div>
						</div>
						<div class="box">
							<div class="field-wrap value-wrap">
								<label class="field-label">
									Тип занятости:
								</label>
								<div class="field-value">
									{{
										typeEmploymentFormat(response.summary_new.type_of_employment)
									}}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div
					v-if="workPlaces"
					class="create-resume-section"
				>
					<div class="block-title">
						Опыт работы
					</div>
					<template v-if="workPlaces && workPlaces.length">
						<div
							v-for="place in workPlaces"
							:key="place.id"
							class="panel"
						>
							<div class="cells">
								<div class="cell">
									<div class="text dark sm">
										<p class="word-style">
											{{ place.organization }}
										</p>
										<small>
											{{ place.commencement_date | dateForViewFormat }} - {{ place.to_present ? 'По настоящее время' : place.completion_date | dateForViewFormat }}
										</small>
										<p>{{ place.city }}</p>
									</div>
								</div>
								<div class="cell">
									<div class="text dark sm">
										<p class="word-style">
											{{ place.position }}
										</p>
										<p class="word-style">
											{{ place.duties_at_work }}
										</p>
									</div>
								</div>
							</div>
							<br>
						</div>
					</template>
				</div>

				<div
					v-if="studyPlaces"
					class="create-resume-section"
				>
					<div class="block-title">
						Образование
					</div>

					<template
						v-if="studyPlaces && studyPlaces.length"
					>
						<study-place
							v-for="item in studyPlaces"
							:key="item.id"
							:sp-data="item"
							is-watch
						/>
					</template>
				</div>

				<div
					v-if="isSummary"
					class="create-resume-section"
				>
					<div class="block-title">
						О кандидате
					</div>
					<div
						v-if="response.summary_new.about_me"
						class="about-text text--white-pre text--word-break"
					>
						{{ response.summary_new.about_me }}
					</div>
				</div>

				<div class="create-resume-section">
					<div class="block-title">
						Ответ на отклик
					</div>
					<div class="grid">
						<div class="grey-grid">
							<div
								v-if="autoresponse"
								class="box"
							>
								<div class="field-wrap">
									<c-select
										:options="['Авторский', ...autoresponse.map(x => x.name)]"
										:name="'Автоответ:'"
										:default-data="'Авторский'"
										@selected="selectAutoResponse"
									/>
								</div>
							</div>

							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<c-select
											:options="statusesOptions"
											:name="'Статус отклика:'"
											:default-data="statusResponse"
											@selected="selectStatus"
										/>
										<span
											v-if="isSendedStatus"
											class="help-block form-success"
										>Статус изменён!</span>
									</label>
								</div>
							</div>

							<div class="box full">
								<div class="field-wrap">
									<label class="field-label">
										<textarea
											v-model="$v.answer.text.$model"
											rows="3"
											class="field"
											placeholder="Текст ответа с возможностью изменения"
										/>
										<div class="label">Текст ответа:</div>
										<span
											v-if="$v.answer.text.$error"
											class="help-block form-error"
										>Это поле не может быть пустым</span>
									</label>
								</div>
							</div>
							<button
								v-if="!isSended"
								class="button lg create-resume-save"
								@click="send"
							>
								Отправить
							</button>
							<div
								v-else
								class="button lg orange create-resume-save button-preloader"
							>
								Отправить
								<div class="button-preloader-icons">
									<div class="button-preloader-icon" />
									<div class="button-preloader-icon" />
									<div class="button-preloader-icon" />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="create-resume-section">
					<div class="block-title">
						Комментарий
					</div>
					<div class="grid">
						<div class="grey-grid">
							<div class="box full">
								<div class="field-wrap">
									<textarea
										v-model="response.comment"
										rows="3"
										class="field field-opacity"
										placeholder="Кандидат не увидит Ваш комментарий"
									/>
									<div class="label">
										Кандидат не увидит Ваш комментарий
									</div>
								</div>
							</div>
							<button
								v-if="!isSendedCmnt"
								class="button blue"
								@click="save"
							>
								Сохранить
							</button>
							<div
								v-else
								class="button blue create-resume-save button-preloader"
							>
								Сохранить
								<div class="button-preloader-icons">
									<div class="button-preloader-icon" />
									<div class="button-preloader-icon" />
									<div class="button-preloader-icon" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>

const MONTHS = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
]

import {required} from 'vuelidate/lib/validators'
import StudyPlace from '@/components/panels/StudyPlace'
import Re from '@/api/recruiter'
import {mapActions, mapGetters} from 'vuex';

export default {
    components: {
        'study-place': StudyPlace
    },
    filters: {
        urlDownloadFile: url => URL.createObjectURL(new Blob([url])),
        nameDownloadFile: link => link.split('/').reverse()[0],
        dateForViewFormat(val) {
            if (val) {
                let [year, month, day] = val.includes('.') ? val.split('.').reverse() : val.split('-')

                if (day) {
                    return `${MONTHS[parseInt(month) - 1]} ${year} г.`
                } else if (month) {
                    return `${MONTHS[month]} ${year} г.`
                } else {
                    return val
                }
            } else {
                return ''
            }
        },
    },
    data: () => ({
        isLoaded: false,
        isSended: false,
        isSendedCmnt: false,
        isSendedStatus: false,
        isDownloaded: false,
        autoresponse: null,
        studyPlaces: [],
        workPlaces: [],
        response: {},
        answer: {
            status: '',
            text: ''
        }
    }),
    validations: {
        answer: {
            text: {required}
        }
    },
    computed: {
        ...mapGetters('cnst', {
            employments: 'getEmployments',
            statuses: 'getRS'
        }),
        token() {
            return this.$store.state.user && this.$store.state.user.token
        },
        isSummary() {
            return this.response && this.response.summary_new
        },
        userAvatar() {
            if (this.isSummary && this.response.summary_new.candidate_photo) {
                return this.response.summary_new.candidate_photo
            } else if (this.isSummary && this.response.summary_new.candidate_photo_url) {
                return this.response.summary_new.candidate_photo_url
            } else {
                return '/staticfiles/img/avatar.png'
            }
        },
        userFirstName() {
            if (this.response && this.response.user.first_name !== null) return this.response && this.response.user.first_name
            else return ''
        },
        userMiddleName() {
            if (this.response && this.response.user.middle_name !== null) return this.response && this.response.user.middle_name
            else return ''
        },
        userLastName() {
            if (this.response && this.response.user.last_name !== null) return this.response && this.response.user.last_name
            else return ''
        },
        userBirthday() {
            if (this.response && this.response.user.birthday) {
                let [year, month, day] = this.response.user.birthday.split('-')
                return `${day}.${month}.${year} г.`
            }

            return ''
        },
        userNationality() {
            return this.isSummary && this.response.summary_new.nationality
        },
        userPhone() {
            let phone = ''

            if (this.isSummary && this.response.summary_new.phone) {
                phone = this.response.summary_new.phone
            } else if (this.response) {
                phone = this.response.user.phone
            }

            if (phone) return `${phone.substr(0, 2)}(${phone.substr(2, 3)})${phone.substr(5, 3)}-${phone.substr(8, 2)}-${phone.substr(10, 2)}`
            else return ''
        },
        userEmail() {
            let email = ''

            if (this.isSummary && this.response.summary_new.email) {
                email = this.response.summary_new.email
            } else if (this.response) {
                email = this.response.user.email
            }

            return email
        },
        userCity() {
            if (this.response.summary_new && this.response.summary_new.city) return this.response.summary_new.city
            else if (this.response.user.city) return this.response.user.city.name
            else return '-'
        },
        statusesOptions() {
            return this.statuses.map(x => x.name)
        },
        statusResponse() {
            return this.response && this.statuses.find(x => x.slug === this.response.status).name
        }
    },
    created() {
        Re.getAutoResponse().then(response => this.autoresponse = response.data.filter(x => x.active))
        if (this.$route.query.action === 'edit') {
            Re.getResponse(this.$route.query.id)
                .then(response => {
                    this.response = response.data
                    this.answer.text = response.data.answer
                    if (response.data.summary_new.id) {
                        this.getStudyPlace(response.data.summary_new.id).then(res => {
                            this.studyPlaces = res
                        })
                        this.getWorkPlace(response.data.summary_new.id).then(res => {
                            this.workPlaces = res
                        })
                    }
                })
                .finally(() => this.isLoaded = true)
        }
    },
    methods: {
        ...mapActions('user', {
            getStudyPlace: 'getStudyPlaceResume',
            getWorkPlace: 'getWorkPlaceResume',
        }),
        typeEmploymentFormat(type) {
            if (type) return this.employments.find(x => x.slug === type).value
        },
        selectAutoResponse(val) {
            let variant = this.autoresponse.find(x => x.name === val)

            if (variant) this.answer.text = variant.text
        },
        selectStatus(val) {
            this.answer.status = this.statuses.find(x => x.name === val).slug

            if (!this.isSended) {
                this.isSended = true
                this.$store.dispatch('rec/changeResponse', {
                    id: +this.$route.query.id,
                    data: {
                        status: this.answer.status
                    }
                })
                    .then(() => {
                        this.isSendedStatus = true
                        setTimeout(() => this.isSendedStatus = false, 2000)
                    })
                    .catch(error => console.log(error))
                    .finally(() => {
                        this.$v.answer.$reset()
                        this.isSended = false
                    })
            }
        },
        changeFavorite() {
            if (!this.isSended) {
                this.isSended = true
                this.$store.dispatch('rec/changeResponse', {
                    id: +this.$route.query.id,
                    data: {
                        favourite: !this.response.favourite
                    }
                })
                    .then(result => this.response.favourite = result.favourite)
                    .finally(() => this.isSended = false)
            }
        },
        downloadPDF() {
            this.isDownloaded = true
            Re.downloadPDF(+this.$route.query.id)
                .then(response => {
                    if (response.status === 204) this.downloadPDF()
                    else {
                        window.open(response.data.download_link, '_blank')
                        this.isDownloaded = false
                    }
                })
        },
        save() {
            if (!this.isSendedCmnt) {
                this.isSendedCmnt = true
                this.$store.dispatch('rec/changeResponse', {
                    id: +this.$route.query.id,
                    data: {
                        comment: this.response.comment ? this.response.comment : null
                    }
                })
                    .then(() => {
                        this.modalOpen('cmntSuccess')
                    })
                    .finally(() => this.isSendedCmnt = false)
            }
        },
        send() {
            this.$v.answer.$touch()
            if (this.$v.answer.$invalid) {
                console.log('Errors in answer form')
            } else if (!this.isSended) {
                this.isSended = true
                this.$store.dispatch('rec/changeResponse', {
                    id: +this.$route.query.id,
                    data: {
                        answer: this.answer.text,
                        status: this.answer.status || this.response.status
                    }
                })
                    .then(() => this.modalOpen('respSuccess'))
                    .catch(error => console.log(error))
                    .finally(() => this.isSended = false)
            }
        }
    }
}
</script>

<style lang="less">
.icon{
    position: relative;
    &:before{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -webkit-background-size: contain !important;
        background-size: contain !important;
        .transition;
    }
    &:after{
        opacity: 0;
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -webkit-background-size: contain !important;
        background-size: contain !important;
        .transition;
    }
    &.icon-download{
        display: inline-block;
        vertical-align: middle;
        width: 18px;
        height: 18px;
        margin-right: 12px;
        &:before{
            background: url(../../../assets/img/icons/icon-download.svg) center center no-repeat;
        }
        &:after{
            background: url(../../../assets/img/icons/icon-download-white.svg) center center no-repeat;
        }
    }
}

.create-resume-block{
    //padding-top: 55px;
    padding-bottom: 20px;
    @media(max-width: 1100px){
        //padding-top: 45px;
    }
    @media(max-width: 905px){
    }
    .grid{
        margin: 0 -25px;
    }
    .box{
        width: 33.33%;
        padding: 0 25px;
        @media @desktop-end{
        }
        @media @laptop-end{
        }
        @media @tablet-end{
        }
        @media @mobile-end{
            width: 100%;
        }
    }
    .grey-grid{
        .box{
            @media @mobile-end{
                padding: 0;
            }
        }
    }
    .block-title{
        margin-bottom: 35px;
        @media @mobile-end{
            font-size: 25px;
        }
    }
    .button-control{
        display: block;
        height: auto;
        line-height: 1.4;
    }
    .button{
        @media @desktop-end{
        }
        @media @laptop-end{
        }
        @media @tablet-end{
        }
        @media @mobile-end{
            width: 100%;
            min-width: 100%;
            margin-left: 0 !important;
        }
    }
    .value-wrap {
        .field-label {
            font-size: 13px;
            color: @color-light;
            margin-bottom: 15px;
        }
        .field-value {
            color: @color-text;
            font-size: 16px;
        }
    }
    .profile-avatar-wrap{
        max-width: 200px;
    }
    .profile-avatar{
        width: 200px;
        height: 200px;
    }
}
.create-resume-section{
    padding-bottom: 25px;
    @media(max-width: 1100px){
        padding-bottom: 20px;
    }
    @media @mobile-end{
        padding-bottom: 16px;
    }
    .text{
        p{
            small{
                display: inline;
                margin-bottom: 0;
            }
        }
    }
    .select-type-simple{
        max-width: 250px;
        margin-bottom: 40px;
    }
    .button-group{
        margin-bottom: -20px;
        .button{
            margin-right: 20px;
            margin-bottom: 20px;
            @media @desktop-end{
            }
            @media @laptop-end{
            }
            @media @tablet-end{
            }
            @media @mobile-end{
                width: 100%;
            }
            &:last-child{
                margin-right: 0;
            }
        }
        .button-hollow{
            margin-right: 20px;
            margin-bottom: 20px;
            @media @desktop-end{
            }
            @media @laptop-end{
            }
            @media @tablet-end{
            }
            @media @mobile-end{
                width: 100%;
            }
            &:last-child{
                margin-right: 0;
            }
        }
    }
}

.create-rekrut-resume {

    .block-title.sub-block-title {
        font-size: 36px;
        @media(max-width: 905px){
            font-size: 34px;
        }
        @media @desktop-end{
        }
        @media @laptop-end{
        }
        @media @tablet-end{
        }
        @media @mobile-end{
            font-size: 30px;
        }
    }

    .block-title.sup-block-title {
        font-size: 18px;
        margin-bottom: 15px;
    }

    .value-wrap {

        .field-label {
            font-size: 13px;
            color: @color-light;
            margin-bottom: 15px;
        }

        .field-value {
            color: @color-text;
            font-size: 16px;
        }

    }

}
</style>

<style lang="less">
.text {
    &--white-pre {
        white-space: pre-wrap;
    }

    &--word-break {
        word-break: break-word;
    }
}

.field.field-opacity::placeholder {
    opacity: 1;
}


.icon.icon-download {
    display: inline-block;
    vertical-align: middle;
    width: 18px;
    height: 18px;
    margin-right: 12px;
    &:before {
        background: url(../../../assets/img/icons/icon-download.svg) center center no-repeat;
    }
    &:after {
        background: url(../../../assets/img/icons/icon-download-white.svg) center center no-repeat;
    }
}

.icon.icon-button-play {
    display: inline-block;
    vertical-align: middle;
    width: 17px;
    height: 17px;
    margin-right: 12px;
    &:before {
        background: url(../../../assets/img/icons/icon-button-play.svg) center center no-repeat;
    }
    &:after {
        background: url(../../../assets/img/icons/icon-button-play-white.svg) center center no-repeat;
    }
}
</style>
