<template>
	<div
		class="contest-view__step"
		:class="{
			'contest-view__step--checked': stage.status === 'past',
			'contest-view__step--active': stage.status === 'actual',
			'contest-view__step--lock': stage.status === 'next' || availableNominations.length === 0
		}"
	>
		<div class="contest-view__info">
			<p class="contest-view__index">
				{{ index + 1 }} этап
			</p>
			<p class="contest-view__date">
				{{ getDate(stage.start_date) }} - {{ getDate(stage.end_date) }}
			</p>
		</div>
		<div class="contest-view__status" />
		<div class="contest-view__task">
			<p class="contest-view__subtitle">
				{{ stage.name }}
			</p>
			<div v-if="availableNominations.length > 0">
				<button
					v-if="stage.status === 'past'"
					class="button button--s button--hollow"
					@click="watchRequest(stage.id)"
				>
					Посмотреть результат
				</button>
				<div v-if="stage.status === 'actual'">
					<a
						v-if="!isRegisteredToMindFormat"
						class="button button--s button--blue"
						href="/tests/mindformat/"
						target="_blank"
					>
						Пройти тест для оценки потенциала и особенностей мышления
					</a>
					<router-link
						v-else-if="actualTests && actualTests.length > 0"
						class="button button--s button--blue"
						:to="`/tests/${actualTests[0].id}`"
						target="_blank"
					>
						Пройти {{ actualTests[0].name.toLowerCase() }}
					</router-link>
					<button
						v-else
						class="button button--s button--blue"
						@click="applyToContest(stage.id)"
					>
						Пройти задание
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import User from '@/api/user';
import Contests from '@/api/contests'
import { mapGetters } from 'vuex';

export default {
    props: {
        index: {
            type: Number,
            default: null,
        },
        stage: {
            type: Object,
            default: null,
        },
        isRegisteredToMindFormat: {
            type: Boolean,
            default: false,
        },
        actualTests: {
            type: Array,
            default: null,
        },
        isDisableProfileCheck: {
            type: Boolean,
            default: false
        },
    },
    data: () => ({
        availableNominations: []
    }),
    computed: {
        ...mapGetters('user', {
            userID: 'userID',
            user: 'userInfo'
        }),
    },
    created() {
       this.getAvailableNominations()
    },
    methods: {
        watchRequest(stageID) {
            this.modalOpen('apply-contest', { contest: this.$route.query.id, stage: stageID, status: 'watch', availableNominations: this.availableNominations})
        },
        getDate(date) {
            const year = date.slice(0, 4);
            const month = date.slice(5, 7);
            const day = date.slice(8, 10);
            return [day, month, year].join('.');
        },
        applyToContest(stageID) {
            User.detail(this.userID).then(res => {
                localStorage.setItem('jwtToken', res.data.jwt_token);
            })
            if (!this.isDisableProfileCheck) {
               User.isUserComplete(this.userID).then(() => {
                    this.modalOpen('apply-contest', { contest: this.$route.query.id, stage: stageID, status: 'edit', availableNominations: this.availableNominations })
                }).catch(() => {
                    this.modalOpen('is-user-complete', this.userID)
                })
            } else {
                this.modalOpen('apply-contest', { contest: this.$route.query.id, stage: stageID, status: 'edit', availableNominations: this.availableNominations });
            }
        },
        getAvailableNominations() {
            Contests.getContestNominations(this.$route.query.id, this.stage.id).then(res => {
                if (res.data.nominations_id && res.data.nominations_id.length > 0) {
                    this.availableNominations = res.data.nominations_id;
                }
            })
        }
    }
}

</script>

